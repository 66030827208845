import React from "react";
import { defineMessages } from "react-intl";
import { TextInfoParagraph } from "./LoanInfo";

export const messages = defineMessages({
    title: {
        id: "loan.info.title"
    },
    description: {
        id: "loan.info.description"
    },
    text: {
        id: "loan.info.text",
        values: {
            p: (value) => <TextInfoParagraph>{value}</TextInfoParagraph>
        }
    }
});
