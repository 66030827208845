import {
    FETCH_PAYMENT_PLANS_SUCCESS,
    FETCH_PAYMENT_PLANS_TRIGGER
} from "../constants/paymentPlans";

export function fetchPaymentPlansTrigger() {
    return {
        type: FETCH_PAYMENT_PLANS_TRIGGER
    };
}

export function fetchPaymentPlansSuccess(payload) {
    return {
        type: FETCH_PAYMENT_PLANS_SUCCESS,
        payload
    };
}
