import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
// import { goToTop } from "react-scrollable-anchor";

import { BoxContainer } from "../../components/BoxContainer";
import { MainContent } from "../../components/MainContent";
import { FlexColumn, Grid2, GridContainer, GridContent } from "../../components/Grid";
import { PageTitleBox } from "../../components/PageTitleBox";
import { contactPageTrigger } from "../../actions/page";
import { BasicText } from "../../components/Fonts/BasicText";
import { ActionTitle } from "../../components/ActionTitle";
import { PageTitleWithRightContent } from "../../components/PageTitleWithRightContent";
import { getCustomerBasicInfo } from "../../selectors/customer";

import { messages } from "./messages";
import { selectToken } from "../../selectors/token";

const CustomerServiceTime = styled.div`
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #444444;
`;

const CustomerServiceNum = styled.span`
    display: block;
    margin-top: 8px;
    color: #444444;
    line-height: 24px;
`;

const ContactEmailText = styled.span`
    display: block;
    margin-top: 24px;
    font-weight: bold;
    color: #444444;
    line-height: 24px;
`;
const ContactEmail = styled.span`
    display: block;
    margin-top: 0px;
    line-height: 24px;
`;

const Address = styled.span`
    display: block;
    font-weight: bold;
    margin-top: 24px;
    margin-bottom: 0px;
    color: #444444;
    line-height: 24px;
`;
const ContactParagraphHeading = styled.h3`
    line-height: 24px;
    margin: 0px;
    font-weight: bold;
    font-size: 16px;
    color: #444444;
`;

const ContactParagraph = styled.div`
    color: #444444;
    line-height: 24px;
`;

const ContactParagraphAddress = styled.span`
    display: block;
    color: #444444;
    line-height: 24px;
`;
const DebtCollectionNum = styled.span`
    display: block;
    color: #444444;
    margin-top: 8px;
    line-height: 24px;
`;

export function ContactPage() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(contactPageTrigger());
        // goToTop();
    }, [dispatch]);

    const { formatMessage: f } = useIntl();

    const customerBasicInfo = useSelector(getCustomerBasicInfo) || null;
    const token = useSelector(selectToken);

    return (
        <FlexColumn>
            <PageTitleBox>
                <PageTitleWithRightContent title={f(messages.pagetitle)}>
                    {token && (
                        <BasicText>{`${f(messages.customernumber)} ${
                            customerBasicInfo.customerId
                        }`}</BasicText>
                    )}
                </PageTitleWithRightContent>
            </PageTitleBox>
            <GridContainer>
                <Grid2>
                    <GridContent>
                        <BoxContainer>
                            <MainContent>
                                <ActionTitle title={f(messages.customerservice)} />
                                <ContactParagraphHeading>
                                    {f(messages.phonetitle)}
                                </ContactParagraphHeading>
                                <CustomerServiceTime>{f(messages.phonetime)}</CustomerServiceTime>
                                <CustomerServiceNum>{f(messages.phone)}</CustomerServiceNum>
                                <ContactEmailText>{f(messages.emailtitle)}</ContactEmailText>
                                <ContactEmail>
                                    {f(messages.emailaddress, messages.emailaddress.values)}
                                </ContactEmail>
                                <Address>{f(messages.addresstitle)}</Address>
                                <ContactParagraphAddress>
                                    {f(messages.companyname)}
                                </ContactParagraphAddress>
                                <ContactParagraphAddress>
                                    {f(messages.companyaddress)}
                                </ContactParagraphAddress>
                                <ContactParagraphAddress>
                                    {f(messages.companypost)}
                                </ContactParagraphAddress>
                            </MainContent>
                        </BoxContainer>
                    </GridContent>
                </Grid2>
                <Grid2>
                    <GridContent>
                        <BoxContainer>
                            <MainContent>
                                <ActionTitle title={f(messages.debtcollection)} />
                                <ContactParagraphHeading>
                                    {f(messages.phonetitle)}
                                </ContactParagraphHeading>
                                <ContactParagraph>
                                    {f(messages.debtcollectionphonetime)}
                                </ContactParagraph>
                                <DebtCollectionNum>
                                    {f(messages.debtcollectionphone)}
                                </DebtCollectionNum>
                                <ContactEmailText>{f(messages.emailtitle)}</ContactEmailText>
                                <ContactEmail>
                                    {f(
                                        messages.debtcollectionemailaddress,
                                        messages.debtcollectionemailaddress.values
                                    )}
                                </ContactEmail>
                            </MainContent>
                        </BoxContainer>
                    </GridContent>
                </Grid2>
            </GridContainer>
            <GridContainer>
                <Grid2>
                    <GridContent>
                        <BoxContainer>
                            <MainContent>
                                <ActionTitle title={f(messages.billing)} />
                                <ContactParagraphHeading>
                                    {f(messages.phonetitle)}
                                </ContactParagraphHeading>
                                <ContactParagraph>{f(messages.billinphonetime)}</ContactParagraph>
                                <DebtCollectionNum>{f(messages.billingphone)}</DebtCollectionNum>
                                <ContactEmailText>{f(messages.emailtitle)}</ContactEmailText>
                                <ContactEmail>
                                    {f(messages.emailaddress, messages.emailaddress.values)}
                                </ContactEmail>
                            </MainContent>
                        </BoxContainer>
                    </GridContent>
                </Grid2>
            </GridContainer>
        </FlexColumn>
    );
}
