// Add more languages when implement other countries
import { fi, sv } from "date-fns/locale";

const locales = { fi, sv };
const locale = locales[process.env.REACT_APP_LOCALE];

export const abbreviatedMonth = (month) => {
    return locale.localize.month(month, {
        width: "abbreviated"
    });
};

export const wideMonth = (month) => {
    return locale.localize.month(month, {
        width: "wide"
    });
};
