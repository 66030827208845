import { FETCH_REMAINING_DEBT_SUCCESS } from "../constants/remainingDebts";

export const initialState = {
    remainingDebt: null,
};

export function remainingDebtReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_REMAINING_DEBT_SUCCESS: {
            return { ...state, remainingDebt: action.payload };
        }
        default:
            return state;
    }
}
