import { takeLatest, put } from "@redux-saga/core/effects";
import { FRONT_PAGE_TRIGGER } from "../constants/page";
import { fetchPaymentPlansTrigger } from "../actions/paymentPlans";
import { fetchAccountStatementsTrigger } from "../actions/account";
import { fetchRemainingDebtTrigger } from "../actions/remainingDebt";
import { fetchContentfulNewsTrigger } from "../actions/contentful";

export function* watchFrontPageLoad() {
    yield takeLatest(FRONT_PAGE_TRIGGER, handleFrontPageLoad);
}

export function* handleFrontPageLoad(action) {
    yield put(fetchContentfulNewsTrigger(action.payload));
    yield put(fetchAccountStatementsTrigger());
    yield put(fetchRemainingDebtTrigger());
    yield put(fetchPaymentPlansTrigger());
}
