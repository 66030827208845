import styled from "styled-components/macro";
import { DropDown } from "../DropDown";
import { fontSizes, colors } from "../../constants/styles";
import { breakpoints } from "../../constants/styles";

export const StyledTableContainer = styled.div`
    border-radius: 4px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
    margin-top: 40px;
`;

export const StyledTable = styled.table`
    width: 100%;
    border-spacing: 0;
`;

export const StyledThead = styled.thead``;

export const StyledTr = styled.tr`
    text-align: left;
    color: ${colors.gray};
`;

export const StyledTh = styled.th`
    border-bottom: 1px solid #e0e0e0;
    font-size: ${fontSizes.small};
    line-height: 1.64;
    padding: 6px 6px 6px 16px;
`;

export const StyledTbody = styled.tbody``;

export const StyledTd = styled.td`
    border-bottom: 1px solid #e0e0e0;
    font-size: ${fontSizes.small};
    line-height: 1.43;
    padding: 6px 6px 6px 16px;
    @media ${breakpoints.mobile} {
        padding: 8px;
    }
`;

export const StyledTfoot = styled.tfoot`
    & > tr > td {
        font-weight: bold;
    }
`;

export const StyledOptionsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
`;

export const StyledLabel = styled.span`
    margin: 0 4px;
    line-height: 1.5;
    padding: 0 14px;
`;

export const SelectPageSize = styled(DropDown)`
    margin-left: 16px;
`;
