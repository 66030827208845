import { takeEvery, put, call, select } from "redux-saga/effects";
import { fetchCustomer } from "../util/api";
import { fetchCustomerSuccess } from "../actions/user";
import { USER_FETCH_CUSTOMER_TRIGGER } from "../constants/user";

export function* watchFetchCustomer() {
    yield takeEvery(USER_FETCH_CUSTOMER_TRIGGER, handleFetchCustomer);
}

export function* handleFetchCustomer(action) {
    const token = yield select((state) => {
        return state.user.token;
    });

    console.log("fetch customer with token", token);

    const customer = yield call(fetchCustomer, { token });

    console.log("got customer", customer);

    if (!customer) {
        return (window.location = "/no-loan");
    }

    console.log("fetched customer", customer);

    yield put(fetchCustomerSuccess(customer));
}
