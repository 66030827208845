import { parsePhoneNumberFromString } from "libphonenumber-js";
import validator from "validator";
import { IBAN } from "iban";
import { messages } from "./messages";

import { textFieldNames } from "./constants";

const defaultResult = {
    validatedValue: "",
    errorMsg: messages.validationErrors.empty
};

const validateEmail = (value) => {
    if (!value) {
        return defaultResult;
    }

    const validationResult = {
        validatedValue: value,
        errorMsg: validator.isEmail(value) ? null : messages.validationErrors.email
    };
    return validationResult;
};

const validatePhone = (value) => {
    let validatedValue = value;
    let isValid = false;

    if (!value) {
        return defaultResult;
    }

    const phoneNumber = parsePhoneNumberFromString(value);

    if (phoneNumber) {
        validatedValue = phoneNumber.number;
        isValid = phoneNumber.isValid();
    }

    const validationResult = {
        validatedValue,
        phoneNumber,
        errorMsg: isValid ? null : messages.validationErrors.phone
    };

    return validationResult;
};

const validateIban = (value) => {
    if (!value) {
        return defaultResult;
    }

    const validationResult = {
        validatedValue: value,
        errorMsg: IBAN.isValid(value) ? null : messages.validationErrors.account
    };
    return validationResult;
};

export const validateField = (name, value) => {
    switch (name) {
        case textFieldNames.MOBILE_PHONE:
        case textFieldNames.PHONE_NUMBER: {
            return validatePhone(value);
        }
        case textFieldNames.EMAIL: {
            return validateEmail(value);
        }
        case textFieldNames.ACCOUNT_NUMBER: {
            return validateIban(value);
        }
        default:
            return null;
    }
};
