import { defineMessages } from "react-intl";

export const messages = defineMessages({
    home: {
        id: "navigation.desktop.home"
    },
    loan: {
        id: "navigation.desktop.loan"
    },
    change: {
        id: "navigation.desktop.change"
    },
    user: {
        id: "navigation.desktop.user"
    },
    contact: {
        id: "navigation.desktop.contact"
    },
    logout: {
        id: "button.logout"
    },
    loginAgain: {
        id: "button.login.again"
    },
    ostosraha: {
        id: "link.to.ostosraha"
    }
});
