import { colors, fontSizes } from "../../constants/styles";

export const defaultTheme = {
    listContainer: {
        marginLeft: "16px",
        width: "120px",
        height: "30px",
        borderRadius: "4px",
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.3)",
        cursor: "pointer"
    },
    listHeader: {
        margin: "auto",
        fontSize: `${fontSizes.small}`,
        padding: "7px 8px",
        color: `${colors.gray}`
    },
    headerIcon: {
        float: "right"
    },
    listItems: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        listStyle: "none",
        padding: "0",
        borderRadius: "4px",
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.3)",
        border: `1px solid ${colors.borderGray}`,
        backgroundColor: `${colors.white}`,
        marginTop: "14px"
    },
    listItem: {
        fontSize: `${fontSizes.small}`,
        padding: "7px 8px",
        borderBottom: `1px solid ${colors.borderGray}`
    }
};
