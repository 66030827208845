import React from "react";
import { FormattedMessage } from "react-intl";
import { BoxContainer } from "../BoxContainer";
import { MainContent } from "../MainContent";
import { BoxTitle } from "../BoxContent";
import { NewsItem } from "./NewsItem";

import { messages } from "./messages";

export const News = (props) => {
    const { items } = props;

    return (
        <BoxContainer>
            <MainContent>
                <BoxTitle bold>
                    <FormattedMessage {...messages.title} />
                </BoxTitle>
                {items.map((item, index) => (
                    <NewsItem
                        key={`item-${index}`}
                        headline={item.fields.headline}
                        ingress={item.fields.ingress}
                        content={item.fields.content}
                        createdDate={item.fields.createdDate}
                    />
                ))}
            </MainContent>
        </BoxContainer>
    );
};
