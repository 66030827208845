import React, { useState } from "react";
import Collapsible from "react-collapsible";

import { CollapsiblePanelTitle } from "../../components/CollapsiblePanelTitle";
import { TableContainer, MobileTableContainer } from "./styled";
import { ContentContainer } from "./InvoicesArchive.styled";
import { InvoicesTable, MobileInvoicesTable } from "../../components/InvoicesTable";

export const InvoicesArchive = ({ title, tableData, token, noButton }) => {
    const [panelOpen, setPanelOpen] = useState(false);

    return (
        <Collapsible
            trigger={<CollapsiblePanelTitle title={title} isOpen={panelOpen} />}
            onOpening={() => setPanelOpen(true)}
            onClosing={() => setPanelOpen(false)}>
            <ContentContainer>
                <TableContainer>
                    <InvoicesTable tableData={tableData} token={token} noButton={noButton} />
                </TableContainer>
                <MobileTableContainer>
                    <MobileInvoicesTable tableData={tableData} token={token} noButton={noButton} />
                </MobileTableContainer>
            </ContentContainer>
        </Collapsible>
    );
};
