import styled from "styled-components";
import { colors, fontSizes } from "../../constants/styles";

export const RadioButtonContainer = styled.div`
    margin-bottom: 12px;
`;

export const Checkmark = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.1);
    border: solid 2px #979797;
    background-color: ${colors.white};

    &:after {
        content: "";
        position: absolute;
        display: none;
    }
`;

export const LabelContainer = styled.label`
    display: block;
    position: relative;
    padding-left: 32px;
    padding-top: 4px;
    cursor: pointer;
    font-size: ${fontSizes.small};
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    &:hover input ~ ${Checkmark} {
        box-shadow: 0 2px 2px 0 #86c463;
    }

    input:checked ~ ${Checkmark}:after {
        display: block;
    }

    ${Checkmark}:after {
        top: 5px;
        left: 5px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: ${colors.ligthGreen};
    }
`;
