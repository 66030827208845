import { defineMessages } from "react-intl";

export const messages = defineMessages({
    title: {
        id: "remainingtenor.button.title"
    },
    until: {
        id: "remainingtenor.button.until"
    }
});
