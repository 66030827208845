import namor from "namor";
import { format, addDays, addMonths } from "date-fns";
import { DEFAULT_FORMAT_DATE } from "../constants/dateTime";

export async function httpFaker(url, method, data, response, timeout) {
    return new Promise((resolve) => {
        setTimeout(
            () => {
                console.log("MOCK: " + url, method, data, response);
                resolve(response);
            },
            timeout ? timeout : 600
        );
    });
}

export function getCustomerInfoMock(url, method, data) {
    return httpFaker(url, method, data, {
        customerBasicInfo: {
            customerId: "50701",
            century: "",
            ssn: "070770-905D",
            givenName: "Givenname",
            surName: "Surname",
            email: "jason.wang@leandev.se",
            phoneCode: null,
            phoneNumber: null,
            mobilePhoneCode: "+358",
            mobilePhoneNumber: "43213213",
            preferredContactChannel: null,
            selfAssessedPep: "NULL",
            selfAssessedPepDescription: null,
            nationality: "FI",
            hasGuardian: false
        },
        address: {
            streetAddress: "Katuosoite 11",
            zipCode: "00430",
            city: "Helsinki",
            country: "FI",
            coAddress: null,
            manuallyMaintained: false
        },
        accountList: [
            {
                id: "15350",
                accountNumber: "7103195",
                nickName: null,
                type: "REGULAR_LOAN",
                extraInfos: {
                    remainingPrincipal: "0.00"
                }
            }
        ]
    });
}

export function getAccessTokenMock(url, method, data) {
    return httpFaker(url, method, data, {
        accessToken:
            "SzgW6H1USnp3NGX+XUzcm/32td/EfoUNGv1+qFA9Z8u3fuHt78KHU4+snek8PmtR8bv3z5WR93BDHzVIiyVjAutQwcilXtxJZBHQT1WKFidr0Ku8lvLDZ1EqvnmpQfVLFIbECfRob2mXgraGYMpck7ZOaRzCpLGyl+yad72iC9a2zbQSJbxPOD4dlBzHcIcZOaU0BAsNW+zjiU/47b4t1CifKWhie3rQzx8eU+oOmylwKonZu8HG91vfqJZS0ArYGT/oYTSO6LYV8c+p2AsBnPw23P3bfSukqlxcxUaE9HN75tq4qX/p7ElGWvSkpaNvYZ1ExNwujvpn6nnY9Esa2kofMiS8vt5RMu2p/a2wHYCbY1yToVvPSzEQ5xe/6bIJ"
    });
}

export function getAccountMock(url, method, data) {
    return httpFaker(url, method, data, {
        accountNumber: "7103195",
        appliedAmount: 1000,
        createDate: "2019-11-18 14:26:12",
        disbursementAccount: {
            disbursementType: "IBAN",
            externalAccountNumber: "FI4114421947279566",
            id: "6651"
        },
        id: "15350",
        interestRate: 0.199,
        mainHolderId: "50701",
        mandateList: [],
        monthlyCost: 35.74,
        product: {
            amountToUseInCalculationServiceFee: "ORIGINAL_AMOUNT",
            baseInterestRate: 0.199,
            collectionFeeAccount: 0,
            collectionFeeStatement: 0,
            createDate: "2019-11-04 14:05:05",
            description: "Ostosraha 2",
            directDebitFee: 0,
            dueDay: 28,
            id: "1",
            lateInterestRate: 0.199,
            latePaymentFee: 0,
            name: "Ostosraha 2",
            ocrFee: 0,
            reminderFee: 5,
            serviceFeeRate: 1.0e-4,
            startupFee: 0
        },
        promissoryNoteDocumentId: "f36f5f26b1b9459187c92c8b943607c1",
        remainingPrincipal: 820.0,
        remainingTenor: 12,
        state: "OPEN",
        statementChannel: "PAPER"
    });
}

export function getAccountStatementsMock(url, method, data) {
    return httpFaker(url, method, data, [
        {
            seriesNum: 1,
            dueDate: null,
            paidInFullDate: null,
            overdueDays: null,
            statementAmount: 7.18,
            paidAmount: 0,
            unpaidAmount: 7.18,
            statementType: "PENDING",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PENDING",
            createdDate: "2019-11-19 11:12:28",
            documentId: null
        },
        {
            seriesNum: "1006",
            dueDate: "2020-04-28",
            overdueDays: 1,
            statementAmount: 96.0,
            paidAmount: 96.0,
            unpaidAmount: 0,
            statementType: "UNPAID",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2020-03-24 23:00:09",
            documentId: "4285bec694fe40e0a5f677db8bd30335"
        },
        {
            seriesNum: "1005",
            dueDate: "2020-03-28 00:00:00",
            overdueDays: 3,
            statementAmount: 96.0,
            paidAmount: 96.0,
            unpaidAmount: 0,
            statementType: "UNPAID",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2019-12-12 23:00:09",
            documentId: "4285bec694fe40e0a5f677db8bd30335"
        },
        {
            seriesNum: "1004",
            dueDate: "2019-12-28 00:00:00",
            paidInFullDate: "2019-12-31 00:00:00",
            overdueDays: 3,
            statementAmount: 96.0,
            paidAmount: 96.0,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2019-12-12 23:00:09",
            documentId: "4285bec694fe40e0a5f677db8bd30335"
        },
        {
            seriesNum: "1003",
            dueDate: "2019-11-28 00:00:00",
            paidInFullDate: "2019-11-29 00:00:00",
            overdueDays: 1,
            statementAmount: 96.0,
            paidAmount: 96.0,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2019-11-14 23:00:04",
            documentId: "461ab9513bf94b518deef0db399cd55c"
        },
        {
            seriesNum: "1002",
            dueDate: "2019-12-28 00:00:00",
            paidInFullDate: "2019-12-31 00:00:00",
            overdueDays: 3,
            statementAmount: 96.0,
            paidAmount: 96.0,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2019-12-12 23:00:09",
            documentId: "4285bec694fe40e0a5f677db8bd30335"
        },
        {
            seriesNum: "1001",
            dueDate: "2019-11-28 00:00:00",
            paidInFullDate: "2019-11-29 00:00:00",
            overdueDays: 1,
            statementAmount: 96.0,
            paidAmount: 96.0,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2019-11-14 23:00:04",
            documentId: "461ab9513bf94b518deef0db399cd55c"
        },
        {
            seriesNum: "1000",
            dueDate: "2019-10-28 00:00:00",
            paidInFullDate: "2019-10-26 00:00:00",
            overdueDays: 0,
            statementAmount: 103.0,
            paidAmount: 103.0,
            unpaidAmount: 0,
            statementType: "REGULAR",
            statementFee: null,
            paymentMethod: "OCR",
            status: "PAIDINFULL",
            createdDate: "2019-10-14 23:19:25",
            documentId: "41e3fa002be14e7fa5e7ce17170f4a3a"
        }
    ]);
}
//* Get Payment free month
export function getSpecialPaymentAgreementsMock(url, method, data) {
    return httpFaker(url, method, data, [
        {
            id: 0,
            state: "FINISHED",
            requestMonth: "2020-05",
            accountNumber: "7103195",
            paymentAgreementType: "PAYMENT_FREE_MONTH",
            administrativeFee: 0
        },
        {
            id: 1,
            state: "SCHEDULED",
            requestMonth: "2020-03",
            accountNumber: "7103195",
            paymentAgreementType: "PAYMENT_FREE_MONTH",
            administrativeFee: 0
        },
        {
            id: 2,
            state: "SCHEDULED",
            requestMonth: "2020-07",
            accountNumber: "7103195",
            paymentAgreementType: "PAYMENT_FREE_MONTH",
            administrativeFee: 0
        }
    ]);
    /*return httpFaker(url, method, data, [
        {
            id: 0,
            state: "SCHEDULED",
            requestMonth: "2020-03",
            accountNumber: "7103195",
            paymentAgreementType: "PAYMENT_FREE_MONTH",
            administrativeFee: 0
        },
        {
            id: 1,
            state: "SCHEDULED",
            requestMonth: "2020-05",
            accountNumber: "7103195",
            paymentAgreementType: "PAYMENT_FREE_MONTH",
            administrativeFee: 0
        },
        {
            id: 2,
            state: "SCHEDULED",
            requestMonth: "2020-07",
            accountNumber: "7103195",
            paymentAgreementType: "PAYMENT_FREE_MONTH",
            administrativeFee: 0
        }
    ]);*/
}

//* Set payment free month
export function setSpecialPaymentAgreementsMock(url, method, data) {
    const randomResponse = Math.random() >= 0.5;
    console.log("Mock random response", randomResponse);
    return httpFaker(url, method, data, randomResponse);
}

export function getPaymentPlansMock(url, method, data) {
    return httpFaker(url, method, data, [
        {
            seriesNum: 1011,
            dueDate: "2020-09-28",
            statementType: "PENDING",
            statementStatus: "PENDING",
            amortization: 91.08,
            interest: 1.51,
            fee: 3.41,
            paidAmount: 0.0,
            totalAmount: 96.0
        },
        {
            seriesNum: 1010,
            dueDate: "2020-08-28",
            statementType: "PENDING",
            statementStatus: "PENDING",
            amortization: 89.59,
            interest: 3.0,
            fee: 3.41,
            paidAmount: 0.0,
            totalAmount: 96.0
        },
        {
            seriesNum: 1009,
            dueDate: "2020-07-28",
            statementType: "PENDING",
            statementStatus: "PENDING",
            amortization: 88.13,
            interest: 4.46,
            fee: 3.41,
            paidAmount: 0.0,
            totalAmount: 96.0
        },
        {
            seriesNum: 1008,
            dueDate: "2020-06-28",
            statementType: "PENDING",
            statementStatus: "PENDING",
            amortization: 86.68,
            interest: 5.9,
            fee: 3.42,
            paidAmount: 0.0,
            totalAmount: 96.0
        },
        {
            seriesNum: 1007,
            dueDate: "2020-05-28",
            statementType: "PENDING",
            statementStatus: "PENDING",
            amortization: 85.28,
            interest: 7.31,
            fee: 3.41,
            paidAmount: 0.0,
            totalAmount: 96.0
        },
        {
            seriesNum: 1006,
            dueDate: "2020-04-28",
            statementType: "UNPAID",
            statementStatus: "UNPAID",
            amortization: 83.88,
            interest: 8.7,
            fee: 3.42,
            paidAmount: 0.0,
            totalAmount: 96.0
        },
        {
            seriesNum: 1005,
            dueDate: "2020-03-28",
            statementType: "REGULAR",
            statementStatus: "PAIDINFULL",
            amortization: 82.51,
            interest: 10.07,
            fee: 3.42,
            paidAmount: 0.0,
            totalAmount: 96.0
        },
        {
            seriesNum: 1004,
            dueDate: "2020-02-28",
            statementType: "REGULAR",
            statementStatus: "PAIDINFULL",
            amortization: 81.18,
            interest: 11.41,
            fee: 3.41,
            paidAmount: 0.0,
            totalAmount: 96.0
        },
        {
            seriesNum: 1003,
            dueDate: "2020-01-28",
            statementType: "REGULAR",
            statementStatus: "PAIDINFULL",
            amortization: 79.84,
            interest: 12.74,
            fee: 3.42,
            paidAmount: 0.0,
            totalAmount: 96.0
        },
        {
            seriesNum: 1002,
            dueDate: "2019-12-28",
            statementType: "REGULAR",
            statementStatus: "PAIDINFULL",
            amortization: 78.55,
            interest: 14.04,
            fee: 3.41,
            paidAmount: 0.0,
            totalAmount: 96.0
        },
        {
            seriesNum: 1001,
            dueDate: "2019-11-28",
            statementType: "REGULAR",
            statementStatus: "PAIDINFULL",
            amortization: 77.27,
            interest: 15.32,
            fee: 3.41,
            paidAmount: 0.0,
            totalAmount: 96.0
        },
        {
            seriesNum: 1000,
            dueDate: "2019-10-28",
            statementType: "REGULAR",
            statementStatus: "PAIDINFULL",
            amortization: 76.01,
            interest: 22.66,
            fee: 4.33,
            paidAmount: 0.0,
            totalAmount: 103.0
        },
        {
            amortization: 0,
            dueDate: "2020-04-28",
            fee: 0,
            interest: 0,
            paidAmount: 0,
            seriesNum: null,
            statementStatus: "NOT_GENERATED",
            statementType: "NOT_GENERATED",
            totalAmount: 0
        }
    ]);
}

export function getVerifyTokenMock(url, method, data) {
    return httpFaker(url, method, data, true);
}

export function getRenewTokenMock(url, method, data) {
    return httpFaker(url, method, data, {
        accessToken: "jf2320fj092jf0932jf"
    });
}

export function getRemainingDebtMock(url, method, data) {
    return httpFaker(url, method, data, {
        ocrNumber: "string",
        remainingDebts: 0,
        excludedCollectionAmount: 0,
        items: [
            {
                paymentReceiver: "string",
                bankAccountNumber: "string",
                remainingPrincipal: 0,
                interest: 10,
                unpaidStatementsAmount: 20,
                remainingStartupFee: 0,
                pendingStatementAmount: 5,
                upcomingStatementFee: 10,
                upcomingInsuranceFee: 0,
                serviceFee: 0
            },
            {
                paymentReceiver: "string",
                bankAccountNumber: "string",
                remainingPrincipal: 0,
                interest: 10,
                unpaidStatementsAmount: 20,
                remainingStartupFee: 0,
                pendingStatementAmount: 5,
                upcomingStatementFee: 10,
                upcomingInsuranceFee: 0,
                serviceFee: 0
            }
        ]
    });
}

export function getDocumentMock(url, method, data) {
    const file = new Blob(["Dummy pdf"], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    console.log(url);
}

export function getTableMock(...lens) {
    const range = (len) => {
        const arr = [];
        for (let i = 0; i < len; i++) {
            arr.push(i);
        }
        return arr;
    };

    const min = 1000000;
    const max = 2000000;

    const firstInvoiceNumber = Math.round(Math.random() * (max - min) + min);
    const now = addDays(new Date(), 5);

    const newInvoiceRow = (i) => {
        const loanPayment = Math.floor(Math.random() * 100);
        const interest = Math.floor(Math.random() * 100);
        const otherPayments = 7;
        const statementAmount = loanPayment + interest + otherPayments;
        const due = addMonths(now, i);
        const parsed = format(due, DEFAULT_FORMAT_DATE);

        return {
            invoiceNumber: firstInvoiceNumber + i,
            definition: namor.generate({ words: 1, numbers: 0 }),
            status: namor.generate({ words: 1, numbers: 0 }),
            dueDate: parsed,
            loanPayment,
            interest,
            otherPayments,
            statementAmount
        };
    };

    const makeDataLevel = (Debth = 0) => {
        const len = lens[Debth];
        console.log(len);
        return range(len).map((d, i) => {
            return {
                ...newInvoiceRow(i)
            };
        });
    };
    console.log(makeDataLevel());

    return makeDataLevel();
}

export function setStatementChannelMock(url, method, data) {
    const randomResponse = Math.random() >= 0.5;
    console.log("Mock random response", randomResponse);
    return httpFaker(url, method, data, randomResponse);
}

export function updateUserInfoMock(url, method, data) {
    const randomResponse = Math.random() >= 0.5;
    console.log("Mock random response", randomResponse);
    return httpFaker(url, method, data, randomResponse);
}
