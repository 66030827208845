import React from "react";
import { BasicText } from "./Fonts/BasicText";
import styled from "styled-components/macro";

const Title = styled.div`
    display: flex;
    margin: 0 0 8px 0;
    padding: 0;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 0 16px 0;
    padding: 0;
`;

export function ContentItem(props) {
    const { title, edit, editStateElement, children } = props;

    return (
        <Container>
            {title && (
                <Title>
                    <BasicText bold>{title}</BasicText>
                </Title>
            )}
            {!edit && <BasicText>{children}</BasicText>}
            {edit && editStateElement}
        </Container>
    );
}
