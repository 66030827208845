import {
    CONTENTFUL_NEWS_FETCH_SUCCESS,
    CONTENTFUL_NEWS_FETCH_TRIGGER
} from "../constants/contentful";

export const fetchContentfulNewsSuccess = (data) => ({
    type: CONTENTFUL_NEWS_FETCH_SUCCESS,
    payload: data
});

export function fetchContentfulNewsTrigger(data) {
    return { type: CONTENTFUL_NEWS_FETCH_TRIGGER, payload: data };
}
