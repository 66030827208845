import { defineMessages } from "react-intl";

export const messages = defineMessages({
    change: {
        id: "navigation.mobile.change"
    },
    contact: {
        id: "navigation.mobile.contact"
    },
    home: {
        id: "navigation.mobile.home"
    },
    loan: {
        id: "navigation.mobile.loan"
    },
    more: {
        id: "navigation.mobile.more"
    },
    menutext: {
        id: "navigation.mobile.more.menutext"
    },
    user: {
        id: "navigation.mobile.user"
    }
});
