import React from "react";
import styled from "styled-components/macro";

import { Icon } from "./Icon";
import { colors } from "../constants/styles";
import { ActionTitle } from "./ActionTitle";

const SideIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
`;

export const CollapsiblePanelTitle = (props) => {
    const { title, isOpen } = props;

    return (
        <ActionTitle title={title} noBottomMargin>
            <SideIcon>
                {isOpen ? (
                    <Icon icon={["fas", "caret-up"]} color={colors.gray} size="lg" />
                ) : (
                    <Icon icon={["fas", "caret-down"]} color={colors.gray} size="lg" />
                )}
            </SideIcon>
        </ActionTitle>
    );
};
