export const fonts = {
    basic: "Roboto",
    helvetica: "Helvetica Neue"
};

export const fontSizes = {
    small: "14px",
    normal: "16px",
    large: "18px",
    xlarge: "24px",
    largeTitle: "28px"
};

export const colors = {
    white: "#ffffff",
    black: "#000000",
    gray: "#444444",
    green: "#5c9c4b",
    ligthGreen: "#6bb657",
    lightGray: "#f9f9f9",
    borderGray: "#e0e0e0",
    borderDarkGray: "#979797",
    mediumGray: "#8c8c8c",
    darkGreen: "#538c43",
    darkerGreen: "#446134",
    paleGreen: "#aecea5",
    orange: "#ff5711",
    linkBlue: "#196da8",
    inputBorder: "#979797"
};

export const breakpoints = {
    mobile: "(max-width: 786px)",
    tablet: "(min-width: 786px) and (max-width: 1080px)"
};
