import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import * as R from "ramda";
import { InstallmentsButton } from "../InstallmentsButton";
import { BoxDate } from "../BoxContent";
import { BasicText } from "../Fonts/BasicText";
import { BigText } from "../Fonts/BigText";
import styled from "styled-components/macro";
import { selectPaymentPlansWithStatement } from "../../selectors/paymentPlans";
import { fetchDocument } from "../../util/api";
import { Currency } from "../Currency";
import { STATEMENT_STATUSES } from "../../constants/statementStatus";

import { messages } from "./messages";

export const Container = styled.div`
    margin: 12px 0px 12px 0px;
`;

export function NextInstallmentButton() {
    const getPaymentPlans = useMemo(selectPaymentPlansWithStatement, []);
    const { paymentPlans, token } = useSelector((state) => getPaymentPlans(state));

    if (!paymentPlans && paymentPlans.length === 0) {
        return null;
    }

    const nextPartiallyPaidStatement = R.find(
        R.propEq("statementStatus", STATEMENT_STATUSES.PARTIALLYPAID)
    )(paymentPlans);
    const nextPendingStatement = R.find(R.propEq("statementStatus", STATEMENT_STATUSES.PENDING))(
        paymentPlans
    );
    const nextUnpaidStatement = R.find(R.propEq("statementStatus", STATEMENT_STATUSES.UNPAID))(
        paymentPlans
    );

    if (nextUnpaidStatement) {
        return (
            <InstallmentsButton
                onClick={() =>
                    fetchDocument({ documentId: nextUnpaidStatement.documentId, token })
                }>
                <BasicText>
                    <FormattedMessage {...messages.next} />
                </BasicText>
                <Container>
                    <BigText bold>
                        <Currency>{nextUnpaidStatement.totalAmount}</Currency>
                    </BigText>
                </Container>
                <BoxDate>{nextUnpaidStatement.dueDate}</BoxDate>
            </InstallmentsButton>
        );
    }

    if (nextPendingStatement || nextPartiallyPaidStatement) {
        const totalAmount = nextPendingStatement
            ? nextPendingStatement.totalAmount
            : nextPartiallyPaidStatement.totalAmount;
        const dueDate = nextPendingStatement
            ? nextPendingStatement.dueDate
            : nextPartiallyPaidStatement.dueDate;
        console.log("got due date", dueDate);
        return (
            <InstallmentsButton>
                <BasicText>
                    <FormattedMessage {...messages.upcoming} />
                </BasicText>
                <Container>
                    <BigText bold>
                        <Currency>{totalAmount}</Currency>
                    </BigText>
                </Container>
                <BoxDate>{dueDate}</BoxDate>
            </InstallmentsButton>
        );
    }

    return null;
}
