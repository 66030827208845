import React from "react";
import { defineMessages } from "react-intl";
import { Link } from "react-router-dom";

export const messages = defineMessages({
    pagenotfound: {
        id: "page.error.pagenotfound"
    },
    genericerror: {
        id: "page.error.genericerror"
    },
    errortext: {
        id: "page.error.errortext",
        values: {
            linkto: (value) => <Link to="/">{value}</Link>
        }
    }
});
