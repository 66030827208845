import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware } from "connected-react-router";
import { rootSaga } from "./sagas";
import { createRootReducer } from "./reducers";
import { createBrowserHistory } from "history";

export const h = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();

/*const middleware = composeEnhancers(applyMiddleware(sagaMiddleware));

/*
const preloadedState = {};
const root = (state, action) => {
    // Clear all data in redux store to initial.
    if (action.type === UI_RESET) state = undefined;

    return createRootReducer(state, action);
};
*/

export const store = createStore(
    createRootReducer(h),
    {},
    composeEnhancers(
        applyMiddleware(
            routerMiddleware(h), // for dispatching history actions
            sagaMiddleware
        )
    )
);

sagaMiddleware.run(rootSaga);
