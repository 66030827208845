import { defineMessages } from "react-intl";

export const messages = defineMessages({
    name: {
        id: "customer.name"
    },
    bankaccount: {
        id: "customer.bankaccount"
    },
    personalnumber: {
        id: "customer.personalnumber"
    },
    accountnumber: {
        id: "page.summarymodal.accountnumber"
    },
    createddate: {
        id: "page.summarymodal.createddate"
    },
    appliedamount: {
        id: "page.summarymodal.appliedamount"
    },
    remainingtenor: {
        id: "page.summarymodal.remainingtenor"
    },
    monthlyfee: {
        id: "page.summarymodal.monthlyfee"
    }
});
