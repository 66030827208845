import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Icon } from "../Icon";
import { colors } from "../../constants/styles";
import {
    MobileIconContainer,
    StyledLinkContainer,
    MobileIconText
} from "./MobileNavigation.styled";

export function FooterLink({ route, children, icon, size }) {
    const isActive = useRouteMatch(route);
    const history = useHistory();
    return (
        <MobileIconContainer onClick={() => {
            history.push(route);
        }}>
            <StyledLinkContainer to={`${route}`}>
                <Icon
                    icon={icon}
                    size={size || "lg"}
                    color={isActive ? colors.gray : colors.mediumGray}
                />
                <MobileIconText isActive={isActive}>{children}</MobileIconText>
            </StyledLinkContainer>
        </MobileIconContainer>
    );
}
