import React from "react";
import styled from "styled-components/macro";
import { breakpoints } from "../constants/styles";
import { NextInstallmentButton } from "./NextInstallmentButton";
import { RemainingTenorButton } from "./RemainingTenorButton";
/*import { useSelector } from "react-redux";
import {addMonths, format, startOfMonth} from "date-fns";
import { InstallmentsButton } from "./InstallmentsButton";
import { BoxTitle, BoxAmount, BoxDate } from "./BoxContent";*/

const InstallmentContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 46px 0;
    @media ${breakpoints.mobile} {
        flex-direction: column;
    }
`;

const InstallmentButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
    width: 200px;
    height: 136px;
    &:first-child {
        margin-right: 32px;
    }
    &:last-child {
        margin-left: 32px;
    }
    @media ${breakpoints.mobile} {
        margin: 0;
        display: flex;
        justify-content: center;
        &:first-child {
            margin-right: 0;
        }
        &:last-child {
            margin-left: 0;
            margin-top: 24px;
        }
    }
`;

export function Installments() {
    return (
        <InstallmentContainer>
            <InstallmentButtonContainer>
                <NextInstallmentButton />
            </InstallmentButtonContainer>
            <InstallmentButtonContainer>
                <RemainingTenorButton />
            </InstallmentButtonContainer>
        </InstallmentContainer>
    );
}
