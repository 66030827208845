import React from "react";
import { PageTitleContainer, Title, RightContent } from "./styled";
import { LargeTitle } from "../Fonts/LargeTitle";

export const PageTitleWithRightContent = (props) => {
    const { title, children } = props;
    return (
        <PageTitleContainer>
            <Title>
                <LargeTitle>{title}</LargeTitle>
            </Title>
            <RightContent>{children}</RightContent>
        </PageTitleContainer>
    );
};
