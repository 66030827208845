import {
    FETCH_PDF_TRIGGER,
    FETCH_PDF_SUCCESS,
    FETCH_PDF_ERROR,
    FETCH_PDF_RESET
} from "../constants/fetchPdf";

export const fetchPdfDocumentTrigger = (data, params) => ({
    type: FETCH_PDF_TRIGGER,
    params: { ...params },
    payload: data
});

export const fetchPdfDocumentSuccess = () => ({
    type: FETCH_PDF_SUCCESS
});

export const fetchPdfDocumentError = () => ({
    type: FETCH_PDF_ERROR
});

export const fetchPdfDocumentReset = () => ({
    type: FETCH_PDF_RESET
});
