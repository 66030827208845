import { takeEvery } from "@redux-saga/core/effects";
import { START_PAGE_TRIGGER } from "../constants/page";

export function* watchStartPageLoad() {
    yield takeEvery(START_PAGE_TRIGGER, handleStartPageLoad);
}

export function* handleStartPageLoad() {
    yield console.log("start page loader!");
}
