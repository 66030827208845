import { defineMessages } from "react-intl";

export const messages = defineMessages({
    paidtitle: {
        id: "loan.calculator.information.paidtitle"
    },
    toatalammounttitle: {
        id: "loan.calculator.information.toatalammounttitle"
    }
});
