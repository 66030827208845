import styled from "styled-components/macro";

import { breakpoints, colors } from "../../constants/styles";

export const UserPageContent = styled.div`
    margin-bottom: 16px;
    &:last-child {
        margin-bottom: 0px;
    }
    @media ${breakpoints.mobile} {
        &:last-child {
            margin-bottom: 16px;
        }
    }
`;

export const UserPageContainer = styled.span`
    display: block;
    margin-left: 24px;
    @media ${breakpoints.mobile} {
        margin-left: 0px;
    }
`;

export const SettingsButtonText = styled.span`
    display: inline;
    color: ${colors.white};
    margin-left: 8px;
    @media ${breakpoints.mobile} {
        display: none;
    }
`;
