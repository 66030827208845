import { defineMessages } from "react-intl";

export const messages = defineMessages({
    next: {
        id: "button.nextinstallment.next"
    },
    upcoming: {
        id: "button.nextinstallment.upcoming"
    }
});
