import * as R from "ramda";
import { camelToKebab } from "../../util/camelToKebab";

export const makeStyles = (theme) => {
    let styles = "";
    for (let key in theme) {
        if (theme.hasOwnProperty(key) && !R.is(Object, theme[key])) {
            styles += `${camelToKebab(key)}: ${theme[key]};`;
        }
    }
    return styles;
};
