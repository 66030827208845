import { takeEvery, put } from "@redux-saga/core/effects";
import { USER_PAGE_TRIGGER } from "../constants/page";
import { fetchAccountStatementsTrigger } from "../actions/account";
import { fetchPaymentPlansTrigger } from "../actions/paymentPlans";

export function* watchUserPageLoad() {
    yield takeEvery(USER_PAGE_TRIGGER, handleUserPageLoad);
}

export function* handleUserPageLoad() {
    yield put(fetchAccountStatementsTrigger());
    yield put(fetchPaymentPlansTrigger());
}
