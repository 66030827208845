import { createSelector } from "reselect";
import { wideMonth, abbreviatedMonth } from "../util/getMonth";
import { SPECIAL_PAYMENT_AGREEMENTS_STATUS } from "../constants/specialPaymentAgreements";

export const getStatements = (state) => (state.statements ? state.statements : []);

export const getSpecialPaymentAgreement = () =>
    createSelector([getStatements], ({ paymentAgreements }) => {
        const paymentFreeMonths = paymentAgreements
            .filter(
                (paymentAgreement) =>
                    paymentAgreement.state !== SPECIAL_PAYMENT_AGREEMENTS_STATUS.CANCELLED
            )
            .sort((a, b) => (a.requestMonth > b.requestMonth ? 1 : -1))
            .map((paymentAgreement) => {
                const splittedYearAndMonth = paymentAgreement.requestMonth.split("-");
                const monthIndex = parseInt(splittedYearAndMonth[1]) - 1;
                return {
                    year: splittedYearAndMonth[0],
                    monthWideName: wideMonth(monthIndex),
                    monthAbbreviatedName: abbreviatedMonth(monthIndex),
                    monthIndex
                };
            });

        return paymentFreeMonths;
    });
