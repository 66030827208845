import { library } from "@fortawesome/fontawesome-svg-core";

import {
    faCaretDown,
    faCaretLeft,
    faCaretRight,
    faCaretUp,
    faCircle,
    faCircleNotch,
    faCog,
    faComments,
    faEllipsisH,
    faHome,
    faPencilAlt,
    faPhone,
    faSave,
    faSignOutAlt,
    faSyncAlt,
    faTimes,
    faUserAlt,
    faSignInAlt,
    faCalendar,
    faQuestionCircle,
    faThumbsUp
} from "@fortawesome/free-solid-svg-icons";

import {
    faAddressBook,
    faArrowAltCircleRight,
    faClock,
    faEnvelope,
    faMoneyBillAlt
} from "@fortawesome/free-regular-svg-icons";

library.add(
    faAddressBook,
    faArrowAltCircleRight,
    faCaretDown,
    faCaretLeft,
    faCaretRight,
    faCaretUp,
    faCircle,
    faCircleNotch,
    faClock,
    faCog,
    faComments,
    faEllipsisH,
    faEnvelope,
    faHome,
    faMoneyBillAlt,
    faPencilAlt,
    faPhone,
    faSave,
    faSignOutAlt,
    faSyncAlt,
    faTimes,
    faUserAlt,
    faThumbsUp,
    faSignInAlt,
    faQuestionCircle,
    faCalendar
);
