import React from "react";
import styled from "styled-components/macro";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { breakpoints, colors } from "../../constants/styles";

const StyledHeadline = styled.p`
    color: ${colors.gray};
    font-size: 18px;
    font-weight: bold;
    line-height: 1.22;
    margin: 0 0 16px 0;
    display: flex;
    flex-direction: row;
`;

const StyledCloseIcon = styled.span`
    /*position: relative;
    top: -14px;
    right: -16px;
    float: right;
    padding: 14px 16px;*/
    cursor: pointer;
    margin-left: auto;
`;

const StyledModal = styled(Modal).attrs({
    className: "ModalDialog"
})`
    &.ModalDialog {
        position: relative;
        padding: 32px;
        margin: auto;
        background-color: #f9f9f9;
        border-radius: 8px;
        box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2);
        width: 664px;
        left: auto;
        right: auto;
        top: auto;
        bottom: auto;
        @media ${breakpoints.mobile} {
            position: absolute;
            padding: 16px;
            width: 100%;
            top: 64px;
            bottom: 56px;
            border-radius: 0;
            overflow-y: scroll;
            left: 0;
            right: 0;
        }
        :focus {
            outline: none;
        }
    }
`;

const modalStyles = {
    overlay: {
        backgroundColor: "rgba(0,0,0,0.4)",
        display: "flex",
        zIndex: 1500
    }
};

Modal.setAppElement("#root");

export const ModalDialog = (props) => {
    const { headline, open, closeDialog, children } = props;

    console.log(children);

    return (
        <StyledModal
            isOpen={open}
            onRequestClose={closeDialog}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            style={modalStyles}>
            <StyledHeadline>
                {headline}
                <StyledCloseIcon onClick={closeDialog}>
                    <FontAwesomeIcon icon="times" />
                </StyledCloseIcon>
            </StyledHeadline>
            {children}
        </StyledModal>
    );
};
