import styled from "styled-components/macro";
import { fontSizes, colors, breakpoints } from "../../constants/styles";

export const StyledParagraph = styled.p`
    margin: 0;
    margin-top: ${(props) => (props.marginTop ? props.marginTop : `0`)};
    font-size: ${fontSizes.normal};
    line-height: 1.71;
    @media ${breakpoints.mobile} {
        margin-left: 56px;
        margin-top: -18px;
    }
`;

export const FeedbackBox = styled.textarea`
    width: 552px;
    height: 54px;
    border-radius: 8px;
    padding: 8px;
    margin-top: 24px;
    border: solid 1px #979797;
    background-color: #ffffff;
    font-size: ${fontSizes.normal};
    color: ${colors.gray};
    line-height: 1.75;
    @media ${breakpoints.mobile} {
        width: 98%;
    }
`;

export const FeedBackComment = styled(StyledParagraph)`
    @media ${breakpoints.mobile} {
        margin-top: 16px;
    }
`;
export const Centerd = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 24px;
    @media ${breakpoints.mobile} {
        display: flex;
        justify-content: flex-start;
    }
`;

export const Container = styled.div`
    margin-top: 38px;
    @media ${breakpoints.mobile} {
        margin-top: 24px;
    }
`;

export const WritingFeedback = styled.div``;
export const SentFeedback = styled.div``;
