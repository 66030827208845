export const USER_LOGIN = "USER/LOGIN";
export const USER_LOGOUT = "USER/LOGOUT";
export const USER_UPDATE_INFO_TRIGGER = "USER/UPDATE_INFO/TRIGGER";
export const USER_UPDATE_INFO_SUCCESS = "USER/UPDATE_INFO/SUCCESS";
export const USER_UPDATE_INFO_ERROR = "USER/UPDATE_INFO/ERROR";
export const USER_UPDATE_INFO_RESET = "USER/UPDATE_INFO/RESET";
export const USER_UPDATE_TOKEN = "USER/UPDATE_TOKEN";
export const USER_FETCH_CUSTOMER_TRIGGER = "FETCH/CUSTOMER/TRIGGER";
export const USER_FETCH_CUSTOMER_SUCCESS = "FETCH/CUSTOMER/SUCCESS";
export const USER_VALIDATE_TOKEN = "USER/VALIDATE_TOKEN";
export const USER_UPDATE_IS_VALIDATING_TOKEN = "USER/UPDATE_IS_VALIDATING_TOKEN";
export const USER_UPDATE_IS_VALID_TOKEN = "USER/UPDATE_IS_VALID_TOKEN";
export const USER_UPDATE_INITIALIZED = "USER/UPDATE/INITIALIZED";
