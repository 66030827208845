import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { colors, breakpoints, fontSizes } from "../../constants/styles";
import { BasicText } from "../Fonts/BasicText";

export const MobileNavigationContainer = styled.div`
    display: none;
    position: absolute;
    bottom: 0;
    z-index: 2000;
    @media ${breakpoints.mobile} {
        display: flex;
        position: fixed;
        width: 100%;
        height: 56px;
        box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.2);
        background-color: #ffffff;
    }
`;

export const MobileIconContainer = styled.div`
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => (props.isOpen ? colors.borderGray : "none")};
`;

export const MobileIconText = styled.span`
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${(props) => (props.isActive ? colors.gray : colors.gray)};
`;

export const MobileNavigationItemsContainer = styled.div`
    margin-left: 8px;
`;

export const StyledMoreFooterLink = styled(Link)`
    display: flex;
    flex-direction: row;
    text-decoration: none;
    padding-top: 17px;
`;

export const MobileFeedbackformContainer = styled.div`
    @media ${breakpoints.mobile} {
        padding-top: 17px;
        padding-bottom: 25px;
    }
`;

export const StyledMoreIconText = styled.span`
    line-height: 1.5;
    color: ${(props) => (props.isActive ? colors.gray : colors.mediumGray)};
    margin: 0 0 20px 0;
`;

export const StyledMoreIcon = styled.span`
    margin-right: 32px;
`;
export const StyledLinkContainer = styled(Link)`
    display: flex;
    flex-direction: column;
    color: ${colors.gray};
    text-decoration: none;
    align-items: center;
    justify-content: center;
    &:hover {
        color: #000000;
    }
    &:active {
        color: #000000;
    }
`;

export const StyledListHeader = styled.div`
    margin: 9px 8px 11px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const StyledHeaderText = styled(BasicText)`
    font-size: ${fontSizes.large};
`;

export const StyledHeaderIcon = styled.span`
    float: right;
`;

export const MoreButtonContainer = styled.div`
    font-size: 14px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    border-radius: 0;
    margin-left: 0;
    width: auto;
    height: auto;
`;

export const MoreContentItem = styled.div`
    font-size: 14px;
    padding: 7px 8px;
    border-bottom: 1px solid ${colors.borderGray};
    :last-child {
        border-bottom: none;
    }
`;
