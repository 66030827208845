import styled from "styled-components/macro";

import { colors, fontSizes } from "../../../constants/styles";
import { makeStyles } from "../utils";

export const StyledList = styled.ul`
    ${({ theme }) => makeStyles(theme)}

    ${(props) =>
        !props.noListArrow &&
        `&::before {
        content: " ";
        position: absolute;
        width: 13px;
        height: 13px;
        background-color: ${colors.white};
        border-top: 1px solid ${colors.borderGray};
        border-left: 1px solid ${colors.borderGray};
        top: 11px;
        right: 0px;
        transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
    }`}
`;

export const StyledListItem = styled.li`
    font-size: ${fontSizes.small};
    padding: 7px 8px;
    border-bottom: 1px solid #e0e0e0;
    :last-child {
        border-bottom: none;
    }
`;
