import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { addMonths, format } from "date-fns";
import { FormattedMessage } from "react-intl";
import { BoxDate } from "../BoxContent";
import { InstallmentsButton } from "../InstallmentsButton";
import { BasicText } from "../Fonts/BasicText";
import { BigText } from "../Fonts/BigText";
import styled from "styled-components/macro";

import { messages } from "./messages";

export const Container = styled.div`
    margin: 12px 0px 12px 0px;
`;

export function RemainingTenorButton() {
    let history = useHistory();
    const { activeAccountInfo } = useSelector((state) => {
        return {
            activeAccountInfo: state.accounts.activeAccountInfo
        };
    });

    if (!activeAccountInfo) {
        return null;
    }

    const maturity = activeAccountInfo.remainingTenor ? activeAccountInfo.remainingTenor : 0;

    const now = new Date();
    const until = addMonths(now, maturity);

    const handleClick = () => {
        history.push("/loan#statements");
    };

    return (
        <InstallmentsButton onClick={() => handleClick()}>
            <BasicText>
                <FormattedMessage {...messages.title} />
            </BasicText>
            <Container>
                <BigText bold>{maturity}</BigText>
            </Container>
            <BoxDate>
                <FormattedMessage
                    {...messages.until}
                    values={{
                        dateuntil: format(until, "MM.yyyy")
                    }}
                />
            </BoxDate>
        </InstallmentsButton>
    );
}
