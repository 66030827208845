import React from "react";
import styled from "styled-components/macro";
import { fontSizes, colors } from "../../constants/styles";

const StyledButton = styled.button`
    cursor: pointer;
    background-color: ${colors.white};
    border-radius: 4px;
    padding: ${(props) => (props.padding ? props.padding : `1px 21px 2px`)};
    height: 31px;
    font-size: ${fontSizes.normal};
    color: ${colors.darkerGreen};
    font-weight: bold;
    box-shadow: 0 0 1px 0 rgba(68, 97, 52, 0.7);
`;

export const LightButton = ({ onClick, children, padding, disabled }) => {
    return (
        <StyledButton onClick={onClick} padding={padding} disabled={disabled}>
            {children}
        </StyledButton>
    );
};
