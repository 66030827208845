import {
    USER_FETCH_CUSTOMER_SUCCESS,
    USER_FETCH_CUSTOMER_TRIGGER,
    USER_LOGIN,
    USER_LOGOUT,
    USER_UPDATE_INFO_TRIGGER,
    USER_UPDATE_INFO_SUCCESS,
    USER_UPDATE_INFO_ERROR,
    USER_UPDATE_INFO_RESET,
    USER_UPDATE_IS_VALID_TOKEN,
    USER_UPDATE_IS_VALIDATING_TOKEN,
    USER_UPDATE_TOKEN,
    USER_VALIDATE_TOKEN, USER_UPDATE_INITIALIZED
} from "../constants/user";

export function login(history) {
    return {
        type: USER_LOGIN,
        payload: history
    };
}

export function logout(history) {
    return {
        type: USER_LOGOUT,
        payload: history
    };
}

export function triggerUpdateUserInfo(payload) {
    return {
        type: USER_UPDATE_INFO_TRIGGER,
        payload
    };
}

export function successUpdateUserInfo() {
    return {
        type: USER_UPDATE_INFO_SUCCESS
    };
}

export function resetUpdateUserInfo() {
    return {
        type: USER_UPDATE_INFO_RESET
    };
}

export function errorUpdateUserInfo() {
    return {
        type: USER_UPDATE_INFO_ERROR
    };
}

export function updateToken(payload) {
    return {
        type: USER_UPDATE_TOKEN,
        payload
    };
}

export function fetchCustomerTrigger(payload) {
    return {
        type: USER_FETCH_CUSTOMER_TRIGGER,
        payload
    };
}

export function fetchCustomerSuccess(payload) {
    return {
        type: USER_FETCH_CUSTOMER_SUCCESS,
        payload,
    };
}

export function validateToken() {
    return {
        type: USER_VALIDATE_TOKEN
    };
}

export function updateIsValidatingToken(payload) {
    return {
        type: USER_UPDATE_IS_VALIDATING_TOKEN,
        payload
    };
}

export function updateIsValidToken(payload) {
    return {
        type: USER_UPDATE_IS_VALID_TOKEN,
        payload
    };
}

export function updateInitialized(payload) {
    return {
        type: USER_UPDATE_INITIALIZED,
        payload,
    }
}
