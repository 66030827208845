import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import * as R from "ramda";
import { format, parseISO } from "date-fns";
import { GenericTable } from "../../GenericTable";
import { StatementLink } from "../StatementLink";
import { getCurrency, Currency } from "../../Currency";
import { StyledStatementContainer, StyledStatementInfo, StyledStatementText } from "../styled";
import { DEFAULT_FORMAT_DATE } from "../../../constants/dateTime";
import { CELL_TYPES } from "../constants";

import { messages } from "./messages";

export const MobileInvoicesTable = ({
    tableData,
    token,
    noButton,
    pdfData,
    pdfParams,
    pdfDispatch,
    pdfHeaders,
    loading,
}) => {
    const { formatMessage: f } = useIntl();

    const StatementTitle = ({ documentId, token, children }) =>
        documentId ? (
            <StatementLink documentId={documentId} token={token}>
                {children}
            </StatementLink>
        ) : (
            children
        );

    const CellContent = ({ title, statementStatus, dueDate, totalAmount, documentId }) => {
        return (
            <StyledStatementContainer>
                <StyledStatementInfo>
                    <StatementTitle documentId={documentId} token={token}>
                        {title}
                    </StatementTitle>
                    <StyledStatementText>{statementStatus}</StyledStatementText>
                    <StyledStatementText>{dueDate}</StyledStatementText>
                </StyledStatementInfo>
                <StyledStatementInfo>
                    <StyledStatementText bold>
                        <Currency>{totalAmount}</Currency>
                    </StyledStatementText>
                </StyledStatementInfo>
            </StyledStatementContainer>
        );
    };

    const columns = useMemo(
        () => [
            {
                accessor: "title",
                Cell: ({
                    cell: {
                        row: { original },
                    },
                }) => {
                    return (
                        <CellContent
                            title={`${f(messages.statementtitle)} ${original.title}`}
                            statementStatus={f(messages[original.statementStatus])}
                            dueDate={original.dueDate}
                            totalAmount={original.totalAmount}
                            documentId={original.documentId}
                        />
                    );
                },
                Footer: (data) => {
                    const totalAmortization = useMemo(
                        () => data.rows.reduce((sum, row) => row.original.totalAmount + sum, 0),
                        [data.rows]
                    );
                    return <CellContent title={f(messages.sum)} totalAmount={totalAmortization} />;
                },
            },
        ],
        [f]
    );

    let dataPdf = null;

    const modifyData = (tableRow) => {
        const omitProps = columns
            .filter((item) => !item.exportToPdf)
            .map((column) => column.accessor);

        let currentRow = R.omit(omitProps, tableRow);
        pdfHeaders.forEach((header) => {
            const { accessor, type } = header;
            if (type === CELL_TYPES.DATE) {
                const newValue = format(parseISO(currentRow[accessor]), DEFAULT_FORMAT_DATE);
                currentRow = {
                    ...currentRow,
                    [accessor]: newValue,
                };
            }
            if (type === CELL_TYPES.CURRENCY) {
                const newValue = getCurrency(currentRow[accessor]);
                currentRow = {
                    ...currentRow,
                    [accessor]: newValue,
                };
            }
        });

        return currentRow;
    };

    if (pdfData) {
        const tableData = R.map(modifyData, pdfData.table.tableData);

        dataPdf = {
            ...pdfData,
            table: {
                headers: pdfHeaders,
                tableData,
            },
        };
    }

    return (
        <GenericTable
            columns={columns}
            data={tableData}
            noHead={true}
            noButton={noButton}
            pdfData={dataPdf}
            pdfParams={pdfParams}
            pdfDispatch={pdfDispatch}
            loading={loading}
        />
    );
};
