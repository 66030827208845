import React from 'react';
import {Provider} from "react-redux";
import {ConnectedRouter} from "connected-react-router";

export function AppContainer(props) {
    return (
        <Provider store={props.store}>
            <ConnectedRouter history={props.history}>
                {props.children}
            </ConnectedRouter>
        </Provider>
    )
}
