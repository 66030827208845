import { takeEvery, put, call, select } from "redux-saga/effects";
import {
    fetchAccountsTrigger,
    saveBillingSettingsSuccess,
    saveBillingSettingsError
} from "../actions/account";
import { setStatementChannel } from "../util/api";
import { tokenAndActiveAccountId } from "../selectors/tokenAndAccountId";
import { SAVE_BILLING_SETTINGS_TRIGGER } from "../constants/account";

export function* handleSaveBillingSettings(action) {
    const { token, accountId } = yield select((state) => tokenAndActiveAccountId(state));

    const { statementChannel } = action.payload;

    const statementChannelSaveResult = yield call(setStatementChannel, {
        statementChannel,
        token,
        accountId
    });

    if (statementChannelSaveResult) {
        yield put(saveBillingSettingsSuccess());
        yield put(fetchAccountsTrigger());
    } else {
        yield put(saveBillingSettingsError());
    }
}

export function* watchSaveBillingSettings() {
    yield takeEvery(SAVE_BILLING_SETTINGS_TRIGGER, handleSaveBillingSettings);
}
