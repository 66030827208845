import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { selectPaymentPlansWithStatement } from "../../selectors/paymentPlans";
import { abbreviatedMonth } from "../../util/getMonth";
import { BasicText } from "../../components/Fonts/BasicText";
import { CalendarTitle } from "./styled";

import { messages } from "./messages";
import { CalendarContainer, Calendar, CalenderItem, CalendarText } from "./styled";

import { getMonths, disableMonths } from "./utils";

export const PaymentFreeCalendar = (props) => {
    const getPaymentPlans = useMemo(selectPaymentPlansWithStatement, []);
    const { activePaymentPlans } = useSelector((state) => getPaymentPlans(state));

    let monthsToAdd = getMonths(activePaymentPlans[0]);

    const lastStatementDueDate = activePaymentPlans[activePaymentPlans.length - 1].dueDate;

    const months = disableMonths(
        monthsToAdd,
        props.selectedMonths,
        props.existentMonths,
        lastStatementDueDate
    );
    const handleSetMonth = (item) => {
        if (!item.disabled) {
            props.setMonth("add", item);
        } else if (item.selected && !item.locked) {
            props.setMonth("remove", item);
        }
    };

    return (
        <>
            <CalendarTitle>
                <BasicText bold>
                    <FormattedMessage {...messages.calendartitle} />
                </BasicText>
            </CalendarTitle>
            <CalendarContainer>
                <Calendar>
                    {months.map((item, index) => (
                        <CalenderItem
                            key={`item-${index}`}
                            id={item.id}
                            disabled={item.disabled}
                            locked={item.locked}
                            onClick={() => handleSetMonth(item)}
                            nextYear={item.nextYear}>
                            <CalendarText bold={item.selected}>
                                {abbreviatedMonth(item.month)}
                            </CalendarText>
                            <CalendarText bold={item.selected}>{item.year}</CalendarText>
                        </CalenderItem>
                    ))}
                </Calendar>
            </CalendarContainer>
        </>
    );
};
