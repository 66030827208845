import {
    CONTENTFUL_NEWS_FETCH_TRIGGER,
    CONTENTFUL_NEWS_FETCH_SUCCESS
} from "../constants/contentful";

const initialState = {
    news: []
};

export const contentfulReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONTENTFUL_NEWS_FETCH_TRIGGER: {
            return state;
        }
        case CONTENTFUL_NEWS_FETCH_SUCCESS: {
            const newState = { ...state, news: action.payload };
            return newState;
        }
        default:
            return state;
    }
};
