import { takeEvery, put, call, select } from "redux-saga/effects";
import * as R from "ramda";
import {
    fetchSpecialPaymentAgreementTrigger,
    fetchSpecialPaymentAgreementSuccess,
    setSpecialPaymentAgreementSuccess,
    setSpecialPaymentAgreementError
} from "../actions/specialPaymentAgreements";
import {
    FETCH_SPECIAL_PAYMENT_AGREEMENT,
    SET_SPECIAL_PAYMENT_AGREEMENT
} from "../constants/specialPaymentAgreements";
import { fetchSpecialPaymentAgreements, setSpecialPaymentAgreements } from "../util/api";
import { tokenAndActiveAccountId } from "../selectors/tokenAndAccountId";

export function* handleFetchSpecialPaymentAgreement() {
    const { token, accountId } = yield select((state) => tokenAndActiveAccountId(state));

    const result = yield call(fetchSpecialPaymentAgreements, { token, accountId });

    yield put(fetchSpecialPaymentAgreementSuccess(result));
}

export function* handleSetSpecialPaymentAgreement(action) {
    const { token, accountId } = yield select((state) => tokenAndActiveAccountId(state));
    let calls = action.payload.map((item) =>
        call(setSpecialPaymentAgreements, {
            token,
            accountId,
            paymentAgreementType: item.paymentAgreementType,
            startDate: item.startDate,
            endDate: item.endDate
        })
    );
    let results = [];

    for (let c of calls) {
        results.push(yield c);
    }

    if (!R.contains(false, results)) {
        yield put(setSpecialPaymentAgreementSuccess());
        yield put(fetchSpecialPaymentAgreementTrigger());
    } else {
        yield put(setSpecialPaymentAgreementError());
    }
}

export function* watchHandleFetchSpecialPaymentAgreement() {
    yield takeEvery(FETCH_SPECIAL_PAYMENT_AGREEMENT.TRIGGER, handleFetchSpecialPaymentAgreement);
}

export function* watchSetSpecialPaymentAgreement() {
    yield takeEvery(SET_SPECIAL_PAYMENT_AGREEMENT.TRIGGER, handleSetSpecialPaymentAgreement);
}

export const specialPaymentAgreement = [
    watchHandleFetchSpecialPaymentAgreement,
    watchSetSpecialPaymentAgreement
];
