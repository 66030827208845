import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { fetchDocument } from "../../../util/api";

import { ItemList } from "../../../components/ItemList";
import { CommonButton } from "../../../components/CommonButton";

import { CenteredButtonContainer } from "../../../components/CenteredButtonContainer";
import { StyledParagraph } from "./styled";
import { messages } from "./LoanContracts.messages";

export const LoanContracts = ({ data }) => {
    const { formatMessage: f } = useIntl();
    return (
        <>
            <StyledParagraph>
                <FormattedMessage {...messages.ingress} />
            </StyledParagraph>
            <ItemList
                items={[f(messages.listitem1), f(messages.listitem2), f(messages.listitem3)]}
            />
            <CenteredButtonContainer marginTop="38px">
                <CommonButton
                    onClick={() => {
                        fetchDocument(data);
                    }}>
                    <FormattedMessage {...messages.button} />
                </CommonButton>
            </CenteredButtonContainer>
        </>
    );
};
