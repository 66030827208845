import React from "react";

import { FooterTitleContainer, FooterTitle } from "./ColumnTitle.styled";

export const ColumnTitle = ({ children }) => {
    return (
        <FooterTitleContainer>
            <FooterTitle>{children}</FooterTitle>
        </FooterTitleContainer>
    );
};
