import {
    FETCH_SPECIAL_PAYMENT_AGREEMENT,
    SET_SPECIAL_PAYMENT_AGREEMENT
} from "../constants/specialPaymentAgreements";

export const fetchSpecialPaymentAgreementTrigger = () => ({
    type: FETCH_SPECIAL_PAYMENT_AGREEMENT.TRIGGER
});

export const fetchSpecialPaymentAgreementSuccess = (payload) => ({
    type: FETCH_SPECIAL_PAYMENT_AGREEMENT.SUCCESS,
    payload
});

export const fetchSpecialPaymentAgreementError = () => ({
    type: FETCH_SPECIAL_PAYMENT_AGREEMENT.ERROR
});

export const setSpecialPaymentAgreementTrigger = (payload) => ({
    type: SET_SPECIAL_PAYMENT_AGREEMENT.TRIGGER,
    payload,
    status: {
        loading: true,
        saved: false,
        error: false
    }
});

export const setSpecialPaymentAgreementSuccess = () => ({
    type: SET_SPECIAL_PAYMENT_AGREEMENT.SUCCESS,
    payload: {
        saved: true,
        error: false,
        loading: false
    }
});

export const setSpecialPaymentAgreementError = () => ({
    type: SET_SPECIAL_PAYMENT_AGREEMENT.ERROR,
    payload: {
        saved: false,
        error: true,
        loading: false
    }
});

export const setSpecialPaymentAgreementReset = () => ({
    type: SET_SPECIAL_PAYMENT_AGREEMENT.RESET,
    payload: {
        saved: false,
        error: false
    }
});
