import React from "react";
import styled from "styled-components/macro";
import { fontSizes } from "../constants/styles";

const BoxContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    ${(props) => props.center && `align-items: center;`}
    ${(props) => props.center && `justify-content: center;`}
    ${(props) => props.center && `text-align: center;`}
    margin: 0 0 10px 0;
`;

const Title = styled.div`
    margin-bottom: 4px;
    ${(props) => props.isBold && `font-weight: bold;`}
`;

const Amount = styled.div`
    font-size: ${fontSizes.xlarge};
    font-weight: bold;
    margin-bottom: 4px;
    margin-top: 4px;
`;

const Date = styled.div`
    font-size: ${fontSizes.small};
`;

const Label = styled.span`
    font-size: ${fontSizes.normal};
    line-height: 1.5;
`;

export const BoxContent = ({ children, center }) => (
    <BoxContentContainer center={center}>{children}</BoxContentContainer>
);

export const BoxTitle = ({ children, bold }) => <Title isBold={bold}>{children}</Title>;

export const BoxAmount = ({ children }) => <Amount>{children}</Amount>;

export const BoxDate = ({ children }) => <Date>{children}</Date>;

export const BoxLabel = ({ children }) => <Label>{children}</Label>;
