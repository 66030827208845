import { takeEvery, put, call, select } from "redux-saga/effects";
import { fetchAccountStatements } from "../util/api";
import { FETCH_ACCOUNT_STATEMENTS_TRIGGER } from "../constants/account";
import { fetchAccountStatementsSuccess } from "../actions/account";

export function* watchFetchAccountStatements() {
    yield takeEvery(FETCH_ACCOUNT_STATEMENTS_TRIGGER, handleFetchAccountStatements);
}

export function* handleFetchAccountStatements() {
    const { accountId, token } = yield select((state) => {
        return {
            token: state.user.token,
            accountId: state.accounts.activeAccount.id
        };
    });

    const statements = yield call(fetchAccountStatements, {
        token,
        accountId
    });

    yield put(fetchAccountStatementsSuccess(statements));
}
