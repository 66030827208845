import React from "react";
import { RadioButtonContainer, LabelContainer, Checkmark } from "./styled";

export const RadioButton = ({ label, name, checked, ...rest }) => {
    return (
        <RadioButtonContainer>
            <LabelContainer>
                {label}
                <input type="radio" checked={checked} name={name} {...rest} />
                <Checkmark />
            </LabelContainer>
        </RadioButtonContainer>
    );
};
