import styled from "styled-components";
import { breakpoints } from "../../constants/styles";

export const RightContent = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 20%;
    @media ${breakpoints.mobile} {
        width: 100%;
        justify-content: center;
    }
`;

export const Title = styled.div`
    display: flex;
    margin: 0;
    padding: 0;
    width: 80%;
    @media ${breakpoints.mobile} {
        width: 100%;
        margin-bottom: 16px;
    }
`;

export const PageTitleContainer = styled.div`
    display: flex;
    @media ${breakpoints.mobile} {
        flex-direction: column;
    }
`;
