import { defineMessages } from "react-intl";
import { TRANSLATED_STATEMENT_STATUSES } from "../../constants/statementStatus";

export const messages = defineMessages({
    number: {
        id: "invoicestable.column.number"
    },
    title: {
        id: "invoicestable.column.title"
    },
    status: {
        id: "invoicestable.column.status"
    },
    duedate: {
        id: "invoicestable.column.duedate"
    },
    amortization: {
        id: "invoicestable.column.amortization"
    },
    interest: {
        id: "invoicestable.column.interest"
    },
    fee: {
        id: "invoicestable.column.fee"
    },
    total: {
        id: "invoicestable.column.total"
    },
    statementtitle: {
        id: "invoicestable.statement.title"
    },
    sum: {
        id: "invoicestable.sum"
    },
    ...TRANSLATED_STATEMENT_STATUSES
});
