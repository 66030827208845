import { FETCH_PAYMENT_PLANS_SUCCESS } from "../constants/paymentPlans";

export const initialState = {
    paymentPlans: []
};

export function paymentPlansReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_PAYMENT_PLANS_SUCCESS: {
            console.log("got payment plans to update", action);
            return { ...state, paymentPlans: action.payload };
        }
        default:
            return state;
    }
}
