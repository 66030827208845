import { defineMessages } from "react-intl";

export const messages = defineMessages({
    pagetitle: {
        id: "page.logout.pagetitle"
    },
    message: {
        id: "page.logout.message"
    },
    surveylink: {
        id: "page.logout.surveylink"
    }
});
