import styled from "styled-components/macro";
import { fontSizes, breakpoints } from "../../../constants/styles";

export const LoanModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    width: 100%;
`;

export const LoanSettingsContainer = styled.div`
    display: flex;
    margin: 0 84px;
`;

export const ListWithMargin = styled.div`
    margin-top: 16px;
`;

export const StyledParagraph = styled.p`
    margin: 0;
    margin-top: ${(props) => (props.marginTop ? props.marginTop : `0`)};
    font-size: ${fontSizes.small};
    line-height: 1.71;
`;

export const RadioButtonGroup = styled.div`
    margin-top: 8px;
    margin-bottom: 16px;
`;

export const UserInfo = styled.div`
    margin-top: 40px;
    display: flex;

    justify-content: center;
    align-items: center;
    width: 100%;
    @media ${breakpoints.mobile} {
        flex-direction: column;
        max-width: 320px;
        margin: 40px auto 0;
    }
`;

export const UserInfoContainerOne = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 12px;
    margin-left: auto;
    @media ${breakpoints.mobile} {
        margin-left: 24px;
    }
`;

export const UserInfoContainerTwo = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    @media ${breakpoints.mobile} {
        margin-left: 24px;
    }
`;

export const TitleValueContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 0 8px 0;
`;
