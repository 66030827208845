import styled from "styled-components/macro";
import { colors } from "../../constants/styles";

export const MoreContentContainer = styled.div`
    border-radius: 0px;
    background-color: ${colors.white};
    position: fixed;
    top: 64px;
    bottom: 56px;
    left: 0;
    right: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    border-radius: 0px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
    border: 1px solid ${colors.borderGray};
    overflow-y: scroll;
`;
