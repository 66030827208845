import styled from "styled-components/macro";
import { colors } from "../../constants/styles";

export const StyledStatementContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const StyledStatementInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
`;

export const StyledStatementLink = styled.div`
    text-decoration: underline;
    color: ${colors.orange};
    cursor: pointer;
`;

export const StyledStatementText = styled.div`
    font-weight: ${(props) => (props.bold ? "bold" : "normal")};
`;
