import React, { useEffect, useState, useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";

import { saveBillingSettings, resetSaveBillingSettings } from "../../../actions/account";
import { getStatementChannel } from "../../../selectors/activeAccountInfo";
import { RadioButton } from "../../../components/RadioButton";
import { CommonButton } from "../../../components/CommonButton";
import { ItemList } from "../../../components/ItemList";
import { SmallText } from "../../../components/Fonts/SmallText";
import { Grid2 } from "../../../components/Grid";
import { ErrorMessage } from "../../../components/ErrorMessage";
import {
    LoanModalContainer,
    LoanSettingsContainer,
    RadioButtonGroup,
    ListWithMargin
} from "./styled";
import { CenteredButtonContainer } from "../../../components/CenteredButtonContainer";
import { STATEMENT_CHANNELS } from "../../../constants/statementChannels";

import { messages } from "./LoanSettings.messages";

export const LoanSettings = (props) => {
    const selectStatementChannel = useMemo(getStatementChannel, []);
    const currentStatementChannel = useSelector((state) => selectStatementChannel(state));
    const [statementChannel, setStatementChannel] = useState(currentStatementChannel);
    const dispatch = useDispatch();
    const closeDialog = props.close;

    const { saved, error } = useSelector((state) => {
        return state.ui.billingSettingsSaved;
    });

    const { formatMessage: f } = useIntl();

    useEffect(() => {
        if (saved) {
            closeDialog();
        }
        dispatch(resetSaveBillingSettings());
    }, [closeDialog, dispatch, saved]);

    const handleSave = () => {
        const data = {
            statementChannel
        };
        dispatch(saveBillingSettings(data));
    };

    return (
        <LoanModalContainer>
            <LoanSettingsContainer>
                <Grid2>
                    <SmallText bold>{f(messages.channeltitle)}</SmallText>
                    <RadioButtonGroup>
                        <RadioButton
                            label={f(messages.email)}
                            name={f(messages.channeltitle)}
                            value={STATEMENT_CHANNELS.EMAIL}
                            checked={statementChannel === STATEMENT_CHANNELS.EMAIL}
                            onChange={() => setStatementChannel(STATEMENT_CHANNELS.EMAIL)}
                        />
                        <RadioButton
                            label={f(messages.paper)}
                            name={f(messages.channeltitle)}
                            value={STATEMENT_CHANNELS.PAPER}
                            checked={statementChannel === STATEMENT_CHANNELS.PAPER}
                            onChange={() => setStatementChannel(STATEMENT_CHANNELS.PAPER)}
                        />
                    </RadioButtonGroup>
                    {
                        //* Note: ORFIN-65 Tämä osa modaalista on visuaalisesti ok. Laitetaan näkyviin kun API-endpoint valmistuu.
                        /*<SmallText bold>{f(messages.duedatetitle)}</SmallText>
                    <RadioButtonGroup>
                        <RadioButton label={f(messages.first)} name="erapaiva" value="" />
                        <RadioButton label={f(messages.second)} name="erapaiva" value="" />
                        <RadioButton label={f(messages.third)} name="erapaiva" value="" />
                    </RadioButtonGroup>
                    <StyledParagraph marginTop="8px">{f(messages.info)}</StyledParagraph>*/
                    }
                </Grid2>
                <Grid2>
                    <SmallText bold>{f(messages.einvoicetitle)}</SmallText>
                    <ListWithMargin>
                        <ItemList
                            items={[
                                f(messages.listitem1),
                                f(messages.listitem2),
                                f(messages.listitem3)
                            ]}
                            label={f(messages.listlabel)}
                        />
                    </ListWithMargin>
                </Grid2>
            </LoanSettingsContainer>
            <CenteredButtonContainer marginTop="32px">
                <CommonButton onClick={() => handleSave()}>{f(messages.button)}</CommonButton>
                {error && <ErrorMessage>{f(messages.errormsg)}</ErrorMessage>}
            </CenteredButtonContainer>
        </LoanModalContainer>
    );
};
