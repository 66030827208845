import { createSelector } from "reselect";
import { getToken } from "./token";

const token = (state) => getToken(state);

export const getActiveAccountInfo = (state) => state.accounts.activeAccountInfo;

export const getRemainingTenor = () =>
    createSelector([getActiveAccountInfo], (activeAccountInfo) => activeAccountInfo.remainingTenor);

export const getStatementChannel = () =>
    createSelector(
        [getActiveAccountInfo],
        (activeAccountInfo) => activeAccountInfo.statementChannel
    );

export const getPromissoryNoteDocumentId = () =>
    createSelector(
        [getActiveAccountInfo],
        (activeAccountInfo) => activeAccountInfo.promissoryNoteDocumentId
    );

export const getPromissoryNoteDocumentIdWithToken = () =>
    createSelector([getActiveAccountInfo, token], ({ promissoryNoteDocumentId }, token) => {
        return { promissoryNoteDocumentId, token };
    });

export const getRemainingPrincipal = () =>
    createSelector(
        [getActiveAccountInfo],
        (activeAccountInfo) => activeAccountInfo.remainingPrincipal
    );
