import { takeEvery, call, select, put } from "redux-saga/effects";
import { successUpdateUserInfo, errorUpdateUserInfo, fetchCustomerTrigger } from "../actions/user";
import { updateUserInfo } from "../util/api";
import { selectToken } from "../selectors/token";
import { USER_UPDATE_INFO_TRIGGER } from "../constants/user";

export function* handleSaveUserInfo(action) {
    const token = yield select(selectToken);
    const { customerBasicInfo } = action.payload;

    const updateResult = yield call(updateUserInfo, { customerBasicInfo, token });

    if (updateResult) {
        yield put(successUpdateUserInfo());
        yield put(fetchCustomerTrigger({ initial: false }));
    } else {
        yield put(errorUpdateUserInfo());
    }
}

export function* watchSaveUserInfo() {
    yield takeEvery(USER_UPDATE_INFO_TRIGGER, handleSaveUserInfo);
}
