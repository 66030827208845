import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { format, parseISO } from "date-fns";
import { useHistory } from "react-router-dom";
// import { goToTop } from "react-scrollable-anchor";
import styled from "styled-components/macro";

import { BoxContainer } from "../../components/BoxContainer";
import { MainContent } from "../../components/MainContent";
import { FlexColumn, Grid1, Grid3, GridContainer, GridContent } from "../../components/Grid";
import { News } from "../../components/News/News";
import { PageTitleBox } from "../../components/PageTitleBox";
import { frontPageTrigger } from "../../actions/page";
import { LoanInfo } from "../../components/LoanInfo/LoanInfo";
import { Installments } from "../../components/Installments";
import { SmallTitle } from "../../components/Fonts/SmallTitle";
import { BasicText } from "../../components/Fonts/BasicText";
import { Icon } from "../../components/Icon";
import { breakpoints } from "../../constants/styles";
import { PayNow } from "../../components/PayNow";
import { DEFAULT_FORMAT_DATE } from "../../constants/dateTime";
import { Loader } from "../../components/Loader";
import { getNewsSelector } from "../../selectors/contentful";
import { getActiveAccountInfo } from "../../selectors/activeAccountInfo";
import { ActionTitle } from "../../components/ActionTitle";
import { getActiveAccount } from "../../selectors/activeAccount";
import { getCustomerBasicInfo, getCustomer } from "../../selectors/customer";
import { PageTitleWithRightContent } from "../../components/PageTitleWithRightContent";

import { messages } from "./messages";

const IconContainer = styled.div`
    display: flex;
    cursor: pointer;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    @media ${breakpoints.mobile} {
        padding: 12px 0 16px 0;
    }
`;

const IconText = styled.div`
    display: flex;
    margin: 20px 0 0 0;
    padding: 0;
`;

export function FrontPage() {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(frontPageTrigger({ langId: "fi", portal: "Ostosraha Online" }));
        // goToTop();
    }, [dispatch]);

    const activeAccount = useSelector(getActiveAccount);
    const customer = useSelector(getCustomer);
    const customerBasicInfo = useSelector(getCustomerBasicInfo) || {};

    const news = useSelector(getNewsSelector);
    const { createDate } = useSelector(getActiveAccountInfo);

    const { formatMessage: f } = useIntl();

    if (!activeAccount || !customer || !customerBasicInfo || !createDate) {
        return <Loader />;
    }

    const formattedCreatedDate = format(parseISO(createDate), DEFAULT_FORMAT_DATE);

    return (
        <FlexColumn>
            <PageTitleBox id="topOfPage">
                <PageTitleWithRightContent
                    title={`${f(messages.pagetitle)} ${customerBasicInfo.givenName}!`}>
                    <BasicText>{`${f(messages.customernumber)} ${
                        customerBasicInfo.customerId
                    }`}</BasicText>
                </PageTitleWithRightContent>
            </PageTitleBox>
            <GridContainer>
                <Grid3>
                    <GridContent>
                        <BoxContainer>
                            <MainContent>
                                <ActionTitle
                                    title={`${f(messages.loaninfotitle)} ${formattedCreatedDate}`}
                                />
                                <LoanInfo />
                                <Installments />
                            </MainContent>
                        </BoxContainer>
                    </GridContent>
                </Grid3>
                <Grid1>
                    <GridContent>
                        <BoxContainer>
                            <News items={news} />
                        </BoxContainer>
                    </GridContent>
                </Grid1>
            </GridContainer>
            <GridContainer>
                <Grid3>
                    <GridContent>
                        <BoxContainer>
                            <MainContent>
                                <PayNow />
                            </MainContent>
                        </BoxContainer>
                    </GridContent>
                </Grid3>
                <Grid1>
                    <GridContent>
                        <BoxContainer>
                            <IconContainer
                                onClick={() => {
                                    history.push("/loan");
                                }}>
                                <Icon icon={["far", "money-bill-alt"]} size="4x" />
                                <IconText>
                                    <SmallTitle>{f(messages.loanlinktext)}</SmallTitle>
                                </IconText>
                            </IconContainer>
                        </BoxContainer>
                    </GridContent>
                    <GridContent>
                        <BoxContainer>
                            <IconContainer
                                onClick={() => {
                                    history.push("/change");
                                }}>
                                <Icon icon={["fa", "sync-alt"]} size="4x" />
                                <IconText>
                                    <SmallTitle>{f(messages.changelinktext)}</SmallTitle>
                                </IconText>
                            </IconContainer>
                        </BoxContainer>
                    </GridContent>
                </Grid1>
            </GridContainer>
        </FlexColumn>
    );
}
