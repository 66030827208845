import { takeEvery, put, call, select } from "redux-saga/effects";
import { fetchRemainingDebt } from "../util/api";
import { FETCH_REMAINING_DEBT_TRIGGER } from "../constants/remainingDebts";
import { fetchRemainingDebtSuccess } from "../actions/remainingDebt";
import { format } from "date-fns";

export function* watchFetchRemainingDebt() {
    yield takeEvery(FETCH_REMAINING_DEBT_TRIGGER, handleFetchRemainingDebt);
}

export function* handleFetchRemainingDebt(action) {
    const { token, accountId } = yield select((state) => {
        return {
            token: state.user.token,
            accountId: state.accounts.activeAccount.id
        };
    });

    const date = format(action.payload ? action.payload : new Date(), "yyyy-MM-dd");

    console.log('got date to fetch', date);

    const result = yield call(fetchRemainingDebt, {
        token,
        accountId,
        date,
    });

    console.log('got remaining debt result', result);

    yield put(fetchRemainingDebtSuccess(result));
}
