import styled from "styled-components/macro";
import { breakpoints } from "../constants/styles";

export const GridContainer = styled.div`
    display: flex;
    max-width: 976px;
    width: 100%;
    @media ${breakpoints.mobile} {
        flex-direction: column;
    }
`;

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    @media ${breakpoints.mobile} {
        flex-direction: column;
    }
    @media ${breakpoints.tablet} {
        flex-direction: column;
    }
`;

export const Grid1 = styled.div`
    display: flex;
    width: 25%;
    height: 100%;
    flex-direction: ${(props) => (props.row ? `row` : `column`)};
    @media ${breakpoints.mobile} {
        width: 100%;
        margin: 0;
        display: ${(props) => (props.hideOnMobile ? "none" : "flex")};
    }
`;

export const Grid2 = styled.div`
    display: flex;
    width: 50%;
    height: 100%;
    flex-direction: ${(props) => (props.row ? `row` : `column`)};
    @media ${breakpoints.mobile} {
        width: 100%;
        margin: 0;
        display: ${(props) => (props.hideOnMobile ? "none" : "flex")};
    }
`;

export const Grid3 = styled.div`
    display: flex;
    width: 75%;
    height: 100%;
    flex-direction: ${(props) => (props.row ? `row` : `column`)};
    @media ${breakpoints.mobile} {
        width: 100%;
        margin: 0;
        display: ${(props) => (props.hideOnMobile ? "none" : "flex")};
    }
`;

export const Grid4 = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: ${(props) => (props.row ? `row` : `column`)};
    @media ${breakpoints.mobile} {
        width: 100%;
        margin: 0;
        display: ${(props) => (props.hideOnMobile ? "none" : "flex")};
    }
`;

export const GridContent = styled.div`
    padding: 8px;
    height: 100%;
    width: 100%;
    @media ${breakpoints.mobile} {
        height: auto;
    }
`;
