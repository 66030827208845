import React from "react";
import styled from "styled-components/macro";
import Markdown from "react-markdown";
import { colors } from "../../constants/styles";

const StyledDate = styled.p`
    color: ${colors.gray};
    font-size: 14px;
    line-height: 1.71;
    margin: 0 0 16px 0;
`;

const StyledParagraph = styled.p`
    margin: 0;
    font-size: 14px;
    line-height: 1.71;
    color: ${colors.gray};
`;

export const NewsModalContent = ({ createdDate, content }) => {
    return (
        <>
            <StyledDate>{createdDate}</StyledDate>
            <StyledParagraph>
            <Markdown>{content}</Markdown>
            </StyledParagraph>
        </>
    );
};
