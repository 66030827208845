import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DEFAULT_FORMAT_DATE } from "../../constants/dateTime";
import fi from "date-fns/locale/fi";
import "./index.css";

registerLocale("fi", fi);

export function DateSelector(props) {
    let { startDate, onChange, onFilterDate, ...rest } = props;

    if (!onFilterDate) {
        onFilterDate = (date) => {
            return new Date() < date;
        };
    }

    return (
        <DatePicker
            selected={startDate}
            onChange={onChange}
            dateFormat={DEFAULT_FORMAT_DATE}
            filterDate={onFilterDate}
            locale="fi"
            {...rest}
        />
    );
}
