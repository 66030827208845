import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import * as R from "ramda";
import { format, parseISO } from "date-fns";
import { GenericTable } from "../GenericTable";
import { StatementLink } from "./StatementLink";
import { Currency, getCurrency } from "../Currency";
import { messages } from "./messages";
import { DEFAULT_FORMAT_DATE } from "../../constants/dateTime";
import { CELL_TYPES } from "./constants";

export const InvoicesTable = ({
    tableData,
    token,
    noButton,
    pdfData,
    pdfParams,
    pdfDispatch,
    loading,
}) => {
    const { formatMessage: f } = useIntl();
    const columns = useMemo(
        () => [
            {
                Header: f(messages.number),
                accessor: "seriesNum",
                Cell: ({ cell }) =>
                    cell.row.original.documentId ? (
                        <StatementLink documentId={cell.row.original.documentId} token={token}>
                            {cell.value}
                        </StatementLink>
                    ) : (
                        cell.value
                    ),
                Footer: f(messages.sum),
                type: CELL_TYPES.TEXT,
                exportToPdf: true,
            },
            {
                Header: f(messages.title),
                accessor: "title",
                Cell: ({ cell }) =>
                    cell.row.original.documentId ? (
                        <StatementLink documentId={cell.row.original.documentId} token={token}>
                            {`${f(messages.statementtitle)} ${cell.value}`}
                        </StatementLink>
                    ) : (
                        `${f(messages.statementtitle)} ${cell.value}`
                    ),
                type: CELL_TYPES.TEXT,
                exportToPdf: false,
            },
            {
                Header: f(messages.status),
                accessor: "statementStatus",
                Cell: ({ cell }) => f(messages[cell.value]),
                type: CELL_TYPES.TEXT,
                exportToPdf: false,
            },
            {
                Header: f(messages.duedate),
                accessor: "dueDate",
                type: CELL_TYPES.DATE,
                exportToPdf: true,
            },
            {
                Header: f(messages.amortization),
                accessor: "amortization",
                Cell: ({ cell }) => <Currency>{cell.value}</Currency>,
                Footer: (data) => {
                    const totalAmortization = useMemo(
                        () => data.rows.reduce((sum, row) => row.values.amortization + sum, 0),
                        [data.rows]
                    );
                    return <Currency>{totalAmortization}</Currency>;
                },
                type: CELL_TYPES.CURRENCY,
                exportToPdf: true,
            },
            {
                Header: f(messages.interest),
                accessor: "interest",
                Cell: ({ cell }) => <Currency>{cell.value}</Currency>,
                Footer: (data) => {
                    const totalAmortization = useMemo(
                        () => data.rows.reduce((sum, row) => row.values.interest + sum, 0),
                        [data.rows]
                    );
                    return <Currency>{totalAmortization}</Currency>;
                },
                type: CELL_TYPES.CURRENCY,
                exportToPdf: true,
            },
            {
                Header: f(messages.fee),
                accessor: "fee",
                Cell: ({ cell }) => <Currency>{cell.value}</Currency>,
                Footer: (data) => {
                    const totalAmortization = useMemo(
                        () => data.rows.reduce((sum, row) => row.values.fee + sum, 0),
                        [data.rows]
                    );
                    return <Currency>{totalAmortization}</Currency>;
                },
                type: CELL_TYPES.CURRENCY,
                exportToPdf: true,
            },
            {
                Header: f(messages.total),
                accessor: "totalAmount",
                Cell: ({ cell }) => <Currency>{cell.value}</Currency>,
                Footer: (data) => {
                    const totalAmortization = useMemo(
                        () => data.rows.reduce((sum, row) => row.values.totalAmount + sum, 0),
                        [data.rows]
                    );
                    return <Currency>{totalAmortization}</Currency>;
                },
                type: CELL_TYPES.CURRENCY,
                exportToPdf: true,
            },
        ],
        [f, token]
    );

    const pdfHeaders = columns
        .filter((item) => item.exportToPdf)
        .map((column) => ({
            accessor: column.accessor,
            translationKey: column.accessor,
            type: column.type,
        }));

    let dataPdf = null;

    const modifyData = (tableRow) => {
        const omitProps = columns
            .filter((item) => !item.exportToPdf)
            .map((column) => column.accessor);

        let currentRow = R.omit(omitProps, tableRow);
        pdfHeaders.forEach((header) => {
            const { accessor, type } = header;
            if (type === CELL_TYPES.DATE) {
                const newValue = format(parseISO(currentRow[accessor]), DEFAULT_FORMAT_DATE);
                currentRow = {
                    ...currentRow,
                    [accessor]: newValue,
                };
            }
            if (type === CELL_TYPES.CURRENCY) {
                const newValue = getCurrency(currentRow[accessor]);
                currentRow = {
                    ...currentRow,
                    [accessor]: newValue,
                };
            }
        });

        return currentRow;
    };

    if (pdfData) {
        const tableData = R.map(modifyData, pdfData.table.tableData);

        dataPdf = {
            ...pdfData,
            table: {
                headers: pdfHeaders,
                tableData,
            },
        };
    }

    return (
        <GenericTable
            columns={columns}
            data={tableData}
            noButton={noButton}
            pdfData={dataPdf}
            pdfParams={pdfParams}
            pdfDispatch={pdfDispatch}
            loading={loading}
        />
    );
};
