import styled from "styled-components";

export const Icon = styled.svg`
    fill: none;
    stroke: #5c9c4b;
    stroke-width: 4px;
`;
export const CheckboxContainer = styled.div`
    display: inline-block;
    vertical-align: middle;
    margin-right: 16px;
    cursor: pointer;
`;
export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;
export const StyledCheckbox = styled.div`
    display: inline-block;
    width: 20px;
    height: 20px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.1), inset 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    border: solid 2px #979797;
    transition: all 150ms;

    ${HiddenCheckbox}:focus + & {
        box-shadow: 0 0 0 3px pink;
    }

    ${Icon} {
        visibility: ${(props) => (props.checked ? "visible" : "hidden")};
    }
`;
