import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { accountsReducer } from "./accounts";
import { accountStatementsReducer } from "./statements";
import { specialPaymentAgreementsReducer } from "./specialPaymentAgreements";
import { userReducer } from "./user";
import { contentfulReducer } from "./contentful";
import { uiReducer } from "./ui";
import { paymentPlansReducer } from "./paymentPlans";
import { remainingDebtReducer } from "./remainingDebt";
import { feedbackReducer } from "./feedback";
import { fetchPdfReducer } from "./fetchPdf";

export const createRootReducer = (h) =>
    combineReducers({
        router: connectRouter(h),
        accounts: accountsReducer,
        statements: combineReducers({
            statements: accountStatementsReducer,
            paymentAgreements: specialPaymentAgreementsReducer
        }),
        user: userReducer,
        contentful: contentfulReducer,
        ui: uiReducer,
        paymentPlans: paymentPlansReducer,
        remainingDebt: remainingDebtReducer,
        notifications: combineReducers({
            feedback: feedbackReducer,
            fetchPdf: fetchPdfReducer
        })
    });
