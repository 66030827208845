import {
    CONTACT_PAGE_TRIGGER,
    FRONT_PAGE_TRIGGER,
    LOAN_PAGE_TRIGGER,
    START_PAGE_TRIGGER,
    USER_PAGE_TRIGGER
} from "../constants/page";

export function contactPageTrigger() {
    return {
        type: CONTACT_PAGE_TRIGGER
    };
}

export function frontPageTrigger(data) {
    return {
        type: FRONT_PAGE_TRIGGER,
        payload: data
    };
}

export function loanPageTrigger() {
    return {
        type: LOAN_PAGE_TRIGGER
    };
}

export function startPageTrigger() {
    return {
        type: START_PAGE_TRIGGER
    };
}

export function userPageTrigger() {
    return {
        type: USER_PAGE_TRIGGER
    };
}
