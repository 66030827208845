import styled from "styled-components/macro";
import { colors } from "../../constants/styles";

export const BasicText = styled.span`
    line-height: 1.5;
    color: ${colors.gray};
    font-weight: ${(props) => (props.bold ? "bold" : "normal")};
`;

export const BasicTextP = styled.p`
    line-height: 1.5;
    color: ${colors.gray};
    font-weight: ${(props) => (props.bold ? "bold" : "normal")};
    margin: 4px, 0;
    padding: 0;
`;
