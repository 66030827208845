import { takeEvery, put } from "@redux-saga/core/effects";
import { LOAN_PAGE_TRIGGER } from "../constants/page";
import {
    fetchAccountStatementsTrigger,
} from "../actions/account";
import { fetchPaymentPlansTrigger } from "../actions/paymentPlans";

export function* watchLoanPageLoad() {
    yield takeEvery(LOAN_PAGE_TRIGGER, handleLoanPageLoad);
}

export function* handleLoanPageLoad() {
    yield put(fetchAccountStatementsTrigger());
    yield put(fetchPaymentPlansTrigger());
}
