import styled from "styled-components";
import { colors, fontSizes } from "../../constants/styles";

export const StyledInput = styled.input`
    width: 100%;
    border-radius: 8px;
    height: 30px;
    border: 1px solid ${colors.inputBorder};
    font-size: ${fontSizes.normal};
    color: ${colors.gray};
    padding: 0 8px;
`;

export const StyledError = styled.span`
    font-size: ${fontSizes.small};
    color: ${colors.orange};
`;
