import React from "react";
import { defineMessages } from "react-intl";
import { Link } from "./styled";

export const messages = defineMessages({
    pagetitle: {
        id: "page.noloan.pagetitle"
    },
    smalltitle: {
        id: "page.noloan.smalltitle"
    },
    listinfo: {
        id: "page.noloan.listinfo"
    },
    listitem1: {
        id: "page.noloan.listitem1"
    },
    listitem2: {
        id: "page.noloan.listitem2"
    },
    info: {
        id: "page.noloan.info"
    },
    link: {
        id: "page.noloan.link",
        values: {
            linkto: (value) => <Link href={`https://${value}`}>{value}</Link>
        }
    }
});
