import styled from "styled-components/macro";
import { colors } from "../../constants/styles";
import { SmallText } from "../../components/Fonts/SmallText";

export const ChangePageMargins = styled.span`
    display: block;
    margin: 0px 0px 24px 0px;
`;

export const CalendarTitle = styled.div`
    display: flex;
    margin: 24px auto 0 auto;
    width: 322px;
`;

export const ChangePageMargin = styled.span`
    margin-right: 24px;
`;

export const CalendarContainer = styled.div`
    margin-top: 6px;
    display: flex;
    justify-content: center;
`;

export const Calendar = styled.div`
    border-radius: 12px;
    border: solid 1px ${colors.borderDarkGray};
    background-color: ${colors.white};
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 322px; /* +2 for borders */
`;

export const CalenderItem = styled.div`
    width: 80px;
    height: 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    justify-content: center;
    padding: 8px;
    ${(props) => props.nextYear && `background-color: ${colors.borderGray};`};
    &:hover {
        cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
        background-color: ${(props) =>
            !props.disabled ? colors.paleGreen : props.nextYear ? colors.borderGray : colors.white};
    }
    &:active {
        background-color: ${(props) =>
            props.disabled ? (props.nextYear ? colors.borderGray : colors.white) : `#e6f0e4;`};
        ${(props) => !props.disabled && `box-shadow: 0 2px 2px 0 ${colors.darkGreen};`};
    }
`;

export const CalendarText = styled(SmallText)`
    line-height: normal;
`;
