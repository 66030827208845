import { defineMessages } from "react-intl";

export const messages = defineMessages({
    title: {
        id: "news.title"
    },
    more: {
        id: "news.more"
    }
});
