import styled from "styled-components/macro";
import { colors } from "../../constants/styles";
import { breakpoints } from "../../constants/styles";

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {
        margin: 12px 0;
    }
`;

export const LoanContent = styled.div`
    margin: 0 52px;
    text-align: center;
`;

export const NoteContainer = styled.div`
    margin-top: 22px;
    margin-bottom: 28px;
`;

export const InstallmentsButtonsContainer = styled(ButtonsContainer)`
    margin: 16px 0;
    > :first-child {
        margin-bottom: 24px;
    }
`;

export const InstallmentsButtonContainer = styled.div`
    width: 200px;
    height: 136px;
`;

export const TableContainer = styled.div`
    @media ${breakpoints.mobile} {
        display: none;
    }
`;

export const MobileTableContainer = styled.div`
    display: none;
    @media ${breakpoints.mobile} {
        display: block;
    }
`;

export const SettingsButtonText = styled.span`
    display: inline;
    color: ${colors.white};
    margin-left: 8px;
    @media ${breakpoints.mobile} {
        display: none;
    }
`;
