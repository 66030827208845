import React from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import { GridContainer, FlexColumn, GridContent } from "../../components/Grid";
import { BoxContainer } from "../../components/BoxContainer";
import { MainContent } from "../../components/MainContent";
import { LargeTitle } from "../../components/Fonts/LargeTitle";
import { PageTitleBox } from "../../components/PageTitleBox";
import { SmallTitle } from "../../components/Fonts/SmallTitle";
import { messages } from "./messages";

const Space = styled.span`
    display: block;
    height: 270px;
`;
export function ErrorPage() {
    const location = useLocation();
    const { formatMessage: f } = useIntl();
    const text =
        location.pathname === "/error" ? f(messages.genericerror) : f(messages.pagenotfound);

    return (
        <FlexColumn>
            <PageTitleBox>
                <LargeTitle>{text}</LargeTitle>
            </PageTitleBox>
            <GridContainer>
                <GridContent>
                    <Space>
                        <BoxContainer>
                            <MainContent>
                                <SmallTitle>
                                    {f(messages.errortext, messages.errortext.values)}
                                </SmallTitle>
                            </MainContent>
                        </BoxContainer>
                    </Space>
                </GridContent>
            </GridContainer>
        </FlexColumn>
    );
}
