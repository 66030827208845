import React from "react";
import { defineMessages } from "react-intl";

export const messages = defineMessages({
    pagetitle: {
        id: "page.contact.pagetitle"
    },
    customernumber: {
        id: "customer.number.short"
    },
    customerservice: {
        id: "page.contact.subtitle.customerservice"
    },
    phonetitle: {
        id: "page.contact.phonetitle"
    },
    phonetime: {
        id: "page.contact.customerservice.phonetime"
    },
    phone: {
        id: "companyinfo.phonenumber"
    },
    emailtitle: {
        id: "page.contact.emailtitle"
    },
    emailaddress: {
        id: "page.contact.customerservice.emailaddress",
        values: {
            mailto: (value) => <a href={`mailto: ${value}`}>{value}</a>
        }
    },
    addresstitle: {
        id: "page.contact.customerservice.addresstitle"
    },
    companyname: {
        id: "page.contact.customerservice.companyname"
    },
    companyaddress: {
        id: "companyinfo.address"
    },
    companypost: {
        id: "companyinfo.post"
    },
    debtcollection: {
        id: "page.contact.subtitle.debtcollection"
    },
    debtcollectionphonetime: {
        id: "page.contact.debtcollection.phonetime"
    },
    debtcollectionphone: {
        id: "page.contact.debtcollection.phone"
    },
    debtcollectionemailaddress: {
        id: "page.contact.debtcollection.emailAddress",
        values: {
            mailto: (value) => <a href={`mailto: ${value}`}>{value}</a>
        }
    },
    billing: {
        id: "page.contact.subtitle.billing"
    },
    billinphonetime: {
        id: "page.contact.billing.phonetime"
    },
    billingphone: {
        id: "page.contact.billing.phone"
    }
});
