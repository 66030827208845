import {
    FETCH_REMAINING_DEBT_SUCCESS,
    FETCH_REMAINING_DEBT_TRIGGER
} from "../constants/remainingDebts";

export function fetchRemainingDebtTrigger(payload) {
    return {
        type: FETCH_REMAINING_DEBT_TRIGGER,
        payload,
    };
}

export function fetchRemainingDebtSuccess(payload) {
    return {
        type: FETCH_REMAINING_DEBT_SUCCESS,
        payload
    };
}
