import React from "react";
import styled from "styled-components/macro";

export const LoanCalculatorContainer = styled.div`
    display: flex;
    width: 100%;
    height: 16px;
    margin: 0;
    padding: 0;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #d8d8d8;
    border-radius: 8px;
`;

export const LoanCalculatorContent = styled.div`
    height: 16px;
    width: ${(props) => props.percentage}%;
    margin: 0;
    padding: 0;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #6bb657;
    border-radius: 8px;
`;

export function LoanCalculator(props) {
    const { percentage } = props;

    return (
        <LoanCalculatorContainer>
            <LoanCalculatorContent percentage={percentage} />
        </LoanCalculatorContainer>
    );
}
