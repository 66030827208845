import React from "react";
import styled from "styled-components/macro";

export const StyledRaisedButton = styled.button`
    border-radius: 4px;
    border: 0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
    cursor: pointer;
    padding: 24px;
    width: 100%;
    height: 100%;
    &:hover {
        box-shadow: 0 2px 2px 0 #86c463;
    }
    &:active {
        box-shadow: 0 2px 2px 0 #86c463;
        background-color: #f5fff2;
        border: 0;
    }
`;

export function RaisedButton(props) {
    const { onClick, children } = props;

    return <StyledRaisedButton onClick={onClick}>{children}</StyledRaisedButton>;
}
