import styled from "styled-components/macro";
import { breakpoints } from "../constants/styles";

export const NavigationContainer = styled.div`
    display: flex;
    width: 90%;
    max-width: 960px;
    @media ${breakpoints.mobile} {
        width: 100%;
    }
`;
