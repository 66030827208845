import React from "react";
import { RaisedButton } from "./RaisedButton";
import { BoxContent } from "./BoxContent";

export const InstallmentsButton = ({ onClick, children }) => {
    return (
        <RaisedButton onClick={onClick}>
            <BoxContent center>{children}</BoxContent>
        </RaisedButton>
    );
};
