import styled from "styled-components/macro";
import { fontSizes, colors, breakpoints } from "../../constants/styles";

export const StyledButton = styled.button`
    z-index: 1200;
    position: fixed;
    top: 216px;
    right: 0;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.85);
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    padding: 16px 8px;
    @media ${breakpoints.mobile} {
        display: none;
    }
`;

export const TextButton = styled.p`
    padding: 0px;
    margin: 0px;
    margin-top: 8px;
    font-size: ${fontSizes.normal};
    color: ${colors.gray};
`;
