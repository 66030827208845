import React from "react";
import { Icon } from "../Icon";
import { StyledButton, TextButton } from "./FeedbackButton.styled";
import { colors } from "../../constants/styles";
import { messages } from "./messages";
import { useIntl } from "react-intl";

export const FeedbackButton = ({ onClick }) => {
    const { formatMessage: f } = useIntl();
    return (
        <StyledButton onClick={onClick}>
            <Icon icon={["fa", "thumbs-up"]} size="2x" color={colors.gray} />
            <TextButton>
                {f(messages.cornerbutton1)}
                <br /> {f(messages.cornerbutton2)}
            </TextButton>
        </StyledButton>
    );
};
