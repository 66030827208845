import * as UI_ACTIONS from "../constants/ui";

export function isUiLoading(flag) {
    return {
        type: UI_ACTIONS.UI_IS_LOADING,
        payload: flag
    };
}

export function reset() {
    return {
        type: UI_ACTIONS.UI_RESET
    };
}

export function translationsTrigger(payload) {
    return {
        type: UI_ACTIONS.UI_TRANSLATIONS_TRIGGER,
        payload
    };
}

export function translationsSuccess(payload) {
    return {
        type: UI_ACTIONS.UI_TRANSLATIONS_SUCCESS,
        payload
    };
}

export function translationsError(error) {
    return {
        type: UI_ACTIONS.UI_TRANSLATIONS_ERROR,
        payload: error
    };
}
