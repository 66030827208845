import { call, put, takeEvery } from "redux-saga/effects";
import * as R from "ramda";
import { UI_TRANSLATIONS_TRIGGER } from "../constants/ui";
import { getTranslations } from "../util/contentful";
import { translationsSuccess, translationsError } from "../actions/ui";

export function* handleGetTranslations(action) {
    try {
        const data = yield call(getTranslations, action.payload);

        if (R.isEmpty(data)) {
            yield put(translationsError("Couldn't fetch data"));
        } else {
            yield put(translationsSuccess(data));
        }
    } catch (error) {
        console.log(error);
        yield put(translationsError(error));
    }
}

export function* watchGetTranslations() {
    yield takeEvery(UI_TRANSLATIONS_TRIGGER, handleGetTranslations);
}
