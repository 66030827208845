import styled from "styled-components";
import { fontSizes } from "../../constants/styles";

export const StyledParagraph = styled.p`
    margin: 0;
    margin-top: ${(props) => (props.marginTop ? props.marginTop : `0`)};
    font-size: ${fontSizes.small};
    line-height: 1.71;
`;

export const StyledList = styled.ul`
    margin: 0;
    padding: 0;
    margin-top: 8px;
    list-style: none;
    text-indent: -12px;
    margin-left: 12px;
`;

export const StyledListItem = styled.li`
    font-size: ${fontSizes.small};
    line-height: 1.71;
`;

export const ListItemIconContainer = styled.div`
    display: inline;
    font-size: 12px;
`;
