import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { login } from "../actions/user";
import { Loader } from "../components/Loader";

export function LoginPage(props) {
    console.log("login page props", props);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(login(props.history));
    }, [dispatch, props.history]);

    return <Loader />;
}
