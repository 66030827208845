import { defineMessages } from "react-intl";

export const messages = defineMessages({
    show6: {
        id: "generictable.show6"
    },
    show12: {
        id: "generictable.show12"
    },
    showall: {
        id: "generictable.showall"
    },
    openPdf: {
        id: "generictable.openpdf"
    }
});
