import { createGlobalStyle } from "styled-components";

import { fonts, fontSizes, colors } from "./constants/styles";

export const GlobalStyle = createGlobalStyle`
    * {
        font-family: ${fonts.basic};
    }

    div, span, p, h1, h2, h3, h4, h5, h6, ul, li {
        color: ${colors.gray};
    }

    a {
        color: ${colors.linkBlue};
    }
    
    h1 {
        margin: 0;
        padding: 0;
        font-size: ${fontSizes.largeTitle};
        font-weight: bold;
    }
    
    h2 {
        margin: 0;
        padding: 0;
        font-family: ${fonts.basic};
        font-size: ${fontSizes.largeTitle};
        font-weight: bold;
    }

    span {
        font-size: ${fontSizes.normal};
    }

    button {
        border:0;
    }
`;
