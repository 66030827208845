import React from "react";
import { FormattedMessage } from "react-intl";

import { FlexRow, Grid2 } from "../../Grid";
import { Icon } from "../../Icon";
import { ColumnTitle } from "../ColumnTitle";
import {
    FlexIconColumn,
    FlexIconRow,
    FooterLink,
    Grid2WithPadding,
    StyledFooterLink,
    StyledParagraph
} from "../styled";
import { colors } from "../../../constants/styles";

import { messages } from "./messages";

export const CompanyInfo = () => {
    return (
        <Grid2>
            <ColumnTitle>
                <FormattedMessage {...messages.title} />
            </ColumnTitle>
            <FlexRow>
                <Grid2WithPadding>
                    <FlexIconRow>
                        <FlexIconColumn>
                            <Icon icon={["far", "address-book"]} size="lg" color={colors.gray} />
                        </FlexIconColumn>
                        <div>
                            <StyledParagraph>
                                <FormattedMessage {...messages.name} />
                            </StyledParagraph>
                            <StyledParagraph>
                                <FormattedMessage {...messages.address} />
                            </StyledParagraph>
                            <StyledParagraph>
                                <FormattedMessage {...messages.post} />
                            </StyledParagraph>
                            <StyledParagraph>
                                <FormattedMessage {...messages.vatnumber} />
                            </StyledParagraph>
                        </div>
                    </FlexIconRow>
                    <FlexIconRow>
                        <FlexIconColumn>
                            <Icon
                                icon={["far", "arrow-alt-circle-right"]}
                                size="lg"
                                color={colors.gray}
                            />
                        </FlexIconColumn>
                        <div>
                            <StyledFooterLink href="/contact">
                                <FormattedMessage {...messages.contact} />
                            </StyledFooterLink>
                        </div>
                    </FlexIconRow>
                </Grid2WithPadding>
                <Grid2WithPadding>
                    <FooterLink>
                        <StyledFooterLink href="https://www.ostosraha.fi/evasteet" target="_blank">
                            <FormattedMessage {...messages.cookies} />
                        </StyledFooterLink>
                    </FooterLink>
                    <FooterLink>
                        <StyledFooterLink
                            href="https://www.ostosraha.fi/rekisteriseloste"
                            target="_blank">
                            <FormattedMessage {...messages.policy} />
                        </StyledFooterLink>
                    </FooterLink>
                </Grid2WithPadding>
            </FlexRow>
        </Grid2>
    );
};
