import { Grid4, GridContainer, GridContent } from "./Grid";
import { BoxContainer } from "./BoxContainer";
import React from "react";
import { MainContent } from "./MainContent";

export function PageTitleBox(props) {
    return (
        <GridContainer>
            <Grid4>
                <GridContent>
                    <BoxContainer>
                        <MainContent>{props.children}</MainContent>
                    </BoxContainer>
                </GridContent>
            </Grid4>
        </GridContainer>
    );
}
