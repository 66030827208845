import { defineMessages } from "react-intl";

export const messages = defineMessages({
    question: {
        id: "feedback.question"
    },
    error: {
        id: "feedback.error"
    },
    send: {
        id: "feedback.send"
    },
    thankyou: {
        id: "feedback.thankyou"
    },
    answer: {
        id: "feedback.answer"
    },
    textfield: {
        id: "feedback.textfield"
    },
    option: {
        id: "feedback.option"
    },
    cornerbutton1: {
        id: "feedback.cornerbutton1"
    },
    cornerbutton2: {
        id: "feedback.cornerbutton2"
    }
});
