import { SET_SELECTED_ACCOUNT, SET_SELECTED_ACCOUNT_INFO } from "../constants/account";
import { FETCH_ACCOUNT_SUCCESS } from "../constants/account";

export const initialState = {
    activeAccountInfo: {},
    activeAccount: {}
};

export function accountsReducer(state = initialState, action) {
    switch (action.type) {
        case SET_SELECTED_ACCOUNT: {
            return { ...state, activeAccount: action.payload.account };
        }
        case SET_SELECTED_ACCOUNT_INFO:
        case FETCH_ACCOUNT_SUCCESS: {
            return { ...state, activeAccountInfo: action.payload };
        }
        default:
            return state;
    }
}
