import { takeEvery, call } from "redux-saga/effects";
import { getToken } from "../util/api";
import { USER_LOGIN } from "../constants/user";

export function* watchUserLogin() {
    yield takeEvery(USER_LOGIN, handleUserLogin);
}

export function* handleUserLogin(action) {
    try {
        const query = new URLSearchParams(window.location.search);

        if (query.has("accessToken")) {
            const accessToken = query.get("accessToken");

            localStorage.setItem("token", accessToken);

            return (window.location = "/front");
        }

        const authorizationCode = query.get("authorizationCode");

        const data = yield call(getToken, {
            authorizationCode,
        });

        if (!data) {
            return (window.location = "/no-loan");
        }

        const { accessToken } = data;

        console.log("access token", accessToken);

        localStorage.setItem("token", accessToken);

        window.location = "/front";

        console.log("fetched customer, go to front page");
    } catch (e) {
        console.log("login failed!", e);
    }
}
