import {
    FETCH_PDF_ERROR,
    FETCH_PDF_RESET,
    FETCH_PDF_SUCCESS,
    FETCH_PDF_TRIGGER
} from "../constants/fetchPdf";

const initialState = {
    loading: false,
    error: false
};

export function fetchPdfReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_PDF_TRIGGER: {
            return {
                ...state,
                loading: true
            };
        }
        case FETCH_PDF_SUCCESS: {
            return {
                ...state,
                loading: false
            };
        }
        case FETCH_PDF_ERROR: {
            return {
                ...state,
                error: true
            };
        }
        case FETCH_PDF_RESET: {
            return {
                ...initialState
            };
        }
        default: {
            return state;
        }
    }
}
