import React from "react";
import { defineMessages } from "react-intl";

export const messages = defineMessages({
    pagetitle: {
        id: "page.change.pagetitle"
    },
    customernumber: {
        id: "customer.number.short"
    },
    subtitle: {
        id: "page.change.subtitle"
    },
    info: {
        id: "page.change.info"
    },
    customerservicecontact: {
        id: "page.change.customerservicecontact"
    },
    phonenumber: {
        id: "page.change.phonenumber",
        values: {
            strong: (value) => <strong>{value}</strong>
        }
    },
    email: {
        id: "page.change.email",
        values: {
            strong: (value) => <strong>{value}</strong>,
            mailto: (value) => <a href={`mailto: ${value}`}>{value}</a>
        }
    },
    paymentfreetitle: {
        id: "page.change.paymentfree.title"
    },
    addmonthbutton: {
        id: "page.change.addmonth.button"
    },
    cancelbutton: {
        id: "page.change.cancel.button"
    },
    savebutton: {
        id: "page.change.save.button"
    },
    infonomonths: {
        id: "page.change.no.selected.months"
    },
    infomonthsadded: {
        id: "page.change.selected.months"
    },
    infoingress: {
        id: "page.change.infotext",
        values: {
            p: (value) => <p>{value}</p>
        }
    },
    calendartitle: {
        id: "page.change.calendar.title"
    },
    errormessage: {
        id: "page.change.save.errormessage"
    }
});
