import React from "react";
import { useIntl } from "react-intl";
import { BasicText } from "../../components/Fonts/BasicText";
import { ChangePageMargins } from "./styled";
import { messages } from "./messages";

export const IngressText = (props) => {
    const { formatMessage: f } = useIntl();
    let basicText = f(messages.infonomonths);
    let months = "";

    if (props.paymentFreeMonths.length > 0) {
        props.paymentFreeMonths.forEach((month) => {
            if (!months) {
                months = `${month.monthWideName} ${month.year}`;
            } else {
                months = `${months}, ${month.monthWideName} ${month.year}`;
            }
        });
        if (months) {
            basicText = `${f(messages.infomonthsadded)} ${months}.`;
        }
    }
    return (
        <>
            <ChangePageMargins>
                <BasicText bold>{basicText}</BasicText>
            </ChangePageMargins>
            <BasicText>{f(messages.infoingress, messages.infoingress.values)}</BasicText>
        </>
    );
};
