import styled from "styled-components/macro";

export const List = styled.ul`
    list-style-type: none;
    padding-inline-start: 18px;
    li::before {
        content: "•";
        color: #5c9c4b;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
    }
`;

export const Link = styled.a`
    display: block;
    color: #196da8;
`;

export const Space = styled.div`
    margin-bottom: 24px;
`;
