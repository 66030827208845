import { all, fork } from "@redux-saga/core/effects";
import { watchStartPageLoad } from "./startPage";
import { watchUserPageLoad } from "./userPage";
import { watchContactPageLoad } from "./contactPage";
import { watchFrontPageLoad } from "./frontPage";
import { watchLoanPageLoad } from "./loanPage";
import { watchFetchCustomer } from "./fetchCustomer";
import { fetchUserAccount } from "./fetchAccount";
import { watchFetchAccountStatements } from "./fetchAccountStatements";
import { watchFetchPaymentPlans } from "./fetchPaymentPlans";
import { watchUserLogin } from "./userLogin";
import { watchValidateUserToken } from "./userToken";
import { watchGetContentfulNews } from "./contentful";
import { watchSelectAccount } from "./selectAccount";
import { watchFetchRemainingDebt } from "./fetchRemainingDebt";
import { watchUserLogout } from "./userLogout";
import { watchSaveBillingSettings } from "./saveBillingSettings";
import { watchSaveUserInfo } from "./userUpdateInfo";
import { watchGetTranslations } from "./ui";
import { watchSendFeedback } from "./feedback";
import { watchHandleMakePdf } from "./fetchPdf";
import { specialPaymentAgreement } from "./specialPaymentAgreements";

export function* rootSaga() {
    yield all([
        watchStartPageLoad(),
        watchUserPageLoad(),
        watchContactPageLoad(),
        watchFrontPageLoad(),
        watchLoanPageLoad(),
        watchUserPageLoad(),
        watchFetchCustomer(),
        ...fetchUserAccount.map(fork),
        watchFetchAccountStatements(),
        watchFetchPaymentPlans(),
        watchFetchRemainingDebt(),
        watchUserLogin(),
        watchValidateUserToken(),
        watchGetContentfulNews(),
        watchSelectAccount(),
        watchUserLogout(),
        watchSaveBillingSettings(),
        watchSaveUserInfo(),
        watchGetTranslations(),
        watchSendFeedback(),
        watchHandleMakePdf(),
        ...specialPaymentAgreement.map(fork)
    ]);
}
