import React from "react";
import { defineMessages } from "react-intl";

export const messages = defineMessages({
    title: {
        id: "paynow.title"
    },
    tooltip: {
        id: "paynow.tooltip"
    },
    info: {
        id: "paynow.info"
    },
    fees: {
        id: "paynow.fees"
    },
    sum: {
        id: "paynow.sum"
    },
    button: {
        id: "paynow.button",
        values: { strong: (bolded) => <strong>{bolded}</strong> }
    }
});
