import { defineMessages } from "react-intl";

export const messages = defineMessages({
    pagetitle: {
        id: "page.front.pagetitle"
    },
    customernumber: {
        id: "customer.number.short"
    },
    loaninfotitle: {
        id: "page.front.loaninfotitle"
    },
    loanlinktext: {
        id: "page.front.loan.linktext"
    },
    changelinktext: {
        id: "page.front.change.linktext"
    }
});
