import {
    FEEDBACK_TRIGGER,
    FEEDBACK_SUCCESS,
    FEEDBACK_ERROR,
    FEEDBACK_RESET
} from "../constants/feedback";

export const sendFeedbackTrigger = (data) => ({
    type: FEEDBACK_TRIGGER,
    payload: data
});

export const sendFeedbackSuccess = () => ({
    type: FEEDBACK_SUCCESS
});

export const sendFeedbackError = () => ({
    type: FEEDBACK_ERROR
});

export const sendFeedbackReset = () => ({
    type: FEEDBACK_RESET
});
