import styled from "styled-components";
import { breakpoints } from "../../constants/styles";

export const PayNowContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @media ${breakpoints.mobile} {
        flex-direction: column;
    }
`;

export const PayNowInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 200px;
    margin: 0 8px 0 8px;
    @media ${breakpoints.mobile} {
        padding: 8px;
    }
`;

export const PayNowInfoRow = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 200px;
    padding: 4px;
    margin: 0;
`;

export const PayNowAccount = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const PayNowButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 16px 0 0 0;
    width: 200px;
    height: 136px;
`;
