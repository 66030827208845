import { call, put, takeEvery } from "redux-saga/effects";
import { FETCH_PDF_TRIGGER } from "../constants/fetchPdf";
import { makeCalculationPdf } from "../util/api";
import { fetchPdfDocumentSuccess, fetchPdfDocumentError } from "../actions/fetchPdf";

export function* handleMakePdf(action) {
    try {
        const response = yield call(makeCalculationPdf, action.params, action.payload);
        if (response.status === 200) {
            yield put(fetchPdfDocumentSuccess());
        } else {
            yield put(fetchPdfDocumentError());
        }
    } catch (error) {
        yield put(fetchPdfDocumentError());
        console.log(error);
    }
}

export function* watchHandleMakePdf() {
    yield takeEvery(FETCH_PDF_TRIGGER, handleMakePdf);
}
