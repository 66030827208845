import styled from "styled-components";

import { fontSizes, colors } from "../../constants/styles";

export const FooterTitleContainer = styled.span`
    display: block;
    margin: 0;
    padding: 0 0 16px 0;
`;

export const FooterTitle = styled.span`
    font-size: ${fontSizes.large};
    font-weight: bold;
    line-height: 1.22;
    color: ${colors.gray};
`;
