import { takeEvery, put } from "@redux-saga/core/effects";
import { USER_LOGOUT } from "../constants/user";
import { reset } from "../actions/ui";

export function* watchUserLogout() {
    yield takeEvery(USER_LOGOUT, handleUserLogout);
}

export function* handleUserLogout(action) {
    yield put(reset());
    localStorage.clear();
    window.location = "/logout";
}
