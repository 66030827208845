import { defineMessages } from "react-intl";
import { TRANSLATED_STATEMENT_STATUSES } from "../../../constants/statementStatus";

export const messages = defineMessages({
    sum: {
        id: "invoicestable.sum"
    },
    statementtitle: {
        id: "invoicestable.statement.title"
    },
    ...TRANSLATED_STATEMENT_STATUSES
});
