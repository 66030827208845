import { defineMessages } from "react-intl";

export const messages = defineMessages({
    ingress: {
        id: "page.contractsmodal.ingress"
    },
    button: {
        id: "page.contractsmodal.button"
    },
    listitem1: {
        id: "page.contractsmodal.listitem1"
    },
    listitem2: {
        id: "page.contractsmodal.listitem2"
    },
    listitem3: {
        id: "page.contractsmodal.listitem3"
    }
});
