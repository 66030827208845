import React from "react";
import { CheckboxContainer, HiddenCheckbox, StyledCheckbox, Icon } from "./styled";
import { SmallText } from "../Fonts/SmallText";

export const Checkbox = (props) => {
    return (
        <>
            <CheckboxContainer>
                <HiddenCheckbox
                    checked={props.checked}
                    onChange={() => {
                        props.onChange();
                    }}></HiddenCheckbox>
                <StyledCheckbox
                    onClick={() => {
                        props.onChange();
                    }}
                    checked={props.checked}>
                    <Icon viewBox="0 0 24 24">
                        <polyline points="20 6 9 17 4 12" />
                    </Icon>
                </StyledCheckbox>
            </CheckboxContainer>
            <SmallText>{props.label}</SmallText>
        </>
    );
};
