import React, { useState } from "react";
import * as R from "ramda";
import { useTable, usePagination } from "react-table";
import { useIntl } from "react-intl";
import { colors } from "../../constants/styles";
import { CommonButton } from "../CommonButton";
import { Icon } from "../Icon";
import {
    StyledTableContainer,
    StyledTable,
    StyledThead,
    StyledTr,
    StyledTh,
    StyledTbody,
    StyledTd,
    StyledOptionsContainer,
    StyledLabel,
    SelectPageSize,
    StyledTfoot
} from "./styled";

import { messages } from "./messages";

export const GenericTable = ({
    columns,
    data,
    noHead,
    noFooter,
    noButton,
    pdfData,
    pdfParams,
    pdfDispatch,
    loading
}) => {
    const {
        canNextPage,
        canPreviousPage,
        footerGroups,
        getTableBodyProps,
        getTableProps,
        headerGroups,
        nextPage,
        page,
        pageOptions,
        prepareRow,
        previousPage,
        rows,
        setPageSize,
        state: { pageIndex }
    } = useTable({ columns, data, initialState: { pageIndex: 0, pageSize: 6 } }, usePagination);

    const { formatMessage: f } = useIntl();

    const pageSizeItems = [
        {
            text: f(messages.show6),
            value: "6"
        },
        {
            text: f(messages.show12),
            value: "12"
        },
        {
            text: f(messages.showall),
            value: `${rows.length}`
        }
    ];

    const [selectedItem, setSelectedItem] = useState(pageSizeItems[0]);

    const handleClick = (e) => {
        const selectedItem = R.findIndex(R.propEq("value", `${e.target.value}`))(pageSizeItems);
        setSelectedItem(pageSizeItems[selectedItem]);
        setPageSize(Number(e.target.value));
    };

    const handleOpenPdf = () => {
        console.log(pdfData);
        pdfDispatch(pdfData, pdfParams);
    };

    return (
        <>
            <StyledTableContainer>
                <StyledTable {...getTableProps()}>
                    {!noHead && (
                        <StyledThead>
                            {headerGroups.map((headerGroup) => (
                                <StyledTr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <StyledTh {...column.getHeaderProps()}>
                                            {column.render("Header")}
                                        </StyledTh>
                                    ))}
                                </StyledTr>
                            ))}
                        </StyledThead>
                    )}
                    <StyledTbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <StyledTr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <StyledTd {...cell.getCellProps()}>
                                                {cell.render("Cell")}
                                            </StyledTd>
                                        );
                                    })}
                                </StyledTr>
                            );
                        })}
                    </StyledTbody>
                    {!noFooter && (
                        <StyledTfoot>
                            {footerGroups.map((footerGroup) => (
                                <StyledTr {...footerGroup.getHeaderGroupProps()}>
                                    {footerGroup.headers.map((column) => (
                                        <StyledTd {...column.getFooterProps()}>
                                            {column.render("Footer")}
                                        </StyledTd>
                                    ))}
                                </StyledTr>
                            ))}
                        </StyledTfoot>
                    )}
                </StyledTable>
            </StyledTableContainer>
            <StyledOptionsContainer>
                <CommonButton
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                    padding="0 11px">
                    <Icon icon={["fa", "caret-left"]} color={colors.white} size="lg" />
                </CommonButton>
                <StyledLabel>
                    {pageIndex + 1}/{pageOptions.length}
                </StyledLabel>
                <CommonButton onClick={() => nextPage()} disabled={!canNextPage} padding="0 11px">
                    <Icon icon={["fa", "caret-right"]} color={colors.white} size="lg" />
                </CommonButton>
                <SelectPageSize
                    items={pageSizeItems}
                    onClick={(e) => handleClick(e)}
                    icon={{ iconType: ["fa", "caret-down"] }}
                    selected={selectedItem.text}
                />
            </StyledOptionsContainer>
            {!noButton && (
                <StyledOptionsContainer>
                    <CommonButton onClick={() => handleOpenPdf()} disabled={loading}>
                        {loading && (
                            <Icon icon={["fas", "circle-notch"]} color={colors.white} spin />
                        )}
                        {f(messages.openPdf)}
                    </CommonButton>
                </StyledOptionsContainer>
            )}
        </>
    );
};
