import React from "react";

import { TitleContainer } from "../TitleContainer";
import { SmallTitle } from "../Fonts/SmallTitle";

import { ActionContainer } from "./styled";

export const ActionTitle = ({ children, title, noBottomMargin }) => {
    return (
        <TitleContainer noBottomMargin={noBottomMargin}>
            <SmallTitle>{title}</SmallTitle>
            {children && <ActionContainer>{children}</ActionContainer>}
        </TitleContainer>
    );
};
