import { takeEvery, put, call } from "redux-saga/effects";
import { USER_VALIDATE_TOKEN } from "../constants/user";
import { verifyToken } from "../util/api";
import {
    fetchCustomerTrigger,
    updateIsValidatingToken,
    updateIsValidToken,
    updateToken
} from "../actions/user";
import { push } from "connected-react-router";

export function* watchValidateUserToken() {
    yield takeEvery(USER_VALIDATE_TOKEN, handleValidateUserToken);
}

export function* handleValidateUserToken() {

    yield put(updateIsValidatingToken(true));

    const token = localStorage.getItem('token');

    if (!token) {
        console.log('no token found?', token);
        window.location = "/";
        return;
    }

    const isValidToken = yield call(verifyToken, {
        token
    });

    yield put(updateToken(token));
    yield put(updateIsValidToken(isValidToken));

    if (isValidToken) {
        yield put(fetchCustomerTrigger());
    } else {
        yield put(updateToken(''));
        yield put(push('/error'));
    }

}
