import React, { useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
// import { goToTop } from "react-scrollable-anchor";
import { parsePhoneNumberFromString } from "libphonenumber-js";

import { BoxContainer } from "../../components/BoxContainer";
import { MainContent } from "../../components/MainContent";
import {
    FlexColumn,
    FlexRow,
    Grid2,
    Grid4,
    GridContainer,
    GridContent
} from "../../components/Grid";
import { PageTitleBox } from "../../components/PageTitleBox";
import { BasicText } from "../../components/Fonts/BasicText";
import { ActionTitle } from "../../components/ActionTitle";
import { CommonButton } from "../../components/CommonButton";
import { InputField } from "../../components/InputField";
import { Icon } from "../../components/Icon";
import { ErrorMessage } from "../../components/ErrorMessage";

import { userPageTrigger } from "../../actions/page";
import { triggerUpdateUserInfo, resetUpdateUserInfo } from "../../actions/user";
import { fetchPdfDocumentTrigger, fetchPdfDocumentReset } from "../../actions/fetchPdf";
import { selectUpdateUser } from "../../selectors/ui";
import { selectCustomerBasicInfoAndAddress } from "../../selectors/customer";
import { getFetchPdfStatus } from "../../selectors/fetchPdf";
import { selectToken } from "../../selectors/token";
import { getActiveAccountInfo } from "../../selectors/activeAccountInfo";
import { colors } from "../../constants/styles";
import { textFieldNames } from "./constants";
import { validateField } from "./validationUtils";

import { UserPageContent, UserPageContainer, SettingsButtonText } from "./styled";
import { PageTitleWithRightContent } from "../../components/PageTitleWithRightContent";

import { ContentItem } from "../../components/ContentItem";
import { messages } from "./messages";

export function UserPage() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(userPageTrigger());
        dispatch(fetchPdfDocumentReset());
        // goToTop();
        return () => {
            dispatch(resetUpdateUserInfo());
        };
    }, [dispatch]);

    const { customerBasicInfo, address } = useSelector(selectCustomerBasicInfoAndAddress);
    const { disbursementAccount } = useSelector(getActiveAccountInfo);

    const [isEditing, setEditing] = useState(false);
    const [fields, setFields] = useState({
        [textFieldNames.ACCOUNT_NUMBER]: {
            value: disbursementAccount[textFieldNames.ACCOUNT_NUMBER],
            errorMessage: null
        },
        [textFieldNames.PHONE_NUMBER]: {
            value: customerBasicInfo[textFieldNames.PHONE_NUMBER],
            errorMessage: null
        },
        [textFieldNames.MOBILE_PHONE]: {
            value: customerBasicInfo[textFieldNames.MOBILE_PHONE],
            errorMessage: null
        },
        [textFieldNames.EMAIL]: {
            value: customerBasicInfo[textFieldNames.EMAIL],
            errorMessage: null
        }
    });

    const { isUpdateUserInfoLoading, isUpdateUserInfoError } = useSelector(selectUpdateUser);
    const { loading } = useSelector(getFetchPdfStatus);
    const token = useSelector(selectToken || "");
    const [notValid, setNotValid] = useState(false);

    const { formatMessage: f } = useIntl();

    const parsePhoneCodeAndNumber = (value) => {
        if (value) {
            const phoneNumber = parsePhoneNumberFromString(value);
            if (phoneNumber) {
                return {
                    countryCallingCode: `+${phoneNumber.countryCallingCode}`,
                    nationalNumber: phoneNumber.nationalNumber
                };
            }
        }

        return {
            countryCallingCode: null,
            nationalNumber: null
        };
    };

    const editOrSave = () => {
        if (isEditing) {
            const phone = parsePhoneCodeAndNumber(fields[textFieldNames.PHONE_NUMBER].value);
            const mobile = parsePhoneCodeAndNumber(fields[textFieldNames.MOBILE_PHONE].value);

            const customerBasicInfo = {
                phoneCode: phone.countryCallingCode,
                phoneNumber: phone.nationalNumber,
                mobilePhoneCode: mobile.countryCallingCode,
                mobilePhoneNumber: mobile.nationalNumber,
                [textFieldNames.EMAIL]: fields[textFieldNames.EMAIL].value
            };
            console.log("CLICK", customerBasicInfo);
            dispatch(triggerUpdateUserInfo({ customerBasicInfo }));
        }
        setEditing(!isEditing);
    };

    const handleTextFieldUpdate = (event) => {
        let { value } = event.target;
        const { name } = event.target;
        const { validatedValue, errorMsg } = validateField(name, value);

        if (errorMsg !== null) {
            setNotValid(true);
        } else {
            setNotValid(false);
        }
        setFields({
            ...fields,
            [name]: { value: validatedValue, errorMessage: errorMsg }
        });
    };

    const handleCreatePdf = () => {
        const data = {
            name: `${customerBasicInfo.givenName} ${customerBasicInfo.surName}`,
            ssn: customerBasicInfo.ssn,
            address: address.streetAddress,
            postCode: address.zipCode,
            city: address.city,
            phoneNumber: fields[textFieldNames.MOBILE_PHONE].value,
            params: {
                source: "OR-PdfPowerOfAttorney"
            }
        };
        const params = {
            token,
            endpoint: "poa"
        };
        dispatch(fetchPdfDocumentTrigger(data, params));
    };

    const isDisabled = () => isUpdateUserInfoLoading || notValid;
    const getMesage = (value) => (value ? f(value) : null);

    return (
        <FlexColumn>
            <PageTitleBox>
                <PageTitleWithRightContent title={f(messages.pagetitle)}>
                    <BasicText>{`${f(messages.customernumber)} ${
                        customerBasicInfo.customerId
                    }`}</BasicText>
                </PageTitleWithRightContent>
            </PageTitleBox>
            <GridContainer>
                <Grid4>
                    <GridContent>
                        <BoxContainer>
                            <MainContent>
                                <ActionTitle title={f(messages.customertitle)} />
                                <FlexRow>
                                    <Grid2>
                                        <ContentItem title={f(messages.name)}>
                                            {`${customerBasicInfo.givenName} ${customerBasicInfo.surName}`}
                                        </ContentItem>
                                        <ContentItem title={f(messages.customernumberlong)}>
                                            {customerBasicInfo.customerId}
                                        </ContentItem>
                                        <ContentItem title={f(messages.personalnumber)}>
                                            {customerBasicInfo.ssn}
                                        </ContentItem>
                                    </Grid2>
                                    <Grid2>
                                        <UserPageContainer>
                                            <ContentItem title={f(messages.bankaccount)}>
                                                {fields[textFieldNames.ACCOUNT_NUMBER].value}
                                            </ContentItem>
                                            {/*
                                            <ContentItem
                                                title={f(messages.bankaccount)}
                                                edit={isEditing}
                                                editStateElement={
                                                    <InputField
                                                        value={
                                                            fields[textFieldNames.ACCOUNT_NUMBER]
                                                                .value
                                                        }
                                                        onChange={handleTextFieldUpdate}
                                                        name={textFieldNames.ACCOUNT_NUMBER}
                                                        helperText={getMessage(
                                                            fields[textFieldNames.ACCOUNT_NUMBER]
                                                                .errorMessage)
                                                        }
                                                    />
                                                }>
                                                {fields[textFieldNames.ACCOUNT_NUMBER].value}
                                                {isUpdateUserInfoLoading && (
                                                            <Icon
                                                                icon={["fas", "circle-notch"]}
                                                                color={colors.gray}
                                                                spin
                                                            />
                                                        )}
                                            </ContentItem>
                                            {isUpdateUserInfoError && (
                                                <UserPageContent>
                                                    <ErrorMessage>{f(messages.errormessage)}</ErrorMessage>
                                                </UserPageContent>
                                            )*/}
                                        </UserPageContainer>
                                    </Grid2>
                                </FlexRow>
                            </MainContent>
                        </BoxContainer>
                    </GridContent>
                </Grid4>
            </GridContainer>
            <GridContainer>
                <Grid4>
                    <GridContent>
                        <BoxContainer>
                            <MainContent>
                                <ActionTitle title={f(messages.contacttitle)}>
                                    <CommonButton
                                        onClick={(e) => editOrSave(e)}
                                        disabled={isDisabled()}>
                                        {!isEditing && (
                                            <>
                                                <Icon
                                                    icon={["fas", "pencil-alt"]}
                                                    color={colors.white}
                                                />
                                                <SettingsButtonText>
                                                    {f(messages.button)}
                                                </SettingsButtonText>
                                            </>
                                        )}
                                        {isEditing && (
                                            <>
                                                <Icon icon={["fas", "save"]} color={colors.white} />
                                                <SettingsButtonText>
                                                    {f(messages.save)}
                                                </SettingsButtonText>
                                            </>
                                        )}
                                    </CommonButton>
                                </ActionTitle>
                                <FlexRow>
                                    <Grid2>
                                        <ContentItem title={f(messages.address)}>
                                            {address.streetAddress}
                                        </ContentItem>
                                        <ContentItem title={f(messages.zipcode)}>
                                            {address.zipCode}
                                        </ContentItem>
                                        <ContentItem title={f(messages.city)}>
                                            {address.city}
                                        </ContentItem>
                                        <ContentItem>{f(messages.info)}</ContentItem>
                                    </Grid2>

                                    <Grid2>
                                        <UserPageContainer>
                                            <ContentItem
                                                title={f(messages.mobile)}
                                                edit={isEditing}
                                                editStateElement={
                                                    <InputField
                                                        value={
                                                            fields[textFieldNames.MOBILE_PHONE]
                                                                .value
                                                        }
                                                        onChange={handleTextFieldUpdate}
                                                        name={textFieldNames.MOBILE_PHONE}
                                                        helperText={getMesage(
                                                            fields[textFieldNames.MOBILE_PHONE]
                                                                .errorMessage
                                                        )}
                                                    />
                                                }>
                                                {fields[textFieldNames.MOBILE_PHONE].value}
                                                {isUpdateUserInfoLoading && (
                                                    <Icon
                                                        icon={["fas", "circle-notch"]}
                                                        color={colors.gray}
                                                        spin
                                                    />
                                                )}
                                            </ContentItem>
                                            {fields[textFieldNames.PHONE_NUMBER].value && (
                                                <>
                                                    <ContentItem
                                                        title={f(messages.phone)}
                                                        edit={isEditing}
                                                        editStateElement={
                                                            <InputField
                                                                value={
                                                                    fields[
                                                                        textFieldNames.PHONE_NUMBER
                                                                    ].value
                                                                }
                                                                onChange={handleTextFieldUpdate}
                                                                name={textFieldNames.PHONE_NUMBER}
                                                                helperText={getMesage(
                                                                    fields[
                                                                        textFieldNames.PHONE_NUMBER
                                                                    ].errorMessage
                                                                )}
                                                            />
                                                        }>
                                                        {fields[textFieldNames.PHONE_NUMBER].value}
                                                        {isUpdateUserInfoLoading && (
                                                            <Icon
                                                                icon={["fas", "circle-notch"]}
                                                                color={colors.gray}
                                                                spin
                                                            />
                                                        )}
                                                    </ContentItem>
                                                </>
                                            )}
                                            <ContentItem
                                                title={f(messages.email)}
                                                edit={isEditing}
                                                editStateElement={
                                                    <InputField
                                                        value={fields[textFieldNames.EMAIL].value}
                                                        onChange={handleTextFieldUpdate}
                                                        name={textFieldNames.EMAIL}
                                                        helperText={getMesage(
                                                            fields[textFieldNames.EMAIL]
                                                                .errorMessage
                                                        )}
                                                    />
                                                }>
                                                {fields[textFieldNames.EMAIL].value}
                                                {isUpdateUserInfoLoading && (
                                                    <Icon
                                                        icon={["fas", "circle-notch"]}
                                                        color={colors.gray}
                                                        spin
                                                    />
                                                )}
                                            </ContentItem>
                                            {isUpdateUserInfoError && (
                                                <UserPageContent>
                                                    <ErrorMessage>
                                                        {f(messages.errormessage)}
                                                    </ErrorMessage>
                                                </UserPageContent>
                                            )}
                                        </UserPageContainer>
                                    </Grid2>
                                </FlexRow>
                            </MainContent>
                        </BoxContainer>
                    </GridContent>
                </Grid4>
            </GridContainer>
            <GridContainer>
                <Grid4>
                    <GridContent>
                        <BoxContainer>
                            <MainContent>
                                <Grid4>
                                    <ActionTitle title={f(messages.authorisedpersontitle)}>
                                        <CommonButton
                                            onClick={() => handleCreatePdf()}
                                            disabled={loading}>
                                            {loading && (
                                                <Icon
                                                    icon={["fas", "circle-notch"]}
                                                    color={colors.white}
                                                    spin
                                                />
                                            )}
                                            {f(messages.addauthorisedpersonbtn)}
                                        </CommonButton>
                                    </ActionTitle>
                                    <ContentItem>
                                        <FormattedMessage {...messages.authorisedpersoninfo} />
                                    </ContentItem>
                                </Grid4>
                            </MainContent>
                        </BoxContainer>
                    </GridContent>
                </Grid4>
            </GridContainer>
        </FlexColumn>
    );
}
