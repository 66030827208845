import React from "react";
import { defineMessages } from "react-intl";
import { BasicTextP } from "../../components/Fonts/BasicText";

export const messages = defineMessages({
    pagetitle: {
        id: "page.loan.pagetitle"
    },
    customernumber: {
        id: "customer.number.short"
    },
    info: {
        subtitle: {
            id: "page.loan.info.subtitle"
        },
        remainingtoday: {
            id: "page.loan.info.remaining.today"
        },
        remaininginfo: {
            id: "page.loan.info.remaining.info",
            values: {
                p: (value) => <BasicTextP>{value}</BasicTextP>
            }
        },
        modalsummary: {
            id: "page.loan.modal.summary"
        },
        modalcontracts: {
            id: "page.loan.modal.contracts"
        }
    },
    changepaymentinfobutton: {
        id: "page.loan.paymentinfo.change.button"
    },
    statementstable: {
        id: "page.loan.statements.tablename"
    },
    statementssettings: {
        id: "page.loan.statements.settings"
    },
    archivetable: {
        id: "page.loan.archive.tablename"
    },
    paymentinfobutton: {
        id: "page.loan.paymentinfo.button"
    },
    calculator: {
        subtitle: {
            id: "page.loan.calculator.subtitle"
        },
        chooseday: {
            id: "page.loan.calculator.chooseday"
        },
        button: {
            id: "page.loan.calculator.button"
        },
        chosendate: {
            id: "page.loan.calculator.chosendate"
        },
        accounttitle: {
            id: "page.loan.calculator.bankaccount.title"
        },
        accountcontent: {
            id: "page.loan.calculator.bankaccount.content"
        },
        receivertitle: {
            id: "page.loan.calculator.receiver.title"
        },
        companyname: {
            id: "companyinfo.name"
        },
        receivernotetitle: {
            id: "page.loan.calculator.receivernote.title"
        },
        sumtitle: {
            id: "page.loan.calculator.sum.title"
        },
        sumcontent: {
            id: "page.loan.calculator.sum.content"
        },
        calculationbutton: {
            id: "page.loan.calculator.calculation.button"
        }
    },
    calculatorinfo: {
        title: {
            id: "page.loan.calculator.info.subtitle"
        },
        listinfo: {
            id: "page.loan.calculator.info.listinfo"
        },
        listitem1: {
            id: "page.loan.calculator.info.listitem1"
        },
        listitem2: {
            id: "page.loan.calculator.info.listitem2"
        },
        listitem3: {
            id: "page.loan.calculator.info.listitem3",
            values: {
                adobelink: (value) => (
                    <a
                        href="https://get.adobe.com/reader"
                        target="_blank"
                        rel="noopener noreferrer">
                        {value}
                    </a>
                )
            }
        },
        listitem4: {
            id: "page.loan.calculator.info.listitem4",
            values: {
                mailto: (value) => <a href={`mailto: ${value}`}>{value}</a>,
                newrow: <br />
            }
        }
    }
});
