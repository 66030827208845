import { call, put, takeEvery } from "redux-saga/effects";
import { CONTENTFUL_NEWS_FETCH_TRIGGER } from "../constants/contentful";
import { getContentfulNews } from "../util/contentful";
import { fetchContentfulNewsSuccess } from "../actions/contentful";

export function* handleGetContentfulNews(action) {
    try {
        const newsItems = yield call(getContentfulNews, action.payload);

        yield put(fetchContentfulNewsSuccess(newsItems));
    } catch (error) {
        // TODO: Some kind of error handling needed
        console.log(error);
    }
}

export function* watchGetContentfulNews() {
    yield takeEvery(CONTENTFUL_NEWS_FETCH_TRIGGER, handleGetContentfulNews);
}
