import styled from "styled-components";

export const CenteredButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: ${(props) => (props.marginTop ? props.marginTop : `0`)};
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : `0`)};
    margin-left: ${(props) => (props.marginLeft ? props.marginLeft : `0`)};
    margin-right: ${(props) => (props.marginRight ? props.marginRight : `0`)};
`;
