import {
    FETCH_ACCOUNT_STATEMENTS_SUCCESS,
    FETCH_ACCOUNT_STATEMENTS_TRIGGER,
    FETCH_ACCOUNT_SUCCESS,
    FETCH_ACCOUNT_TRIGGER,
    SELECT_ACCOUNT,
    SET_SELECTED_ACCOUNT,
    SET_SELECTED_ACCOUNT_INFO,
    SAVE_BILLING_SETTINGS_TRIGGER,
    SAVE_BILLING_SETTINGS_SUCCESS,
    SAVE_BILLING_SETTINGS_ERROR,
    SAVE_BILLING_SETTINGS_RESET
} from "../constants/account";

export function fetchAccountsTrigger() {
    return {
        type: FETCH_ACCOUNT_TRIGGER
    };
}

export function fetchAccountsSuccess(payload) {
    return {
        type: FETCH_ACCOUNT_SUCCESS,
        payload
    };
}

export function fetchAccountStatementsTrigger() {
    return {
        type: FETCH_ACCOUNT_STATEMENTS_TRIGGER
    };
}

export function fetchAccountStatementsSuccess(payload) {
    return {
        type: FETCH_ACCOUNT_STATEMENTS_SUCCESS,
        payload
    };
}

export function selectAccount() {
    return {
        type: SELECT_ACCOUNT
    };
}

export function setSelectedAccount(account, initial) {
    return {
        type: SET_SELECTED_ACCOUNT,
        payload: {
            account,
            initial
        }
    };
}

export function setSelectedAccountInfo(payload) {
    return {
        type: SET_SELECTED_ACCOUNT_INFO,
        payload
    };
}

export function saveBillingSettings(payload) {
    return {
        type: SAVE_BILLING_SETTINGS_TRIGGER,
        payload
    };
}

export function saveBillingSettingsSuccess() {
    return {
        type: SAVE_BILLING_SETTINGS_SUCCESS,
        payload: {
            saved: true,
            error: false
        }
    };
}

export function saveBillingSettingsError() {
    return {
        type: SAVE_BILLING_SETTINGS_ERROR,
        payload: {
            saved: false,
            error: true
        }
    };
}

export function resetSaveBillingSettings() {
    return {
        type: SAVE_BILLING_SETTINGS_RESET,
        payload: {
            saved: false,
            error: false
        }
    };
}
