import { FETCH_SPECIAL_PAYMENT_AGREEMENT } from "../constants/specialPaymentAgreements";

export const initalState = [];

export function specialPaymentAgreementsReducer(state = initalState, action) {
    switch (action.type) {
        case FETCH_SPECIAL_PAYMENT_AGREEMENT.SUCCESS: {
            return action.payload;
        }
        default:
            return state;
    }
}
