import React from "react";
import { useRouteMatch } from "react-router-dom";
import { Icon } from "../Icon";
import {
    StyledMoreFooterLink,
    StyledMoreIconText,
    StyledMoreIcon
} from "./MobileNavigation.styled";
import { colors } from "../../constants/styles";

export const MoreFooterLink = ({ route, icon, size, children }) => {
    const isActive = useRouteMatch(route);
    return (
        <StyledMoreFooterLink to={route}>
            <StyledMoreIcon>
                <Icon
                    icon={icon}
                    size={size || "lg"}
                    color={isActive ? colors.gray : colors.mediumGray}
                />
            </StyledMoreIcon>
            <StyledMoreIconText isActive={isActive ? colors.gray : colors.mediumGray}>
                {children}
            </StyledMoreIconText>
        </StyledMoreFooterLink>
    );
};
