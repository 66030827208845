import { defineMessages } from "react-intl";

export const messages = defineMessages({
    title: {
        id: "footer.companyinfo.title"
    },
    name: {
        id: "companyinfo.name"
    },
    address: {
        id: "companyinfo.address"
    },
    post: {
        id: "companyinfo.post"
    },
    vatnumber: {
        id: "footer.companyinfo.vatnumber"
    },
    contact: {
        id: "footer.companyinfo.contact"
    },
    cookies: {
        id: "footer.companyinfo.cookies"
    },
    policy: {
        id: "footer.companyinfo.policy"
    }
});
