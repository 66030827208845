import React from "react";
import SpinnerImage from "../images/ic-loading_with_bg.svg";
import styled from "styled-components";

export const Spinner = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 64px 0 474px 0;
`;

export function Loader() {
    return (
        <Spinner>
            <img alt="spinner" src={SpinnerImage} />
        </Spinner>
    );
}
