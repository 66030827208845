import React, { useState, useRef, useEffect } from "react";
import { DropDownHeader } from "./DropDownHeader";
import { DropDownList } from "./DropDownList";
import { defaultTheme } from "./defaultTheme";
import { DropDownContainer } from "./styled";

export const DropDown = ({ items, onClick, selected, ...rest }) => {
    const node = useRef();

    const { icon, theme, noListArrow, listHeaderItem } = rest;
    const [ddOpen, setDdOpen] = useState(false);

    const ddTheme = {
        listContainer: {
            ...defaultTheme.listContainer,
            ...(theme && theme.listContainer)
        },
        listHeader: {
            ...defaultTheme.listHeader,
            ...(theme && theme.listHeader),
            headerIcon: {
                ...defaultTheme.headerIcon,
                ...(theme && theme.headerIcon)
            }
        },
        listItems: {
            ...defaultTheme.listItems,
            ...(theme && theme.listItems),
            listItem: {
                ...defaultTheme.listItem,
                ...(theme && theme.listItem)
            }
        }
    };

    const handleClickOutside = (e) => {
        if (node.current.contains(e.target)) {
            return;
        }
        setDdOpen(false);
    };

    const handleChange = (e) => {
        onClick(e);
        setDdOpen(false);
    };

    useEffect(() => {
        if (ddOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ddOpen]);

    return (
        <DropDownContainer theme={ddTheme.listContainer} ref={node}>
            <DropDownHeader
                icon={icon}
                label={selected}
                onClick={(e) => setDdOpen(!ddOpen)}
                theme={ddTheme.listHeader}
            />
            {ddOpen && (
                <DropDownList
                    items={items}
                    onClick={handleChange}
                    theme={ddTheme.listItems}
                    noListArrow={noListArrow}
                    listHeaderItem={listHeaderItem}
                />
            )}
        </DropDownContainer>
    );
};
