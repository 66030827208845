import styled from "styled-components/macro";
import { breakpoints } from "../constants/styles";

export const Container = styled.div`
    display: flex;
    width: 90%;
    max-width: 976px;
    @media ${breakpoints.mobile} {
        width: 100%;
        margin: 10px;
    }
`;
