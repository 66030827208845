import React from "react";
import { colors } from "../../../constants/styles";
import { Icon } from "../../Icon";

import { StyledHeader, IconContainer } from "./styled";

export const DropDownHeader = (props) => {
    if (props.icon) {
        return (
            <StyledHeader onClick={props.onClick} theme={props.theme}>
                {props.label}
                <IconContainer theme={props.theme.headerIcon}>
                    <Icon
                        icon={props.icon.iconType}
                        color={props.icon.color || colors.black}
                        size="lg"
                    />
                </IconContainer>
            </StyledHeader>
        );
    }
    return <StyledHeader onClick={props.onClick}>{props.label}</StyledHeader>;
};
