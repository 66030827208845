import React, { useEffect } from "react";
import { useIntl } from "react-intl";
// import { goToTop } from "react-scrollable-anchor";
import styled from "styled-components/macro";
import { GridContainer, FlexColumn, Grid4, GridContent } from "../../components/Grid";
import { BoxContainer } from "../../components/BoxContainer";
import { MainContent } from "../../components/MainContent";
import { LargeTitle } from "../../components/Fonts/LargeTitle";

import { PageTitleBox } from "../../components/PageTitleBox";
import { SmallTitle } from "../../components/Fonts/SmallTitle";

import { messages } from "./messages";

const LogoutContainer = styled.span`
    width: 100%;
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
`;

const Iframe = styled.iframe`
    width: 100%;
    height: 590px;
    display: flex;
    justify-content: center;
    border: none;
`;

export function LogOutPage() {
    useEffect(() => {
        // goToTop();
    }, []);

    const { formatMessage: f } = useIntl();

    return (
        <FlexColumn>
            <PageTitleBox>
                <LargeTitle>{f(messages.pagetitle)}</LargeTitle>
            </PageTitleBox>

            <GridContainer>
                <Grid4>
                    <GridContent>
                        <BoxContainer>
                            <MainContent>
                                <LogoutContainer>
                                    <SmallTitle>{f(messages.message)}</SmallTitle>
                                </LogoutContainer>
                                <span>
                                    <Iframe src={f(messages.surveylink)} />
                                </span>
                            </MainContent>
                        </BoxContainer>
                    </GridContent>
                </Grid4>
            </GridContainer>
        </FlexColumn>
    );
}
