import { call, put, takeEvery } from "redux-saga/effects";
import { FEEDBACK_TRIGGER } from "../constants/feedback";
import { sendFeedback } from "../util/api";
import { sendFeedbackSuccess, sendFeedbackError } from "../actions/feedback";

export function* handleSendFeedback(action) {
    try {
        const response = yield call(sendFeedback, action.payload);

        if (response) {
            yield put(sendFeedbackSuccess());
        } else {
            yield put(sendFeedbackError());
        }
    } catch (error) {
        console.log(error);
    }
}

export function* watchSendFeedback() {
    yield takeEvery(FEEDBACK_TRIGGER, handleSendFeedback);
}
