import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { colors } from "../../constants/styles";
import {
    MobileNavigationContainer,
    MobileNavigationItemsContainer,
    MobileIconText,
    MobileIconContainer,
    MoreButtonContainer,
    MoreContentItem,
    StyledListHeader,
    StyledHeaderText,
    StyledHeaderIcon,
    MobileFeedbackformContainer
} from "./MobileNavigation.styled";
import { MoreContentContainer } from "./MoreButton.styled";
import { SmallText } from "../Fonts/SmallText";
import { FooterLink } from "./FooterLink";
import { MoreFooterLink } from "./MoreFooterLink";
import { Icon } from "../Icon";
import { useSelector } from "react-redux";
import { MoreButton } from "./MoreButton";
import { FeedbackForm } from "../Feedback/FeedbackForm";

import { messages } from "./messages";

const ListHeader = ({ closeFn }) => {
    return (
        <StyledListHeader>
            <StyledHeaderText bold>
                <FormattedMessage {...messages.more} />
            </StyledHeaderText>
            <StyledHeaderIcon onClick={() => closeFn()}>
                <Icon icon={["fa", "times"]} color={colors.black} size="lg" />
            </StyledHeaderIcon>
        </StyledListHeader>
    );
};

const MobileNavigationLinks = () => {
    return (
        <MobileNavigationItemsContainer>
            <SmallText>
                <FormattedMessage {...messages.menutext} />
            </SmallText>
            <MoreFooterLink route={"/user"} icon={["fa", "user-alt"]}>
                <FormattedMessage {...messages.user} />
            </MoreFooterLink>
            <MoreFooterLink route={"/contact"} icon={["fa", "comments"]}>
                <FormattedMessage {...messages.contact} />
            </MoreFooterLink>
        </MobileNavigationItemsContainer>
    );
};

const MoreButtonLabel = ({ isMoreActive, onClick }) => {
    return (
        <MoreButtonContainer>
            <Icon
                icon={["fa", "ellipsis-h"]}
                color={isMoreActive ? colors.black : colors.mediumGray}
                size="lg"
            />
            <MobileIconText isActive={isMoreActive} onClick={onClick}>
                <FormattedMessage {...messages.more} />
            </MobileIconText>
        </MoreButtonContainer>
    );
};

const MobileNavFeedback = () => {
    return (
        <MobileNavigationItemsContainer>
            <SmallText>Palautenurkka</SmallText>
            <MobileFeedbackformContainer>
                <FeedbackForm
                    feedbackIcon={
                        <Icon icon={["fa", "thumbs-up"]} size="lg" color={colors.mediumGray} />
                    }
                />
            </MobileFeedbackformContainer>
        </MobileNavigationItemsContainer>
    );
};

const MoreMenuContent = ({ closeDialog }) => {
    return (
        <MoreContentContainer>
            <MoreContentItem>
                <ListHeader closeFn={closeDialog} />
            </MoreContentItem>
            <MoreContentItem>
                <MobileNavigationLinks />
            </MoreContentItem>
            <MoreContentItem>
                <MobileNavFeedback />
            </MoreContentItem>
        </MoreContentContainer>
    );
};

export function MobileNavigation() {
    const hasToken = useSelector((state) => {
        return state.user.token;
    });

    const location = useLocation();
    const [moreOpen, setMoreOpen] = useState(false);

    useEffect(() => {
        setMoreOpen(false);
    }, [location]);

    let isMoreActive = false;

    if (location.pathname === "/user") isMoreActive = true;
    if (location.pathname === "/contact") isMoreActive = true;

    if (!hasToken) {
        return null;
    }

    return (
        <MobileNavigationContainer>
            <FooterLink route={"/front"} icon={["fa", "home"]}>
                <FormattedMessage {...messages.home} />
            </FooterLink>
            <FooterLink route={"/loan"} icon={["far", "money-bill-alt"]}>
                <FormattedMessage {...messages.loan} />
            </FooterLink>
            <FooterLink route={"/change"} icon={["fa", "sync-alt"]}>
                <FormattedMessage {...messages.change} />
            </FooterLink>
            {moreOpen && <MoreMenuContent closeDialog={setMoreOpen} />}
            <MobileIconContainer isOpen={moreOpen} onClick={() => setMoreOpen(!moreOpen)}>
                <MoreButton>
                    <MoreButtonLabel isMoreActive={isMoreActive} />
                </MoreButton>
            </MobileIconContainer>
        </MobileNavigationContainer>
    );
}
