import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import styled from "styled-components/macro";
import { CommonButton } from "../CommonButton";
import { CenteredButtonContainer } from "../CenteredButtonContainer";
import { Checkbox } from "../Checkbox";
import {
    StyledParagraph,
    FeedbackBox,
    FeedBackComment,
    Centerd,
    Container,
    WritingFeedback,
    SentFeedback
} from "./FeedbackForm.styled";
import { getCustomerBasicInfo } from "../../selectors/customer";
import { getFeedbackStatus } from "../../selectors/feedback";
import { selectToken } from "../../selectors/token";
import { sendFeedbackTrigger } from "../../actions/feedback";
import { ErrorMessage } from "../ErrorMessage";
import { messages } from "./messages";
import { Icon } from "../../components/Icon";
import { colors } from "../../constants/styles";

export const FeedbackForm = ({ feedbackIcon }) => {
    const [answerRequired, toggleAnswerRequired] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");
    const dispatch = useDispatch();

    const { customerId, surName, givenName, email } = useSelector(getCustomerBasicInfo);
    const token = useSelector(selectToken);

    const { sent, error, isSending } = useSelector(getFeedbackStatus);

    const { formatMessage: f } = useIntl();
    const handleOnClick = () => {
        const customerEmail = answerRequired ? `<${email}>` : email;

        const data = {
            customerName: `${givenName} ${surName}`,
            customerNumber: customerId,
            customerEmail,
            message: feedbackMessage,
            answerRequired: answerRequired ? "yes" : "no",
            token
        };
        dispatch(sendFeedbackTrigger(data));
    };
    const ButtonPadding = styled.span`
        padding-right: 4px;
    `;
    return (
        <>
            {!sent && (
                <WritingFeedback>
                    {feedbackIcon && feedbackIcon}

                    <StyledParagraph>{f(messages.question)}</StyledParagraph>

                    {error && <ErrorMessage>{f(messages.error)}</ErrorMessage>}

                    <FeedbackBox
                        placeholder={f(messages.textfield)}
                        onChange={(event) => setFeedbackMessage(event.target.value)}
                    />
                    <Centerd>
                        <Checkbox
                            checked={answerRequired}
                            onChange={() => toggleAnswerRequired(!answerRequired)}
                            label={f(messages.option)}
                        />
                    </Centerd>
                    <Container>
                        <CenteredButtonContainer>
                            <CommonButton
                                onClick={() => handleOnClick()}
                                disabled={feedbackMessage.length === 0 || isSending}>
                                {isSending && (
                                    <ButtonPadding>
                                        {" "}
                                        <Icon
                                            icon={["fas", "circle-notch"]}
                                            color={colors.white}
                                            spin
                                        />
                                    </ButtonPadding>
                                )}
                                {f(messages.send)}
                            </CommonButton>
                        </CenteredButtonContainer>
                    </Container>
                </WritingFeedback>
            )}
            {sent && (
                <SentFeedback>
                    {feedbackIcon && feedbackIcon}
                    <StyledParagraph>{f(messages.thankyou)}</StyledParagraph>
                    <FeedBackComment>{f(messages.answer)}</FeedBackComment>
                </SentFeedback>
            )}
        </>
    );
};
