import { getMonth, getYear, addMonths, parse } from "date-fns";
import * as R from "ramda";
import { DEFAULT_FORMAT_DATE } from "../../constants/dateTime";

const parseDate = (dateToParse) => parse(dateToParse, DEFAULT_FORMAT_DATE, new Date());

export const getMonths = (firstStatement) => {
    const months = [];
    const now = new Date();
    const parsedDate = parseDate(firstStatement.dueDate);
    const statementMonth = getMonth(parsedDate);

    const startMonth =
        firstStatement.documentId && statementMonth >= getMonth(now) ? addMonths(now, 1) : now;

    for (let i = 0; i < 12; i++) {
        const current = addMonths(startMonth, i + 1);
        const month = getMonth(current);
        const year = getYear(current);
        const monthId = month + 1;
        const id = `${year}-${monthId.toString().padStart(2, "0")}`;
        const nextYear = months[0] && months[0].year !== year ? true : false;
        months.push({ month, year, id, nextYear });
    }

    return months;
};

const findIndexByPropName = (propName, index, arr) => {
    const fixedIndex = index >= arr.length ? index % arr.length : index;
    return R.findIndex(R.propEq(propName, fixedIndex))(arr);
};

const checkYear = (propName, index, arr, year) => {
    const fixedYear = index >= arr.length ? year + 1 : year;
    const ind = findIndexByPropName(propName, index, arr);
    return arr[ind] && arr[ind].year === fixedYear;
};

const isMonthDisabled = (monthProp, index) => {
    return monthProp.index === index && monthProp.sameYear;
};

const isLoanInactive = (currentMonth, lastStatementDueDate) => {
    const parsed = parseDate(lastStatementDueDate);
    const lastStatementYear = getYear(parsed);
    const lastStatementMonth = getMonth(parsed);
    const id = `${lastStatementYear}-${lastStatementMonth.toString().padStart(2, "0")}`;
    return currentMonth.id > id;
};

export const disableMonths = (months, selectedMonths, lockedMonths, lastStatementDueDate) => {
    let editedMonths = months;
    selectedMonths.forEach((selectedMonth) => {
        const year = parseInt(selectedMonth.year);
        const monthIndex = selectedMonth.monthIndex;

        const monthProps = {
            prev: {
                index: findIndexByPropName("month", monthIndex - 1, months),
                sameYear: checkYear("month", monthIndex - 1, months, year)
            },
            current: {
                index: findIndexByPropName("month", monthIndex, months),
                sameYear: checkYear("month", monthIndex, months, year)
            },
            next: {
                index: findIndexByPropName("month", monthIndex + 1, months),
                sameYear: checkYear("month", monthIndex + 1, months, year)
            }
        };
        editedMonths = editedMonths.map((editedMonth, index) => {
            let disabled = editedMonth.disabled || false;
            let selected = editedMonth.selected || false;
            const monthDisabled =
                isMonthDisabled(monthProps.prev, index) ||
                isMonthDisabled(monthProps.current, index) ||
                isMonthDisabled(monthProps.next, index) ||
                isLoanInactive(editedMonth, lastStatementDueDate);

            /* Check if selected months === 3 in same year */
            const countSelectedInYear = R.pipe(
                R.filter(R.propEq("year", `${editedMonth.year}`)),
                R.length
            )(selectedMonths);

            const locked =
                R.pipe(
                    R.filter(R.propEq("year", `${editedMonth.year}`)),
                    R.findIndex(R.propEq("monthIndex", editedMonth.month))
                )(lockedMonths) !== -1
                    ? true
                    : false;

            /* if needed to omit overwriting */
            if (monthDisabled) {
                disabled = monthDisabled;
                selected = monthDisabled ? monthProps.current.index === index : false;
            }

            if (countSelectedInYear === 3) {
                disabled = true;
            }

            return { ...editedMonth, disabled, selected, locked };
        });
    });

    return editedMonths;
};
