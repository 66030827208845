import React from "react";
import styled from "styled-components/macro";
import { useIntl } from "react-intl";
import { LoanInfoText } from "../../Fonts/LoanInfoText";
import { Currency } from "../../Currency";

import { messages } from "./messages";

export const LoanCalculatorInformationContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

export const LoanCalculatorBoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const LoanCalculatorTotalAmount = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 16px 0 0 0;
    width: 50%;
`;

export const LoanCalculatorPaidAmount = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px 0 0 0;
    width: 50%;
`;

export function LoanCalculatorInformation(props) {
    const { paidTotal, loanAmount } = props;

    const { formatMessage: f } = useIntl();

    return (
        <LoanCalculatorInformationContainer>
            <LoanCalculatorPaidAmount>
                <LoanCalculatorBox
                    textAlign={"left"}
                    title={f(messages.paidtitle)}
                    value={paidTotal}
                />
            </LoanCalculatorPaidAmount>
            <LoanCalculatorTotalAmount>
                <LoanCalculatorBox
                    textAlign={"right"}
                    title={f(messages.toatalammounttitle)}
                    value={loanAmount}
                />
            </LoanCalculatorTotalAmount>
        </LoanCalculatorInformationContainer>
    );
}

export function LoanCalculatorBox(props) {
    const { textAlign, title, value } = props;

    return (
        <LoanCalculatorBoxContainer>
            <LoanInfoText textAlign={textAlign}>
                {title} <br />
                <Currency>{value}</Currency>
            </LoanInfoText>
        </LoanCalculatorBoxContainer>
    );
}
