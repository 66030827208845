import React from "react";

import { Icon } from "../Icon";
import { StyledParagraph, StyledList, StyledListItem, ListItemIconContainer } from "./styled";
import { colors } from "../../constants/styles";

export const ItemList = ({ items, label }) => {
    return (
        <>
            {label && <StyledParagraph>{label}</StyledParagraph>}
            <StyledList>
                {items.map((item, index) => {
                    return (
                        <StyledListItem key={`item-${index}`}>
                            <ListItemIconContainer>
                                <Icon
                                    icon={["fas", "circle"]}
                                    color={colors.green}
                                    transform="shrink-11 left-5"
                                />
                            </ListItemIconContainer>
                            {item}
                        </StyledListItem>
                    );
                })}
            </StyledList>
        </>
    );
};
