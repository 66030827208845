import { defineMessages } from "react-intl";

export const messages = defineMessages({
    channeltitle: {
        id: "page.settingsmodal.channeltitle"
    },
    email: {
        id: "page.settingsmodal.radio.email"
    },
    paper: {
        id: "page.settingsmodal.radio.paper"
    },
    duedatetitle: {
        id: "page.settingsmodal.duedatetitle"
    },
    first: {
        id: "page.settingsmodal.radio.first"
    },
    second: {
        id: "page.settingsmodal.radio.second"
    },
    third: {
        id: "page.settingsmodal.radio.third"
    },
    info: {
        id: "page.settingsmodal.radio.info"
    },
    einvoicetitle: {
        id: "page.settingsmodal.einvoice.title"
    },
    listitem1: {
        id: "page.settingsmodal.einvoice.listitem1"
    },
    listitem2: {
        id: "page.settingsmodal.einvoice.listitem2"
    },
    listitem3: {
        id: "page.settingsmodal.einvoice.listitem3"
    },
    listlabel: {
        id: "page.settingsmodal.einvoice.listlabel"
    },
    button: {
        id: "page.settingsmodal.save.button"
    },
    errormsg: {
        id: "page.settingsmodal.save.errormsg"
    }
});
