export const FETCH_SPECIAL_PAYMENT_AGREEMENT = {
    TRIGGER: "FETCH/SPECIAL_PAYMENT_AGREEMENT/TRIGGER",
    SUCCESS: "FETCH/SPECIAL_PAYMENT_AGREEMENT/SUCCESS",
    ERROR: "FETCH/SPECIAL_PAYMENT_AGREEMENT/ERROR"
};

export const SET_SPECIAL_PAYMENT_AGREEMENT = {
    TRIGGER: "SET/SPECIAL_PAYMENT_AGREEMENT/TRIGGER",
    SUCCESS: "SET/SPECIAL_PAYMENT_AGREEMENT/SUCCESS",
    ERROR: "SET/SPECIAL_PAYMENT_AGREEMENT/ERROR",
    RESET: "SET/SPECIAL_PAYMENT_AGREEMENT/RESET"
};

export const SPECIAL_PAYMENT_AGREEMENTS_STATUS = {
    ACTIVE: "ACTIVE",
    SCHEDULED: "SCHEDULED",
    FINISHED: "FINISHED",
    CANCELLED: "CANCELLED"
};

export const SPECIAL_PAYMENT_AGREEMENTS_TYPES = {
    PAYMENT_FREE_MONTH: "PAYMENT_FREE_MONTH",
    AMORTIZATION_FREE_MONTH: "AMORTIZATION_FREE_MONTH"
};
