import styled from "styled-components/macro";
import { colors, fonts, fontSizes } from "../../constants/styles";

export const LoanInfoText = styled.span`
    color: ${colors.gray};
    font-family: ${fonts.basic};
    font-size: ${fontSizes.small};
    line-height: 1.14;
    text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
`;
