import React from "react";

export const getCurrency = (value, locale = "fi-FI", currency = "EUR") => {
    const val = parseFloat(value);
    return `${val.toLocaleString(locale, {
        style: "currency",
        currency: currency,
        currencyDisplay: "symbol"
    })}`;
};

export const Currency = ({ children, locale = "fi-FI", currency = "EUR" }) => (
    <>{getCurrency(children, locale, currency)}</>
);
