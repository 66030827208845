import { defineMessages } from "react-intl";

export const messages = defineMessages({
    pagetitle: {
        id: "page.user.pagetitle"
    },
    customernumber: {
        id: "customer.number.short"
    },
    customertitle: {
        id: "page.user.subtitle.customer"
    },
    name: {
        id: "customer.name"
    },
    customernumberlong: {
        id: "page.user.customer.number"
    },
    personalnumber: {
        id: "customer.personalnumber"
    },
    bankaccount: {
        id: "customer.bankaccount"
    },
    errormessage: {
        id: "page.user.errormessage"
    },
    contacttitle: {
        id: "page.user.subtitle.contact"
    },
    button: {
        id: "page.user.contact.button"
    },
    save: {
        id: "page.user.contact.button.save"
    },
    address: {
        id: "page.user.contact.address"
    },
    zipcode: {
        id: "page.user.contact.zipcode"
    },
    city: {
        id: "page.user.contact.city"
    },
    info: {
        id: "page.user.contact.info"
    },
    mobile: {
        id: "page.user.contact.mobile"
    },
    phone: {
        id: "page.user.contact.phone"
    },
    email: {
        id: "page.user.contact.email"
    },
    authorisedpersontitle: {
        id: "page.user.subtitle.authorisedperson"
    },
    addauthorisedpersonbtn: {
        id: "page.user.subtitle.addauthorisedperson.button"
    },
    authorisedpersoninfo: {
        id: "page.user.authorisedperson.info"
    },
    validationErrors: {
        empty: {
            id: "page.user.validation.empty"
        },
        email: {
            id: "page.user.validation.email"
        },
        phone: {
            id: "page.user.validation.phone"
        },
        account: {
            id: "page.user.validation.account"
        }
    }
});
