import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { FlexColumn, Grid4, GridContainer, GridContent } from "../../components/Grid";
import { BoxContainer } from "../../components/BoxContainer";
import { MainContent } from "../../components/MainContent";
import logoAktia from "../../images/banks/aktia.png";
import logoDanske from "../../images/banks/danske.png";
import logoHandelsbanken from "../../images/banks/handelsbanken.png";
import logoNordea from "../../images/banks/nordea.png";
import logoOmaSP from "../../images/banks/omasp.png";
import logoOp from "../../images/banks/op.png";
import logoSaastopankki from "../../images/banks/saastopankki.png";
import logoSPankki from "../../images/banks/spankki.png";
import logoPop from "../../images/banks/pop.png";
import logoAlandsbanken from "../../images/banks/alandsbanken.png";
import logoMobiilivarmenne from "../../images/banks/mobiilivarmenne.png";

import { startPageTrigger } from "../../actions/page";
import { breakpoints } from "../../constants/styles";
import { PageTitleBox } from "../../components/PageTitleBox";
import { LargeTitle } from "../../components/Fonts/LargeTitle";
import { messages } from "./messages";

export const BankContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 12px;
    padding: 0;
    width: 50%;
    height: 80px;
    cursor: pointer;
    @media ${breakpoints.mobile} {
        width: 100%;
        margin: 0 0 24px 0;
    }
`;

export const BankRow = styled.div`
    display: flex;
    flex-direction: row;
    @media ${breakpoints.mobile} {
        flex-direction: column;
        &:last-of-type > :last-child {
            display: none;
        }
    }
`;

export const BankContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: #ffffff;
    box-shadow: -2px 2px 6px 1px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    align-items: center;
    justify-content: center;
`;

export const BankImageDesktop = styled.div`
    width: ${(props) => `${props.width}px`};
    height: ${(props) => `${props.height}px`};
    background-image: ${(props) => `url(${props.url})`};
    background-size: contain;
    background-repeat: no-repeat;
`;

export const BankImageAlandsbankenDesktop = styled.div`
    width: 170px;
    height: 60px;
    background-image: ${(props) => `url(${props.url})`};
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`;

export const BankImageSimpleDesktop = styled.div`
    width: 100%;
    height: 100%;
    background-image: ${(props) => `url(${props.url})`};
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
`;

export function redirect(bank) {
    let rest = "";
    if (bank) {
        rest = `&acrValues=urn:signicat:oidc:method:${bank}`;
    }
    window.location = process.env.REACT_APP_REDIRECT + rest;
}

export function StartPage() {
    const { formatMessage: f } = useIntl();

    const dispatch = useDispatch();
    const history = useHistory();
    const token = useSelector((state) => state.user.token);

    useEffect(() => {
        if (token) {
            history.push("/front");
        } else {

            dispatch(startPageTrigger());
        }
    }, [dispatch, history, token]);

    return (
        <FlexColumn>
            <PageTitleBox>
                <LargeTitle>{f(messages.title)}</LargeTitle>
            </PageTitleBox>
            <GridContainer>
                <Grid4>
                    <GridContent>
                        <BoxContainer>
                            <MainContent>
                                <BankRow>
                                    <BankContainer
                                        onClick={() => {
                                            redirect("ftn-danskebank-auth");
                                        }}>
                                        <BankContent>
                                            <BankImageDesktop
                                                url={logoDanske}
                                                width={141}
                                                height={22}
                                            />
                                        </BankContent>
                                    </BankContainer>
                                    <BankContainer
                                        onClick={() => {
                                            redirect("ftn-op-auth");
                                        }}>
                                        <BankContent>
                                            <BankImageDesktop
                                                url={logoOp}
                                                width={113}
                                                height={31}
                                            />
                                        </BankContent>
                                    </BankContainer>
                                </BankRow>
                                <BankRow>
                                    <BankContainer
                                        onClick={() => {
                                            redirect("ftn-nordea-auth");
                                        }}>
                                        <BankContent>
                                            <BankImageDesktop
                                                url={logoNordea}
                                                width={144}
                                                height={31}
                                            />
                                        </BankContent>
                                    </BankContainer>
                                    <BankContainer
                                        onClick={() => {
                                            redirect("ftn-handelsbanken-auth");
                                        }}>
                                        <BankContent>
                                            <BankImageDesktop
                                                url={logoHandelsbanken}
                                                width={144}
                                                height={15}
                                            />
                                        </BankContent>
                                    </BankContainer>
                                </BankRow>
                                <BankRow>
                                    <BankContainer
                                        onClick={() => {
                                            redirect("ftn-spankki-auth");
                                        }}>
                                        <BankContent>
                                            <BankImageDesktop
                                                url={logoSPankki}
                                                width={145}
                                                height={33}
                                            />
                                        </BankContent>
                                    </BankContainer>
                                    <BankContainer
                                        onClick={() => {
                                            redirect("ftn-aktia-auth");
                                        }}>
                                        <BankContent>
                                            <BankImageDesktop
                                                url={logoAktia}
                                                width={99}
                                                height={33}
                                            />
                                        </BankContent>
                                    </BankContainer>
                                </BankRow>
                                <BankRow>
                                    <BankContainer
                                        onClick={() => {
                                            redirect("ftn-sp-auth");
                                        }}>
                                        <BankContent>
                                            <BankImageDesktop
                                                url={logoSaastopankki}
                                                width={153}
                                                height={28}
                                            />
                                        </BankContent>
                                    </BankContainer>
                                    <BankContainer
                                        onClick={() => {
                                            redirect("ftn-omasp-auth");
                                        }}>
                                        <BankContent>
                                            <BankImageDesktop
                                                url={logoOmaSP}
                                                width={106}
                                                height={50}
                                            />
                                        </BankContent>
                                    </BankContainer>
                                </BankRow>
                                <BankRow>
                                    <BankContainer
                                        onClick={() => {
                                            redirect("ftn-alandsbanken-auth");
                                        }}>
                                        <BankContent>
                                            <BankImageAlandsbankenDesktop url={logoAlandsbanken} />
                                        </BankContent>
                                    </BankContainer>
                                    <BankContainer
                                        onClick={() => {
                                            redirect("ftn-pop-auth");
                                        }}>
                                        <BankContent>
                                            <BankImageDesktop
                                                url={logoPop}
                                                width={165}
                                                height={54}
                                            />
                                        </BankContent>
                                    </BankContainer>
                                </BankRow>
                                <BankRow>
                                    <BankContainer
                                        onClick={() => {
                                            redirect("mobiilivarmenne-ftn");
                                        }}>
                                        <BankContent>
                                            <BankImageSimpleDesktop
                                                url={logoMobiilivarmenne}
                                                width={201}
                                                height={138}
                                            />
                                        </BankContent>
                                    </BankContainer>
                                    <BankContainer />
                                </BankRow>
                            </MainContent>
                        </BoxContainer>
                    </GridContent>
                </Grid4>
            </GridContainer>
        </FlexColumn>
    );
}
