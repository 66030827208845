import { createSelector } from "reselect";
import * as R from "ramda";
import { format, parseISO } from "date-fns";
import { getToken } from "./token";
import { STATEMENT_STATUSES } from "../constants/statementStatus";
import { DEFAULT_FORMAT_DATE } from "../constants/dateTime";

const getPaymentPlans = (state) =>
    state.paymentPlans.paymentPlans ? state.paymentPlans.paymentPlans : [];
const getStatements = (state) => (state.statements.statements ? state.statements.statements : []);
const token = (state) => getToken(state);

const byStatementStatus = R.groupBy(function(item) {
    const status = item.statementStatus;
    switch (status) {
        case STATEMENT_STATUSES.UNPAID:
        case STATEMENT_STATUSES.PENDING:
        case STATEMENT_STATUSES.PARTIALLYPAID:
        case STATEMENT_STATUSES.OVERDUE:
        case STATEMENT_STATUSES.COLLECTION:
            return "activePaymentPlans";
        case STATEMENT_STATUSES.PAIDINFULL:
        case STATEMENT_STATUSES.CURING:
            return "archivePaymentPlans";
        case STATEMENT_STATUSES.NOTGENERATED:
            return "notGenerated";
        case STATEMENT_STATUSES.CREDIT:
            return "credit";
        case STATEMENT_STATUSES.CURINGCREDIT:
            return "curingCredit";
        case STATEMENT_STATUSES.GENERATED:
            return "generated";
        default:
            return "activePaymentPlans";
    }
});

const filterNotGenerated = R.reject(R.propEq("statementStatus", STATEMENT_STATUSES.NOTGENERATED));

export const selectPaymentPlans = () =>
    createSelector([getPaymentPlans], (paymentPlans) => paymentPlans);

export const selectSortedPaymentPlans = () =>
    createSelector([getPaymentPlans], (paymentPlans) => {
        const filtterdPaymentPlans = filterNotGenerated(paymentPlans);

        return filtterdPaymentPlans.sort((a, b) => (a.dueDate > b.dueDate ? 1 : -1));
    });

export const selectPaymentPlansWithStatement = () =>
    createSelector([getPaymentPlans, getStatements, token], (paymentPlans, statements, token) => {
        const parsedStatements = statements.map((statement) => {
            return {
                ...statement,
                seriesNum: parseInt(statement.seriesNum)
            };
        });

        const tenorCount = R.pipe(filterNotGenerated, R.length)(paymentPlans);
        const filtterdPaymentPlans = filterNotGenerated(paymentPlans);

        const modifiedPaymentPlans = filtterdPaymentPlans
            .sort((a, b) => (a.dueDate > b.dueDate ? 1 : -1))
            .map((item, index) => {
                const statement = R.find(R.propEq("seriesNum", parseInt(item.seriesNum)))(
                    parsedStatements
                );
                const title = `${index + 1}/${tenorCount}`;

                return {
                    ...item,
                    documentId: statement && statement.documentId,
                    title,
                    dueDate: format(parseISO(item.dueDate), DEFAULT_FORMAT_DATE)
                };
            });

        const grouped = byStatementStatus(modifiedPaymentPlans);

        return {
            statements: parsedStatements,
            paymentPlans: modifiedPaymentPlans,
            activePaymentPlans: [],
            archivePaymentPlans: [],
            notGenerated: [],
            generated: [],
            curingcredit: [],
            credit: [],
            ...grouped,
            token
        };
    });
