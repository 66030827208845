import { FETCH_ACCOUNT_STATEMENTS_SUCCESS } from "../constants/account";

export const initialState = [];

export function accountStatementsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ACCOUNT_STATEMENTS_SUCCESS: {
            return [...state, ...action.payload];
        }
        default:
            return state;
    }
}
