import React from "react";
import { FormattedMessage } from "react-intl";

import { Grid2, FlexRow } from "../../Grid";
import { Icon } from "../../Icon";
import { ColumnTitle } from "../ColumnTitle";
import {
    FlexIconColumn,
    FlexIconRow,
    Grid2WithPadding,
    StyledFooterLink,
    StyledParagraph,
    StyledPhoneNumber,
    StyledPriceInfo
} from "../styled";
import { colors } from "../../../constants/styles";

import { messages } from "./messages";

export const CustomerService = () => {
    return (
        <Grid2>
            <ColumnTitle>
                <FormattedMessage {...messages.title} />
            </ColumnTitle>
            <FlexRow>
                <Grid2WithPadding>
                    <FlexIconRow>
                        <FlexIconColumn>
                            <Icon icon={["far", "clock"]} size="lg" color={colors.gray} />
                        </FlexIconColumn>
                        <div>
                            <StyledParagraph>
                                <FormattedMessage {...messages.open} />
                            </StyledParagraph>
                        </div>
                    </FlexIconRow>
                    <FlexIconRow>
                        <FlexIconColumn>
                            <Icon icon={["far", "envelope"]} size="lg" color={colors.gray} />
                        </FlexIconColumn>
                        <div>
                            <StyledParagraph>
                                <StyledFooterLink href="mailto:asiakaspalvelu@ostosraha.fi">
                                    <FormattedMessage {...messages.email} />
                                </StyledFooterLink>
                            </StyledParagraph>
                        </div>
                    </FlexIconRow>
                </Grid2WithPadding>
                <Grid2WithPadding>
                    <FlexIconRow>
                        <FlexIconColumn>
                            <Icon
                                icon={["fas", "phone"]}
                                size="lg"
                                transform={{ rotate: 93 }}
                                color={colors.gray}
                            />
                        </FlexIconColumn>
                        <div>
                            <StyledParagraph>
                                <FormattedMessage {...messages.phonetime} />
                            </StyledParagraph>
                            <StyledPhoneNumber>
                                <FormattedMessage {...messages.phonenumber} />
                            </StyledPhoneNumber>
                            <StyledPriceInfo>
                                <FormattedMessage {...messages.ingresphone} />
                            </StyledPriceInfo>
                            <StyledPriceInfo>
                                <FormattedMessage {...messages.pricephone} />
                            </StyledPriceInfo>
                            <StyledPriceInfo>
                                <FormattedMessage {...messages.ingressmobile} />
                            </StyledPriceInfo>
                            <StyledPriceInfo>
                                <FormattedMessage {...messages.pricemobile} />
                            </StyledPriceInfo>
                        </div>
                    </FlexIconRow>
                </Grid2WithPadding>
            </FlexRow>
        </Grid2>
    );
};
