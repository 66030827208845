import React from "react";

import { StyledInput, StyledError } from "./styled";

export const InputField = (props) => {
    const { helperText } = props;
    return (
        <>
            <StyledInput {...props} />
            {helperText && <StyledError>{helperText}</StyledError>}
        </>
    );
};
