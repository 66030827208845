import { createSelector } from "reselect";

const getNumber = (code, number) => {
    if (code && number) return `${code}${number}`;
    return null;
};

export const getCustomer = (state) => state.user.customer;

export const getCustomerBasicInfo = createSelector(
    [getCustomer],
    ({ customerBasicInfo }) => customerBasicInfo
);

export const selectCustomerBasicInfoAndAddress = createSelector(
    [getCustomer],
    ({ address, customerBasicInfo }) => {
        const { mobilePhoneCode, mobilePhoneNumber, phoneCode, phoneNumber } = customerBasicInfo;
        const mobilePhone = getNumber(mobilePhoneCode, mobilePhoneNumber);
        const phone = getNumber(phoneCode, phoneNumber);

        return {
            address,
            customerBasicInfo: {
                ...customerBasicInfo,
                mobilePhone,
                phone
            }
        };
    }
);
