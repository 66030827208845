import styled from "styled-components/macro";

import { makeStyles } from "../utils";

export const StyledHeader = styled.div`
    ${({ theme }) => makeStyles(theme)}
`;

export const IconContainer = styled.div`
    ${({ theme }) => makeStyles(theme)}
`;
