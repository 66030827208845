import React from "react";

import { NavigationContainer } from "../NavigationContainer";
import { CustomerService } from "./CustomerService";
import { CompanyInfo } from "./CompanyInfo";
import { FooterContainer, FooterContent } from "./styled";

export const Footer = () => {
    return (
        <FooterContainer>
            <NavigationContainer>
                <FooterContent>
                    <CustomerService />
                    <CompanyInfo />
                </FooterContent>
            </NavigationContainer>
        </FooterContainer>
    );
};
