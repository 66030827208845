import React, { useState } from "react";
import styled from "styled-components";
import { Icon } from "./Icon";
import { colors } from "../constants/styles";

export const TooltipHeaderContainer = styled.div`
    cursor: pointer;
`;

export const TooltipContainer = styled.div`
    cursor: pointer;
    position: absolute;
    margin: 10px 0 0 -263px;
    z-index: 1000;
    width: 288px;
    background: #d8d8d8;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
    -moz-box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    padding: 16px;
    background-image: linear-gradient(-180deg, #ffffff 16%, #ebebeb 93%);
    &::before {
        content: "";

        position: absolute;
        top: -23px;
        left: 240px;
        transform: translateX(80%);

        border: 12px solid green;

        border-left-color: transparent;
        border-right-color: transparent;
        border-top-color: transparent;
        border-bottom-color: #adadad;

        width: 0;
        height: 0;

        margin: 0 auto;
    }
    &::after {
        content: "";

        position: absolute;
        top: -20px;
        left: 240px;
        transform: translateX(80%);

        border: 12px solid green;

        border-left-color: transparent;
        border-right-color: transparent;
        border-top-color: transparent;
        border-bottom-color: white;

        width: 0;
        height: 0;

        margin: 0 auto;
    }
`;

export const TooltipTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    flex: 1;
`;

export const TooltipContent = styled.div`
    font-weight: 300;
    font-size: 16px;
    font-family: Roboto, sans-serif;
    color: #444444;
    letter-spacing: 0;
    line-height: 24px;
`;

export const TooltipContentContainer = function({ children, isOpen }) {
    if (!isOpen) {
        return null;
    }

    return (
        <TooltipContainer>
            <TooltipContent>{children}</TooltipContent>
        </TooltipContainer>
    );
};

export function Tooltip({ children }) {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    return (
        <TooltipHeaderContainer
            onClick={() => {
                setIsTooltipOpen(isTooltipOpen === true ? false : true);
            }}>
            <Icon icon={["fa", "question-circle"]} size="1x" color={colors.black} />
            <TooltipContentContainer isOpen={isTooltipOpen}>{children}</TooltipContentContainer>
        </TooltipHeaderContainer>
    );
}
