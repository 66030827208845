import { createSelector } from "reselect";
import { getToken } from "./token";
import { getActiveAccountId } from "./activeAccount";

export const tokenAndActiveAccountId = createSelector(
    [getToken, getActiveAccountId],
    (token, id) => ({
        token: token,
        accountId: id
    })
);
