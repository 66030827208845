import { defineMessages } from "react-intl";

export const messages = defineMessages({
    title: {
        id: "footer.customerservice.title"
    },
    open: {
        id: "footer.customerservice.open"
    },
    email: {
        id: "footer.customerservice.email"
    },
    phonetime: {
        id: "footer.customerservice.phonetime"
    },
    phonenumber: {
        id: "companyinfo.phonenumber"
    },
    ingresphone: {
        id: "footer.customerservice.ingress.phone"
    },
    pricephone: {
        id: "footer.customerservice.price.phone"
    },
    ingressmobile: {
        id: "footer.customerservice.ingress.mobile"
    },
    pricemobile: {
        id: "footer.customerservice.price.mobile"
    }
});
