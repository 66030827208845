import { takeEvery, put, select } from "redux-saga/effects";
import { setSelectedAccount } from "../actions/account";
import { USER_FETCH_CUSTOMER_SUCCESS } from "../constants/user";

export function* watchSelectAccount() {
    yield takeEvery(USER_FETCH_CUSTOMER_SUCCESS, handleSelectAccount);
}

export function* handleSelectAccount(action) {
    const { accountList } = yield select((state) => {
        return {
            accountList: state.user.customer.accountList,
            accounts: state.accounts.accounts
        };
    });
    if (accountList && accountList.length > 0) {
        const accountInfo = accountList.slice(-1).pop();
        yield put(setSelectedAccount(accountInfo));
    }
}
