import React from "react";
import styled from "styled-components/macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "../constants/styles";

const StyledIcon = styled(FontAwesomeIcon)`
    color: ${(props) => (props.color ? props.color : colors.ligthGreen)};
`;

export function Icon(props) {
    const { icon, size, color, transform, ...rest } = props;

    return <StyledIcon icon={icon} size={size} color={color} transform={transform} {...rest} />;
}
