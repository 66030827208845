import {
    USER_FETCH_CUSTOMER_SUCCESS,
    USER_UPDATE_INITIALIZED,
    USER_UPDATE_IS_VALID_TOKEN,
    USER_UPDATE_IS_VALIDATING_TOKEN,
    USER_UPDATE_TOKEN
} from "../constants/user";

export const initialState = {
    customer: {},
    token: "",
    isValidatingToken: false,
    isValidToken: false,
    initialized: false,
};

export function userReducer(state = initialState, action) {
    switch (action.type) {
        case USER_FETCH_CUSTOMER_SUCCESS: {
            return { ...state, customer: action.payload };
        }
        case USER_UPDATE_TOKEN: {
            return { ...state, token: action.payload };
        }
        case USER_UPDATE_INITIALIZED: {
            return { ...state, initialized: action.payload };
        }
        case USER_UPDATE_IS_VALIDATING_TOKEN: {
            return { ...state, isValidatingToken: action.payload };
        }
        case USER_UPDATE_IS_VALID_TOKEN: {
            return { ...state, isValidToken: action.payload, isValidatingToken: false };
        }
        default:
            return state;
    }
}
