import React from "react";
import { StyledList, StyledListItem } from "./styled";

export const DropDownList = ({ listHeaderItem, items, onClick, theme, noListArrow }) => {
    return (
        <StyledList theme={theme} noListArrow={noListArrow}>
            {listHeaderItem && (
                <StyledListItem
                    value={listHeaderItem.value}
                    key="header"
                    theme={theme.listItem}
                    onClick={onClick}>
                    {listHeaderItem}
                </StyledListItem>
            )}
            {items.map((item, index) => (
                <StyledListItem
                    value={item.value}
                    key={`key-${index}`}
                    onClick={onClick}
                    theme={theme.listItem}>
                    {item.text}
                </StyledListItem>
            ))}
        </StyledList>
    );
};
