import React, { useEffect } from "react";
import { useIntl } from "react-intl";
// import { goToTop } from "react-scrollable-anchor";
import { GridContainer, FlexColumn, GridContent } from "../../components/Grid";
import { BoxContainer } from "../../components/BoxContainer";
import { MainContent } from "../../components/MainContent";
import { LargeTitle } from "../../components/Fonts/LargeTitle";
import { PageTitleBox } from "../../components/PageTitleBox";
import { SmallTitle } from "../../components/Fonts/SmallTitle";
import { BasicText } from "../../components/Fonts/BasicText";

import { List, Space } from "./styled";
import { messages } from "./messages";

export function NoLoanPage() {
    useEffect(() => {
        // goToTop();
    }, []);

    const { formatMessage: f } = useIntl();

    return (
        <FlexColumn>
            <PageTitleBox>
                <LargeTitle>{f(messages.pagetitle)}</LargeTitle>
            </PageTitleBox>
            <GridContainer>
                <GridContent>
                    <BoxContainer>
                        <MainContent>
                            <Space>
                                <SmallTitle>{f(messages.smalltitle)}</SmallTitle>
                            </Space>
                            <BasicText>{f(messages.listinfo)}</BasicText>
                            <List>
                                <li>
                                    <BasicText>{f(messages.listitem1)}</BasicText>
                                </li>
                                <li>
                                    <BasicText>{f(messages.listitem2)}</BasicText>
                                </li>
                            </List>
                            <BasicText>{f(messages.info)}</BasicText>
                            <BasicText>{f(messages.link, messages.link.values)}</BasicText>
                        </MainContent>
                    </BoxContainer>
                </GridContent>
            </GridContainer>
        </FlexColumn>
    );
}
