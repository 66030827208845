import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { useSelector } from "react-redux";
import { Container } from "./components/Container";

import { Loader } from "./components/Loader";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { FeedbackButton } from "./components/Feedback/FeedbackButton";
import { FeedbackForm } from "./components/Feedback/FeedbackForm";
import { breakpoints } from "./constants/styles";
import { ModalDialog } from "./components/ModalDialog/index";

import { MobileNavigation } from "./components/MobileNavigation";

import { sendFeedbackReset } from "./actions/feedback";
import { useIntl } from "react-intl";
import { messages } from "./Layout.messages";

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 0px;
    @media ${breakpoints.mobile} {
        padding-top: 64px;
    }
`;

export function Layout({ children }) {
    const { formatMessage: f } = useIntl();

    const dispatch = useDispatch();
    const token = useSelector((state) => state.user.token);
    const isLoading = useSelector((state) => {
        return state.ui.isLoading;
    });

    const [receivedFeedbackModal, toggleReceivedFeedbackModal] = useState(false);

    const handleCloseDialog = () => {
        toggleReceivedFeedbackModal(false);
        dispatch(sendFeedbackReset());
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div>
            <Header />
            {token && (
                <FeedbackButton
                    onClick={() => toggleReceivedFeedbackModal(!receivedFeedbackModal)}
                />
            )}
            <ModalDialog
                open={receivedFeedbackModal}
                closeDialog={() => handleCloseDialog()}
                headline={f(messages.corner)}>
                <FeedbackForm />
            </ModalDialog>
            <Wrapper>
                <Container>{children}</Container>
            </Wrapper>
            <Footer />
            <MobileNavigation />
        </div>
    );
}
