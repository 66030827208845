import * as UI from "../constants/ui";
import * as ACCOUNT from "../constants/account";
import * as USER from "../constants/user";
import { SET_SPECIAL_PAYMENT_AGREEMENT } from "../constants/specialPaymentAgreements";

export const initialState = {
    isLoading: false,
    billingSettingsSaved: {
        saved: false,
        error: false
    },
    specialPaymentAgreement: {
        loading: false,
        saved: false,
        error: false
    },
    userUpdate: {
        isUpdateUserInfoLoading: false,
        isUpdateUserInfoError: false
    },
    language: {
        messages: {},
        error: {},
        loading: false
    }
};

export function uiReducer(state = initialState, action) {
    switch (action.type) {
        case USER.USER_UPDATE_INFO_TRIGGER: {
            return {
                ...state,
                userUpdate: { isUpdateUserInfoLoading: true, isUpdateUserInfoError: false }
            };
        }
        case USER.USER_UPDATE_INFO_SUCCESS: {
            return {
                ...state,
                userUpdate: { isUpdateUserInfoLoading: false, isUpdateUserInfoError: false }
            };
        }
        case USER.USER_UPDATE_INFO_ERROR: {
            return {
                ...state,
                userUpdate: { isUpdateUserInfoLoading: false, isUpdateUserInfoError: true }
            };
        }
        case USER.USER_UPDATE_INFO_RESET: {
            return {
                ...state,
                userUpdate: { ...initialState.userUpdate }
            };
        }
        case UI.UI_IS_LOADING: {
            return { ...state, isLoading: action.payload };
        }
        case UI.UI_TRANSLATIONS_TRIGGER: {
            return {
                ...state,
                language: {
                    messages: {},
                    error: {},
                    loading: true
                }
            };
        }
        case UI.UI_TRANSLATIONS_SUCCESS: {
            return {
                ...state,
                language: {
                    messages: action.payload,
                    error: {},
                    loading: false
                }
            };
        }
        case UI.UI_TRANSLATIONS_ERROR: {
            return {
                ...state,
                language: {
                    messages: {},
                    error: action.payload,
                    loading: false
                }
            };
        }
        case ACCOUNT.SAVE_BILLING_SETTINGS_SUCCESS: {
            return { ...state, billingSettingsSaved: action.payload };
        }
        case ACCOUNT.SAVE_BILLING_SETTINGS_ERROR: {
            return { ...state, billingSettingsSaved: action.payload };
        }
        case ACCOUNT.SAVE_BILLING_SETTINGS_RESET: {
            return { ...state, billingSettingsSaved: action.payload };
        }
        case SET_SPECIAL_PAYMENT_AGREEMENT.TRIGGER: {
            return { ...state, specialPaymentAgreement: action.status };
        }
        case SET_SPECIAL_PAYMENT_AGREEMENT.SUCCESS:
        case SET_SPECIAL_PAYMENT_AGREEMENT.ERROR:
        case SET_SPECIAL_PAYMENT_AGREEMENT.RESET: {
            return { ...state, specialPaymentAgreement: action.payload };
        }
        default:
            return state;
    }
}
