import * as FEEDBACK from "../constants/feedback";

const initialState = {
    sent: false,
    error: false,
    isSending: false
};

export function feedbackReducer(state = initialState, action) {
    switch (action.type) {
        case FEEDBACK.FEEDBACK_SUCCESS: {
            return {
                ...state,
                sent: true,
                error: false,
                isSending: false
            };
        }
        case FEEDBACK.FEEDBACK_ERROR: {
            return {
                ...state,
                sent: false,
                error: true,
                isSending: false
            };
        }
        case FEEDBACK.FEEDBACK_RESET: {
            return {
                ...state,
                ...initialState
            };
        }
        case FEEDBACK.FEEDBACK_TRIGGER: {
            return {
                ...state,
                isSending: true
            };
        }
        default: {
            return state;
        }
    }
}
